import {inject, injectable} from "inversify";
import type {
	IJoinLeague,
	ILeague,
	ILeagueEditPayload,
	ILeagueUser,
	IRankingsTableObject,
} from "data/types/entities";
import {action, makeAutoObservable, observable, runInAction, toJS} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesApiProvider} from "data/providers/api/leagues.api.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import type {
	IOverallRankingsResponse,
	IAxiosApiError,
	IInviteLeagueParams,
	ILeagueCreatePayload,
	ILeaguesFilters,
} from "data/types/api";
import type {Empty} from "data/types/generics";
import {debounce} from "lodash";

export interface ILeagueStoreLoadMore {
	myLeagues: boolean;
	joinLeagues: boolean;
	leagueUsers: boolean;
	standings: boolean;
}

export interface ILeaguesStorePagesMapper {
	myLeagues: number;
	joinLeagues: number;
	leagueUsers: number;
	standings: number;
}

export interface ILeaguesStore {
	fetchMyLeagues(): Promise<void>;

	fetchLeaguesLoadMore(): Promise<void>;

	fetchJoinLeagues(): Promise<void> | undefined;

	fetchJoinLeaguesNext(): Promise<void> | undefined;

	getLeagueById(leagueId: number): Empty<ILeague>;

	getJoinLeagueById(leagueId: number): Empty<IJoinLeague>;

	joinLeague(code: string): Promise<void>;

	createLeague(params: ILeagueCreatePayload): Promise<void>;

	updateFilters(filters: ILeaguesFilters): Promise<void> | undefined;

	fetchLeagueById(leagueId: number): Promise<void>;

	fetchLeagueByCode(code: string): Promise<void>;

	updateLeagueById(leagueId: number, payload: ILeagueEditPayload): Promise<void>;

	leaveLeagueById(leagueId: number): Promise<void>;

	setSelectedLeagueById(leagueId: number): void;

	inviteLeague(params: IInviteLeagueParams): Promise<void>;

	fetchLeagueUsers(): Promise<void>;

	fetchLeagueUsersLoadMore(): Promise<void>;

	removeUserFromLeague(userId: number): Promise<void>;

	clearSelectedLeague(): void;

	fetchLeagueStandings(contestId: number, reset?: boolean): Promise<void>;

	loadMoreLeagueStandings(contestId: number): Promise<void>;

	get standingsNextPage(): boolean;

	get standings(): IRankingsTableObject;

	get selectedLeague(): ILeague | undefined;

	get myLeagues(): ILeague[];

	get joinLeagues(): IJoinLeague[];

	get isMyLeaguesLoading(): boolean;

	get isJoinLeaguesLoading(): boolean;

	get loadMoreMapper(): ILeagueStoreLoadMore;

	get leagueFilters(): ILeaguesFilters;

	get leagueUsers(): ILeagueUser[];
}

@injectable()
export class LeaguesStore implements ILeaguesStore {
	@observable private _pagesMapper: ILeaguesStorePagesMapper = {
		myLeagues: 1,
		joinLeagues: 1,
		leagueUsers: 1,
		standings: 1,
	};

	constructor(
		@inject(Bindings.LeaguesApiProvider) private _leaguesApiProvider: ILeaguesApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _standings: IRankingsTableObject = {
		rankings: [],
		user: undefined,
	};

	get standings(): IRankingsTableObject {
		return this._standings;
	}

	@observable private _leagueUsers: ILeagueUser[] = [];

	get leagueUsers(): ILeagueUser[] {
		return this._leagueUsers;
	}

	@observable private _leagueFilters: ILeaguesFilters = {
		search: "",
	};

	get leagueFilters(): ILeaguesFilters {
		return this._leagueFilters;
	}

	@observable private _loadMoreMapper: ILeagueStoreLoadMore = {
		myLeagues: false,
		joinLeagues: false,
		leagueUsers: false,
		standings: false,
	};

	get loadMoreMapper(): ILeagueStoreLoadMore {
		return this._loadMoreMapper;
	}

	@observable private _isJoinLeaguesLoading: boolean = false;

	get isJoinLeaguesLoading(): boolean {
		return this._isJoinLeaguesLoading;
	}

	@observable private _isMyLeaguesLoading: boolean = false;

	get isMyLeaguesLoading(): boolean {
		return this._isMyLeaguesLoading;
	}

	@observable private _joinLeagues: IJoinLeague[] = [];

	get joinLeagues(): IJoinLeague[] {
		return this._joinLeagues;
	}

	@action
	public fetchJoinLeagues = debounce(async () => {
		if (this._isJoinLeaguesLoading) {
			return Promise.resolve();
		}
		try {
			this._isJoinLeaguesLoading = true;
			const page = this._pagesMapper["joinLeagues"];
			const response = await this._leaguesApiProvider.fetchShowForJoinLeagues({
				page,
				...this._leagueFilters,
			});

			runInAction(() => {
				const responseLeagues = response.data.success.leagues;
				this._joinLeagues =
					page > 1 ? [...this._joinLeagues, ...responseLeagues] : responseLeagues;
			});
			this.updateLoadMoreKey("joinLeagues", response.data.success.nextPage);
		} catch (e) {
			this.onError(e);
			return Promise.reject();
		} finally {
			runInAction(() => {
				this._isJoinLeaguesLoading = false;
			});
		}
		return Promise.resolve();
	}, 500);

	@observable private _myLeagues: ILeague[] = [];

	get myLeagues(): ILeague[] {
		return this._myLeagues;
	}

	@observable private _selectedLeague: ILeague | undefined;

	get selectedLeague() {
		return this._selectedLeague;
	}

	get standingsNextPage(): boolean {
		return this._loadMoreMapper.standings;
	}

	@action
	public clearSelectedLeague() {
		this._selectedLeague = undefined;
	}

	public getLeagueById(leagueId: number): Empty<ILeague> {
		return this._myLeagues.find((e) => e.id === leagueId);
	}

	public getJoinLeagueById(leagueId: number): Empty<IJoinLeague> {
		return this._joinLeagues.find((e) => e.id === leagueId);
	}

	@action
	public async joinLeague(code: string): Promise<void> {
		try {
			await this._leaguesApiProvider.joinToLeagueByCode(code);
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		}
	}

	@action
	public async createLeague(params: ILeagueCreatePayload): Promise<void> {
		try {
			const response = await this._leaguesApiProvider.createLeague(params);
			const league = response.data.success?.league;
			runInAction(() => {
				this._myLeagues = this._myLeagues.concat(league);
				this._selectedLeague = league;
			});

			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		}
	}

	@action
	async inviteLeague(params: IInviteLeagueParams): Promise<void> {
		try {
			await this._leaguesApiProvider.inviteLeague(params);
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		}
	}

	@action
	public async fetchMyLeagues(): Promise<void> {
		try {
			this._isMyLeaguesLoading = true;
			const page = this._pagesMapper.myLeagues;
			const response = await this._leaguesApiProvider.fetchMyLeagues({page});
			this._myLeagues = response.data.success.leagues;
			this.updateLoadMoreKey("myLeagues", response.data.success.nextPage);
		} catch (e) {
			this.onError(e);
		} finally {
			this._isMyLeaguesLoading = false;
		}
		return Promise.resolve(undefined);
	}

	public fetchLeaguesLoadMore(): Promise<void> {
		this._pagesMapper.myLeagues += 1;
		return this.fetchMyLeagues();
	}

	@action
	public async fetchLeagueById(leagueId: number): Promise<void> {
		try {
			const response = await this._leaguesApiProvider.fetchLeagueById(leagueId);
			this.updateLeaguesWithNew(response.data.success.league);
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject();
		}
	}

	@action
	public async fetchLeagueByCode(code: string): Promise<void> {
		try {
			const response = await this._leaguesApiProvider.fetchLeagueByCode(code);
			this.updateLeaguesWithNew(response.data.success);
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject();
		}
	}

	@action
	public async updateLeagueById(leagueId: number, payload: ILeagueEditPayload): Promise<void> {
		try {
			const response = await this._leaguesApiProvider.updateLeagueById(leagueId, payload);
			this.updateLeaguesWithNew(response.data.success.league);
			this._selectedLeague = response.data.success.league;
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject();
		}
	}

	@action
	public async leaveLeagueById(leagueId: number): Promise<void> {
		if (!this._selectedLeague) {
			return;
		}
		try {
			await this._leaguesApiProvider.leaveLeague(leagueId);
			this.updateLeaguesWithNew({...this._selectedLeague, isJoined: false});
			this._selectedLeague.isJoined = false;
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject();
		}
	}

	public fetchJoinLeaguesNext(): Promise<void> | undefined {
		this._pagesMapper["joinLeagues"]++;
		return this.fetchJoinLeagues();
	}

	@action
	public updateFilters(filters: ILeaguesFilters): Promise<void> | undefined {
		this._leagueFilters = filters;
		this._pagesMapper["joinLeagues"] = 1;
		return this.fetchJoinLeagues();
	}

	@action
	public setSelectedLeagueById(leagueId: number): void {
		const league = this._myLeagues.find((e) => e.id === leagueId);
		if (!league) {
			return;
		}

		this._selectedLeague = league;
	}

	public async fetchLeagueUsersLoadMore(): Promise<void> {
		this._pagesMapper.leagueUsers += 1;
		return this.fetchLeagueUsers();
	}

	@action
	public async fetchLeagueUsers(): Promise<void> {
		if (!this.selectedLeague) {
			return Promise.reject("no selected league found!");
		}

		try {
			const page = this._pagesMapper.leagueUsers;
			const response = await this._leaguesApiProvider.fetchLeagueUsers(
				this.selectedLeague.id,
				{page}
			);

			this.updateLoadMoreKey("leagueUsers", response.data.success.nextPage);
			const users = response.data.success.users;
			this._leagueUsers = page === 1 ? users : [...this._leagueUsers, ...users];
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		}

		return Promise.resolve();
	}

	public async removeUserFromLeague(userId: number): Promise<void> {
		if (!this.selectedLeague) {
			return Promise.reject("No selected league found!");
		}
		try {
			await this._leaguesApiProvider.removeUserFormLeague(this.selectedLeague.id, userId);
			this._leagueUsers = this._leagueUsers.filter((e) => e.userId !== userId);
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		}
	}

	public async fetchLeagueStandings(contestId: number, reset: boolean = true): Promise<void> {
		if (!this.selectedLeague) {
			return Promise.reject("No selected league found");
		}

		if (reset) {
			this.resetStandings();
		}

		console.log(toJS(this.selectedLeague));
		try {
			const page = this._pagesMapper.standings;
			const response = await this._leaguesApiProvider.fetchLeagueStandings(
				this.selectedLeague.id,
				{contestId, page}
			);
			this.updateTableAccordingResponse(response.data.success);
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		}
	}

	@action
	public loadMoreLeagueStandings(contestId: number): Promise<void> {
		this._pagesMapper.standings += 1;
		return this.fetchLeagueStandings(contestId, false);
	}

	protected onError(e: unknown) {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalsStore.showAxiosError(error);
	}

	protected updateLoadMoreKey(key: keyof ILeagueStoreLoadMore, value: boolean): void {
		runInAction(() => {
			this._loadMoreMapper = {
				...this._loadMoreMapper,
				[key]: value,
			};
		});
	}

	@action
	protected updateLeaguesWithNew(league: ILeague): void {
		if (!league) {
			return;
		}

		const index = this._myLeagues.findIndex((e) => e.id === league.id);
		if (index === -1) {
			this._myLeagues = [...this._myLeagues, league];
			return;
		}
		this._myLeagues[index] = league;
	}

	@action
	protected resetStandings() {
		this._pagesMapper.standings = 1;
		this._loadMoreMapper.standings = false;
		this._standings = {rankings: [], user: undefined};
	}

	protected updateTableAccordingResponse(data: IOverallRankingsResponse): void {
		const page = this._pagesMapper.standings;
		const localRankings = this.standings.rankings;

		runInAction(() => {
			const {next, rankings, user} = data;
			this._standings = {
				rankings: page > 1 ? [...localRankings, ...rankings] : rankings,
				user,
			};
			this._loadMoreMapper.standings = next;
		});
	}
}
