import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {ModalType} from "data/enums";

export interface IModalPicksSavedController extends ViewController {
	close: () => void;

	get isModalOpen(): boolean;

	get contestId(): number;
}

@injectable()
export class ModalPicksSavedController implements IModalPicksSavedController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get isModalOpen(): boolean {
		return this._modalsStore.modal === ModalType.PICKS_SAVED;
	}

	get contestId(): number {
		return this._contestStore.selectedContest?.id || 0;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};
}
