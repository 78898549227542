import React from "react";
import {Modal} from "@mui/material";
import {useViewController} from "data/services/locator";
import {IModalController} from "views/components/modals/modal.controller";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import {
	ModalButton,
	ModalCloseButton,
	ModalContent,
	ModalText,
	ModalTitle,
} from "views/components/modals/common";
import {observer} from "mobx-react";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {ReactComponent as ErrorIcon} from "assets/img/icons/error.svg";
import {getDashedValue} from "data/utils/helpers";
import {IModalMessage} from "data/types/modals";
import {Exist} from "views/components/Exist";

export const ModalError: React.FC = observer(() => {
	const {isModalOpen, close, modalData} = useViewController<IModalController>(
		Bindings.ModalController
	);
	const isOpen = isModalOpen(ModalType.ERROR);

	const data = modalData as IModalMessage;

	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<ModalCloseButton onClick={close}>
					<IconClose />
				</ModalCloseButton>
				<ErrorIcon />

				<Exist when={Boolean(data?.title)}>
					<ModalTitle>{data?.title}</ModalTitle>
				</Exist>
				<Exist when={!data?.title}>
					<ModalTitle>Error</ModalTitle>
				</Exist>
				<ModalText>{getDashedValue(data?.message)}</ModalText>

				<ModalButton fullWidth onClick={close}>
					Close
				</ModalButton>
			</ModalContent>
		</Modal>
	);
});
