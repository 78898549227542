import type {INotification} from "data/types/entities";
import {injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";

export interface INotificationService {
	showNotification(notification: INotification): void;

	cancelNotification(uuid: string): boolean;

	get notifications(): INotification[];
}

@injectable()
export class NotificationService implements INotificationService {
	@observable private _notifications: INotification[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	get notifications(): INotification[] {
		return this._notifications;
	}

	@action
	cancelNotification(uuid: string): boolean {
		const index = this._notifications.findIndex((e) => e.uuid === uuid);
		if (index !== -1) {
			this._notifications.splice(index, 1);
			return true;
		}
		return false;
	}

	@action
	showNotification(notification: INotification): void {
		const uuid = this.generateUUID();
		const timeToShow = notification.timeToShow || 5000;

		notification.uuid = uuid;
		notification.translate =
			notification.translate === undefined ? true : notification.translate;
		this._notifications.push(notification);

		setTimeout(() => {
			this.cancelNotification(uuid);
		}, timeToShow);
	}

	private generateUUID() {
		return Math.floor(Math.random() * 100).toString();
	}
}
