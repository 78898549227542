import {makeAutoObservable, toJS} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import {ModalType} from "data/enums";
import {IModalNoPicksPayload} from "data/types/entities";

export interface IModalNoPicksPlacedController extends ViewController {
	confirmExit: () => void;
	cancelExit: () => void;

	get isModalOpen(): boolean;
}

@injectable()
export class ModalNoPicksPlacedController implements IModalNoPicksPlacedController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	get isModalOpen(): boolean {
		if (!this.modalContent) {
			return false;
		}
		return this._modalsStore.modal === ModalType.NO_PICKS_BET;
	}

	get modalContent(): IModalNoPicksPayload {
		return this._modalsStore.modalContent as IModalNoPicksPayload;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public confirmExit = (): void => {
		this._contestStore.currentSliderStep = this.modalContent.next;
		this.closeModal();
	};

	public cancelExit = (): void => {
		const content = toJS(this.modalContent);
		if (!content) {
			this.closeModal();
			return;
		}

		if (content.answer) {
			// Wait for slider after change handler
			setTimeout(() => {
				const {pick, questionId} = content.answer;
				this._answersStore.answerQuestion({pick, questionId});
			}, 0);
		}
		this._contestStore.currentSliderStep = content.prev;
		this.closeModal();
	};

	private closeModal(): void {
		this._modalsStore.hideModal();
	}
}
