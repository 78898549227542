import {ViewController} from "data/types/structure";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import type {ILiveScoringStore} from "data/stores/liveScoring/livescoring.store";
import {IDashboardContest, IDashboardResponse} from "data/types/entities";
import {Empty} from "data/types/generics";

export interface IDashboardController extends ViewController {
	fetchDashboard: () => void;

	get isAuthorized(): boolean;

	get userName(): string | undefined;

	get dashboard(): Empty<IDashboardResponse>;

	get contests(): IDashboardContest[];

	get isLoading(): boolean;
}

@injectable()
export class DashboardController implements IDashboardController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore,
		@inject(Bindings.LiveScoringStore) private _liveScoringStore: ILiveScoringStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get userName(): string | undefined {
		return this._userStore.user?.username;
	}

	get dashboard(): Empty<IDashboardResponse> {
		return this._dashboardStore.dashboardInfo;
	}

	get contests(): IDashboardContest[] {
		return this.dashboard?.contests || [];
	}

	public fetchDashboard = (): void => {
		this._isLoading = true;
		this._dashboardStore.fetchDashboard().finally(() => (this._isLoading = false));
	};

	dispose(): void {
		this._liveScoringStore.unsubscribeLiveScoring();
	}

	init(param: void): void {
		this._liveScoringStore.subscribeLiveScoring("dashboard");
	}
}
