import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {Empty} from "data/types/generics";
import {ISettings} from "data/types/entities";
import {PeriodStatus} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";

export interface ISettingsStore {
	fetchSettings(): Promise<void>;

	checkPostSeasonModal(): void;

	postSeasonModalShown(): void;

	get settings(): Empty<ISettings>;

	get isPostSeasonModalShouldBeShown(): boolean;
}

@injectable()
export class SettingsStore implements ISettingsStore {
	protected readonly storageKey = "_bng_ps_";

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isPostSeasonModalShouldBeShown = false;

	get isPostSeasonModalShouldBeShown(): boolean {
		return this._isPostSeasonModalShouldBeShown;
	}

	@observable private _settings: Empty<ISettings>;

	get settings(): Empty<ISettings> {
		return this._settings;
	}

	@action
	public async fetchSettings(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.settings();

			runInAction(() => {
				this._settings = data;
			});
		} catch (e) {
			this._modalsStore.showError({message: "Error while loading settings"});
		}
	}

	@action
	public postSeasonModalShown() {
		this._isPostSeasonModalShouldBeShown = false;
		localStorage.setItem(this.storageKey, "true");
	}

	public checkPostSeasonModal() {
		const isViewedFromStorage = this.getIsPostSeasonModalViewed();
		this._isPostSeasonModalShouldBeShown =
			this._settings?.periodStatus === PeriodStatus.PostSeason && !isViewedFromStorage;

		if (
			!this._isPostSeasonModalShouldBeShown &&
			this._userStore.user?.postseasonModal === false
		) {
			void this._userStore.update({postseasonModal: true});
			this.postSeasonModalShown();
			this.clearSeasonState();
		}

		if (this.settings?.periodStatus === PeriodStatus.Season) {
			this.clearSeasonState();
		}
	}

	protected getIsPostSeasonModalViewed(): boolean {
		return JSON.parse(localStorage.getItem(this.storageKey) || "false") as boolean;
	}

	protected clearSeasonState(): void {
		localStorage.removeItem(this.storageKey);
	}
}
