import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";

import step1 from "assets/img/tutorial/step1.png";
import step2 from "assets/img/tutorial/step2.png";
import step3 from "assets/img/tutorial/step3.png";
import step4 from "assets/img/tutorial/step4.png";
import step5 from "assets/img/tutorial/step5.png";

export interface ISliderItem {
	img: string;
	title: string;
	description: string;
}

export interface ISliderScreenController extends ViewController {
	sliderItems: ISliderItem[];
	currentIndex: number;
	changeCurrentIndex: (index: number) => void;
	getActiveClass: (index: number) => string;
}

@injectable()
export class SliderScreenController implements ISliderScreenController {
	@observable sliderItems = [
		{
			img: step1,
			title: "tutorial.screen1.title",
			description: "tutorial.screen1.description",
		},
		{
			img: step2,
			title: "tutorial.screen2.title",
			description: "tutorial.screen2.description",
		},
		{
			img: step3,
			title: "tutorial.screen3.title",
			description: "tutorial.screen3.description",
		},
		{
			img: step4,
			title: "tutorial.screen4.title",
			description: "tutorial.screen4.description",
		},
		{
			img: step5,
			title: "tutorial.screen5.title",
			description: "tutorial.screen5.description",
		},
	];
	@observable currentIndex = 0;

	@action
	public changeCurrentIndex = (index: number) => {
		this.currentIndex = index;
	};

	public getActiveClass = (index: number) => {
		return this.currentIndex === index ? "active" : "";
	};

	constructor() {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
