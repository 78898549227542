import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Empty} from "data/types/generics";
import {IProp} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import i18next from "i18next";

interface IParams {
	propId: number;
}

export interface IProfileLinkBlockController extends ViewController<IParams> {
	openModal: () => void;
	closeModal: () => void;

	get propHasLink(): boolean;

	get propLinkText(): string;

	get isModalOpen(): boolean;

	get propLink(): string;

	get isYoutube(): boolean;
}

@injectable()
export class ProfileLinkBlockController implements IProfileLinkBlockController {
	protected _propId: Empty<number>;

	constructor(@inject(Bindings.ContestStore) private _contestStore: IContestStore) {
		makeAutoObservable(this);
	}

	private _isModalOpen: boolean = false;

	get isModalOpen(): boolean {
		return this._isModalOpen;
	}

	get propHasLink(): boolean {
		return Boolean(this.prop?.link);
	}

	get propLink(): string {
		return this.prop?.link || "";
	}

	get propLinkText(): string {
		if (!this.prop) {
			return "";
		}
		return this.prop.textLink || i18next.t("game.profile.default");
	}

	get isYoutube(): boolean {
		if (!this.prop?.link) {
			return true;
		}
		return this.prop.link.toLowerCase().includes("youtube");
	}

	protected get prop(): Empty<IProp> {
		return this._contestStore.getPropById(this._propId);
	}

	@action
	public openModal = (): void => {
		this._contestStore.isSliderDisabled = true;
		this._isModalOpen = true;
	};

	@action
	public closeModal = (): void => {
		this._contestStore.isSliderDisabled = false;
		this._isModalOpen = false;
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._propId = param.propId;
	}
}
