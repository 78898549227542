import React from "react";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITitleStore} from "data/stores/title/title.store";
import type {INotificationService} from "data/services/notification.service";
import {AxiosError} from "axios";
import {RequestState} from "data/enums";
import i18n from "i18next";

interface IError {
	errors: {message: string}[];
}

export interface IBonusCodesPageController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
	handleFormChange: (
		target: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	openSuccessModal: (amount: number) => void;
	error?: string;

	sendCode(): void;

	get isLoading(): boolean;

	get code(): string | undefined;
}

@injectable()
export class BonusCodesPageController implements IBonusCodesPageController {
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore,
		@inject(Bindings.NotificationService) private _notificationService: INotificationService
	) {
		makeAutoObservable(this);
	}

	@observable _error?: string = undefined;

	get error() {
		return this._error;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _code: string | undefined;

	get code(): string | undefined {
		return this._code;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this._titleStore.title = i18n.t("title.bonus.title");
	}

	handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>): void => {
		event.preventDefault();
		event.stopPropagation();
		this.sendCode();
	};

	@action
	public sendCode(): void {
		if (!this._code) {
			return;
		}

		this._userStore
			.sendBonusCode(this._code.trim())
			.then((res) => this.openSuccessModal(res.data.success.bonus.amount))
			.catch(this.onFormError)
			.finally(this.onComplete);
	}

	handleFormChange = (
		target: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		this._error = undefined;
		runInAction(() => {
			this._code = target.currentTarget.value;
		});
	};

	openSuccessModal = (amount: number) => {
		this._notificationService.showNotification({
			text: i18n.t("form.code.success_message", {X: amount || "-"}),
			timeToShow: 5000,
			cancellable: true,
			translate: false,
		});
	};

	private onComplete = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onFormError = (error: AxiosError<IError>) => {
		this._error = error.response?.data?.errors[0].message || error.message;
		this._requestState = RequestState.ERROR;
	};
}
