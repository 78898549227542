import {action, makeAutoObservable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IProp} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IParlayStore} from "data/stores/parlay/parlay.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IParlayPrefillService} from "data/services/parlayPrefill.service";
import {ModalType, PropStatus} from "data/enums";

export interface IParlayController extends ViewController {
	onChangeParlay: (checked: boolean, propId: number) => void;

	get votedProps(): IProp[];

	get totalPropsLength(): number;

	get parlayIds(): number[];

	get oddsCount(): number;
}

@injectable()
export class ParlayController implements IParlayController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.ParlayStore) private _parlayStore: IParlayStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ParlayPrefillService) private _parlayPrefillService: IParlayPrefillService
	) {
		makeAutoObservable(this);
	}

	get parlayIds(): number[] {
		return this._answersStore.parlayIds;
	}

	get votedProps(): IProp[] {
		if (!this._contestStore.selectedContest) {
			return [];
		}

		const answerIds = this._answersStore.answers
			.filter((e) => (e.bet ?? 0) > 0)
			.map((e) => e.questionId);

		return this._contestStore.selectedContest.props.filter(
			(e) => answerIds.includes(e.id) && e.status !== PropStatus.Canceled
		);
	}

	get totalPropsLength(): number {
		return this._contestStore.selectedContest?.props.length ?? 0;
	}

	get oddsCount(): number {
		return Math.pow(2, this._answersStore.parlayIds.length);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		reaction(
			() => this._contestStore.currentSliderStep,
			() => {
				this.checkParlayTutorialModal();
				this._answersStore.clearEmptyAnswers();
			}
		);
	}

	@action
	public onChangeParlay = (checked: boolean, propId: number): void => {
		const prop = this._contestStore.getPropById(propId);

		if (checked) {
			this._answersStore.addToParlay(prop);
		} else {
			this._answersStore.removeFromParlay(prop);
		}
	};

	protected prefillParlay(): void {
		const isPrefilled = this._parlayPrefillService.getOrGenerateParlayPrefill();
		if (isPrefilled) {
			this.votedProps.forEach((prop) => this._answersStore.addToParlay(prop));
		}
	}

	private checkParlayTutorialModal(): void {
		const currentStep = this._contestStore.currentSliderStep;
		const parlayStep = this._contestStore.currentContestProps.length + 1;

		// If not parlay slider step - skip
		if (currentStep < parlayStep) {
			return;
		}

		// If parlay step and tutorial hasn't been viewed - show tutorial
		if (!this._parlayStore.isParlayTutorialViewed) {
			this._modalsStore.showModal(ModalType.PARLAY_TUTORIAL);
		}
		this.prefillParlay();
	}
}
