import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface ITitleStore {
	get title(): string;
	set title(value: string);
}

@injectable()
export class TitleStore implements ITitleStore {
	@observable private _title: string = "";
	constructor() {
		makeAutoObservable(this);
	}

	get title(): string {
		return this._title;
	}

	set title(value: string) {
		this._title = value;
	}
}
