import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ILeague} from "data/types/entities";
import type {ITitleStore} from "data/stores/title/title.store";
import i18n from "i18next";

export interface IMyLeaguesController extends ViewController {
	loadMore: () => void;

	get leagues(): ILeague[];

	get isLoading(): boolean;

	get nextPage(): boolean;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.fetchLeagues();
		this._titleStore.title = i18n.t("title.leagues.my");
	}

	private fetchLeagues(): void {
		void this._leaguesStore.fetchMyLeagues();
	}

	get leagues(): ILeague[] {
		return this._leaguesStore.myLeagues;
	}

	get isLoading(): boolean {
		return this._leaguesStore.isMyLeaguesLoading;
	}

	public loadMore = (): void => {
		void this._leaguesStore.fetchLeaguesLoadMore();
	};

	get nextPage(): boolean {
		return this._leaguesStore.loadMoreMapper.myLeagues;
	}
}
