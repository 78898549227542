import {ViewController} from "data/types/structure";
import {IContest} from "data/types/entities";
import {inject, injectable} from "inversify";
import {makeAutoObservable, reaction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IStaticStore} from "data/stores/static/static.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import type {ILiveScoringStore} from "data/stores/liveScoring/livescoring.store";
import {Empty} from "data/types/generics";
import {ContestStatus, PropStatus} from "data/enums";

export interface IPicksPageController extends ViewController {
	get selectedContest(): Empty<IContest>;

	get isPicksAvailable(): boolean;

	get contestId(): number;

	get isCurrentPickSelected(): boolean;
}

@injectable()
export class PicksPageController implements IPicksPageController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore,
		@inject(Bindings.LiveScoringStore) private _liveScoringStore: ILiveScoringStore
	) {
		makeAutoObservable(this);
	}

	get selectedContest(): Empty<IContest> {
		return this._contestStore.selectedContest;
	}

	get isPicksAvailable(): boolean {
		const status = this.selectedContest?.status;
		const props = this.selectedContest?.props;
		if (!status || !props?.length) {
			return true;
		}

		const allPropsUnavailable = props.every((e) =>
			[PropStatus.Locked, PropStatus.Resulted, PropStatus.Canceled].includes(e.status)
		);
		if (allPropsUnavailable) {
			return false;
		}

		return ![ContestStatus.Canceled, ContestStatus.Complete, ContestStatus.Draft].includes(
			status
		);
	}

	get isCurrentPickSelected(): boolean {
		const step = this._contestStore.currentSliderStep;
		const prop = this.selectedContest?.props[step];

		if (!prop) {
			return true;
		}

		return Boolean(this._answersStore.getAnswerById(prop.id));
	}

	get contestId(): number {
		return this.selectedContest?.id || 0;
	}

	dispose(): void {
		this._liveScoringStore.unsubscribeLiveScoring();
	}

	init(param: void): void {
		this._liveScoringStore.subscribeLiveScoring();
		if (this.selectedContest) {
			void this._answersStore.fetchAnswersByContestId(this.selectedContest.id);
		}
		if (this._staticStore.players.length === 0) {
			void this._staticStore.fetchPlayers();
		}

		if (!this._dashboardStore.dashboardInfo) {
			void this._dashboardStore.fetchDashboard();
		}

		reaction(
			() => this._contestStore.selectedContest,
			(_) => {
				this.checkSliderSteps();
			}
		);
	}

	private checkSliderSteps() {
		this._contestStore.checkSliderStep();
	}
}
