import {ViewController} from "data/types/structure";
import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {IProp} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, PropStatus} from "data/enums";

export interface IPicksSliderController extends ViewController {
	updateStep: (step: number) => void;
	checkSlides: () => void;
	checkBetBeforeChangeSlide: (prev: number, next: number) => void;

	get currentPropIndex(): number;

	get props(): IProp[];

	get isParlayIndex(): boolean;

	get isPicksViewIndex(): boolean;

	get isSliderDisabled(): boolean;

	get contestId(): number;
}

@injectable()
export class PicksSliderController implements IPicksSliderController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get currentPropIndex(): number {
		return this._contestStore.currentSliderStep;
	}

	get props(): IProp[] {
		return this._contestStore.selectedContest?.props ?? [];
	}

	get isPicksViewIndex(): boolean {
		return this.currentPropIndex === this.props.length;
	}

	get isParlayIndex(): boolean {
		// +1 as we have picks review page
		return this.currentPropIndex >= this.props.length + 1;
	}

	get isSliderDisabled(): boolean {
		return this._contestStore.isSliderDisabled;
	}

	get contestId(): number {
		return this._contestStore.selectedContest?.id || 0;
	}

	public updateStep = (step: number) => {
		this._contestStore.currentSliderStep = step;
	};

	public checkSlides = () => {
		// Wait for slider fully init
		setTimeout(() => {
			this._contestStore.checkSliderStep();
		}, 500);
	};

	public checkBetBeforeChangeSlide = (prev: number, next: number) => {
		const property = this._contestStore.getQuestionByIndex(prev);
		// Prev === Next when navigate from picks summary page, fix for showing modal after init
		if (!property || property.status !== PropStatus.Open || prev === next) {
			return;
		}
		const answer = this._answersStore.getAnswerById(property.id);
		if (!answer || Number(answer.bet) > 0) {
			return;
		}
		this._modalsStore.showModal(ModalType.NO_PICKS_BET, {...{prev, next, answer}});
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
