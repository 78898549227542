import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType} from "data/enums";
import React from "react";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";

interface IResetForm extends HTMLFormElement {
	email: HTMLInputElement;
}

export interface IModalForgotPasswordController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<IResetForm>) => void;
	close: () => void;

	get isLoading(): boolean;

	get isOpen(): boolean;
}

@injectable()
export class ModalForgotPasswordController implements IModalForgotPasswordController {
	@observable private _email: string = "";

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.FORGOT_PASSWORD;
	}

	handleFormSubmit = (event: React.SyntheticEvent<IResetForm>) => {
		event.preventDefault();
		event.stopPropagation();

		const email = event.currentTarget.email.value;
		this.changeLoadingState(true);
		this._userStore
			.forgotPasswordRequest(email)
			.then(this.showSuccessModal)
			.catch(this.catchError)
			.finally(this.onFinally);
	};

	close = (): void => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	private showSuccessModal = () => {
		this.close();
		this._modalsStore.showModal(ModalType.SUCCESS, {
			title: "modal.password.email_sent",
			message: "modal.password.email_sent_body",
		});
	};

	private catchError = (e: unknown) => {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalsStore.showAxiosError(error);
	};

	private changeLoadingState = (value: boolean): void => {
		runInAction(() => {
			this._isLoading = value;
		});
	};

	private onFinally = () => {
		this.changeLoadingState(false);
	};
}
