import {Checkbox, FormLabel} from "@mui/material";
import styled from "@emotion/styled";

export const InputCheckbox = styled(Checkbox)({
	"&.MuiButtonBase-root.Mui-checked svg path": {
		color: "#121212",
	},

	"&.MuiButtonBase-root + span.MuiTypography-root": {
		fontWeight: 500,
		fontSize: "15px",
		lineHeight: "118%",
		letterSpacing: "0.01em",
		color: "#121212",

		a: {
			textDecoration: "underline",
		},
	},
});

export const CheckboxLabel = styled(FormLabel)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	letter-spacing: 0.01em;
	color: #121212 !important;
	margin-bottom: 8px;

	a {
		text-decoration: underline;
	}
`;
