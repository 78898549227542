import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {ISquad} from "data/types/api";
import {
	IAdvertisement,
	IArticleContent,
	ICategoriesContent,
	IChecksums,
	IContest,
	IPlayer,
	ISectionsContent,
	ISettings,
} from "data/types/entities";
import {withTimeStamp} from "data/utils/helpers";

export interface ICountry {
	name: string;
	code: string;
}

export type ISquadResponse = {squads: ISquad[]};
export type IContestsResponse = {contests: IContest[]};
export type IPlayersResponse = {players: IPlayer[]};

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;

	squads(): Promise<AxiosResponse<ISquadResponse>>;

	players(): Promise<AxiosResponse<IPlayersResponse>>;

	contests(): Promise<AxiosResponse<IContestsResponse>>;

	helpArticles(): Promise<AxiosResponse<IArticleContent>>;

	helpSections(): Promise<AxiosResponse<ISectionsContent>>;

	helpCategories(): Promise<AxiosResponse<ICategoriesContent>>;

	advertisement(): Promise<AxiosResponse<IAdvertisement>>;

	checksums(): Promise<AxiosResponse<IChecksums>>;

	settings(): Promise<AxiosResponse<ISettings>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _http: IHttpClientService,
		@inject(Bindings.JsonHelpClient) private _helpsHTTP: IHttpClientService
	) {}

	public countries = () => this._http.get<ICountry[]>("countries.json");

	public squads = () => this._http.get<ISquadResponse>("squads.json");
	public players = () => this._http.get<IPlayersResponse>("players.json");

	public contests = () => this._http.get<IContestsResponse>("contests.json");

	public advertisement = () => this._http.get<IAdvertisement>("sponsorAd.json");

	public helpArticles = () => this._helpsHTTP.get<IArticleContent>("articles.json");

	public helpCategories = () => this._helpsHTTP.get<ICategoriesContent>("categories.json");

	public helpSections = () => this._helpsHTTP.get<ISectionsContent>("sections.json");

	public checksums = () => this._http.get<IChecksums>(withTimeStamp("checksums.json"));

	public settings = () => this._http.get<ISettings>(withTimeStamp("settings.json"));
}
