import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IRankingsRow} from "data/types/entities";
import {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";

interface IParams {
	userId: number;
}

export interface ILeagueStandingsRowController extends ViewController<IParams> {
	togglePicks: () => void;

	get row(): Empty<IRankingsRow>;

	get isPicksOpen(): boolean;

	getUserClass: (userId: number) => string;
}

@injectable()
export class LeagueStandingsRowController implements ILeagueStandingsRowController {
	@observable private _userId: number = 0;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isPicksOpen: boolean = false;

	get isPicksOpen(): boolean {
		return this._isPicksOpen;
	}

	get row(): Empty<IRankingsRow> {
		return this._leaguesStore.standings.rankings.find((e) => e.userId === this._userId);
	}

	@action
	public togglePicks = () => {
		this._isPicksOpen = !this._isPicksOpen;
	};

	public getUserClass = (userId: number): string => {
		return userId === this._userStore.user?.id ? "user" : "";
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._userId = param.userId;
	}
}
