import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Empty} from "data/types/generics";
import {IJoinLeague} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {getSponsoredImageFromBackend} from "data/utils/helpers";

interface IParams {
	leagueId: number;
}

export interface ILeagueJoinItemController extends ViewController<IParams> {
	joinLeague: () => void;

	get league(): Empty<IJoinLeague>;

	get isLoading(): boolean;

	get isJoined(): boolean;

	get isSponsored(): boolean;

	get sponsorImageSrc(): Empty<string>;
}

@injectable()
export class LeagueJoinItemController implements ILeagueJoinItemController {
	@observable private _leagueId: number = 0;

	constructor(@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _isJoined: boolean = false;

	get isJoined(): boolean {
		return this._isJoined;
	}

	get league(): Empty<IJoinLeague> {
		return this._leaguesStore.getJoinLeagueById(this._leagueId);
	}

	get isSponsored(): boolean {
		return this.league?.class === "sponsored";
	}

	get sponsorImageSrc(): Empty<string> {
		if (!this.isSponsored || !this.league) {
			return;
		}
		return getSponsoredImageFromBackend(this.league.id);
	}

	@action
	public joinLeague = (): void => {
		if (!this.league) {
			return;
		}
		this._isLoading = true;

		this._leaguesStore
			.joinLeague(this.league.code)
			.then(() => {
				runInAction(() => {
					this._isJoined = true;
				});
			})
			.finally(() => {
				runInAction(() => {
					this._isLoading = false;
				});
			});
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._leagueId = param.leagueId;
	}
}
