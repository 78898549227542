import {PureButton} from "views/components/common/buttons/pureButton";
import styled from "@emotion/styled";

export const TertiaryButton = styled(PureButton)`
	background: transparent;
	color: ${({theme}) => theme.button.tertiary.color};
	border: 1px solid transparent;
	padding: 14px 22px;
	height: 48px;

	&:hover,
	&:active,
	&:focus &.active {
		opacity: ${({theme}) => theme.button.tertiary.hoverOpacity};
	}

	&:disabled {
		opacity: ${({theme}) => theme.button.tertiary.disabledOpacity};
	}

	&.underline {
		text-decoration: underline;
	}
`;
