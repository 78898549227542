import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IHOCSecretKeyController} from "views/components/HOCSecretKey/HOCSecretKey.controller";
import styled from "@emotion/styled";
import {ModalContent, ModalText, ModalTitle} from "views/components/modals/common";
import {Input} from "views/components/common";
import {useTranslation} from "react-i18next";
import Logo from "assets/img/icons/header-logo.png";
import {Exist} from "views/components/Exist";
import {IS_SECRET_ENABLED} from "data/constants";

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: ${({theme}) => theme.brandColor.white};
`;

const SModalContent = styled(ModalContent)`
	.logo {
		height: 150px;
		width: auto;
		margin-bottom: 24px;
	}
`;

interface IProps {
	children: React.ReactElement;
}

export const HOCSecretKey: React.FC<IProps> = observer(({children}) => {
	const {t} = useTranslation();
	const {handleLogoClick, handleInputChange, isSecretPassed, isFirstStepPassed} =
		useViewController<IHOCSecretKeyController>(Bindings.HOCSecretKeyController);

	if (!IS_SECRET_ENABLED) {
		return children;
	}

	if (isSecretPassed) {
		return children;
	}
	return (
		<Wrapper>
			<SModalContent>
				{/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
				<img onClick={handleLogoClick} src={Logo} className="logo" alt="logo" />

				<ModalTitle>{t("page.secret.title")}</ModalTitle>
				<ModalText>{t("page.secret.text")}</ModalText>

				<Exist when={isFirstStepPassed}>
					<form onChange={handleInputChange}>
						<Input fullWidth label="2+2=" />
					</form>
				</Exist>
			</SModalContent>
		</Wrapper>
	);
});
