import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IProp} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {Empty} from "data/types/generics";
import {IServerAnswer} from "data/types/api";
import {first} from "lodash";
import {PROP_TIME_FORMAT} from "data/constants";
import {DateTime} from "luxon";
import {PropStatus} from "data/enums";

export interface ISummaryParlayController extends ViewController {
	getPropIndexById: (propId: number) => number;
	getAnswerByProp: (propId: number) => Empty<IServerAnswer>;

	get props(): IProp[];

	get totalPropsLength(): number;

	get lockTime(): string;

	get isLocked(): boolean;

	get isComplete(): boolean;

	get isCanceled(): boolean;
}

@injectable()
export class SummaryParlayController implements ISummaryParlayController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get props(): IProp[] {
		return (
			this._contestStore.selectedContest?.props.filter((e) =>
				this._answersStore.parlayIds.includes(e.id)
			) ?? []
		);
	}

	get totalPropsLength(): number {
		return this._contestStore.selectedContest?.props.length ?? 1;
	}

	private get firstProp(): Empty<IProp> {
		return first(
			this.props.sort((propOne, propTwo) => {
				const startOne = new Date(propOne.lockDate).getTime();
				const startTwo = new Date(propTwo.lockDate).getTime();

				if (startOne === startTwo) {
					return 0;
				}

				return startOne > startTwo ? 1 : -1;
			})
		);
	}

	get lockTime(): string {
		if (!this.firstProp) {
			return "";
		}

		return DateTime.fromISO(this.firstProp.lockDate).toFormat(PROP_TIME_FORMAT).toString();
	}

	public getPropIndexById = (propId: number): number => {
		if (!this._contestStore.selectedContest) {
			return 0;
		}
		return this._contestStore.selectedContest.props.findIndex((e) => e.id === propId) + 1;
	};

	public getAnswerByProp = (propId: number): Empty<IServerAnswer> => {
		return this._answersStore.answers.find((e) => e.questionId === propId);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isCanceled(): boolean {
		return false;
	}

	get isComplete(): boolean {
		return this.props.every((e) => e.status === PropStatus.Resulted);
	}

	get isLocked(): boolean {
		return this.props.some((e) => [PropStatus.Resulted, PropStatus.Locked].includes(e.status));
	}
}
