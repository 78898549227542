import {ViewController} from "data/types/structure";
import {Guess, MediaType, PropStatus, PropType} from "data/enums";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {Empty, Nullable} from "data/types/generics";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IStaticStore} from "data/stores/static/static.store";
import {IServerAnswer} from "data/types/api";
import {IMedia, IProp} from "data/types/entities";
import {first} from "lodash";
import {formatPropTime, getEmptyStringValue, getImageFromBackend} from "data/utils/helpers";

interface IParams {
	questionId: number;
}

export interface IPickCardController extends ViewController<IParams> {
	guess: (guess: Guess) => void;
	onSliderUpdate: (value: number, emit?: boolean) => void;

	get sliderValue(): number;

	get isQuestionAnswered(): boolean;

	get propCount(): number;

	get propPosition(): number;

	get overClass(): string;

	get underClass(): string;

	get isSponsored(): boolean;

	get multiplier(): number;

	get image(): string;

	get mediaType(): string;

	get propLockDate(): string;

	get isPropCancelled(): boolean;

	get isPropLocked(): boolean;

	get isPropsComplete(): boolean;

	get isButtonDisabled(): boolean;

	get availableFootballs(): number;

	get propIndex(): number;
}

@injectable()
export class PickCardController implements IPickCardController {
	@observable private _questionId: Nullable<number> = null;

	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore
	) {
		makeAutoObservable(this);
	}

	get isPropCancelled(): boolean {
		return this.prop?.status === PropStatus.Canceled;
	}

	get isPropLocked(): boolean {
		return this.prop?.status === PropStatus.Locked;
	}

	get isPropsComplete(): boolean {
		return this.prop?.status === PropStatus.Resulted;
	}

	get isButtonDisabled(): boolean {
		return this.isPropLocked || this.isPropCancelled || this.isPropsComplete;
	}

	get sliderValue(): number {
		if (this.isPropCancelled) {
			return 0;
		}

		return this.answer?.bet || 0;
	}

	get answer(): Empty<IServerAnswer> {
		return this._answersStore.getAnswerById(this._questionId);
	}

	get isQuestionAnswered(): boolean {
		return Boolean(this.answer);
	}

	get overClass(): string {
		return this.answer?.pick === Guess.Over ? "active" : "";
	}

	get underClass(): string {
		return this.answer?.pick === Guess.Under ? "active" : "";
	}

	get propCount(): number {
		return this._contestStore.selectedContest?.props.length ?? 1;
	}

	get propPosition(): number {
		return this._contestStore.getQuestionIndexById(this._questionId) + 1;
	}

	get isSponsored(): boolean {
		return this.prop?.type === PropType.Sponsored;
	}

	get image(): string {
		const mediaContent = first(this.prop?.media);
		return this.getPropImage(mediaContent);
	}

	get mediaType(): string {
		const mediaContent = first(this.prop?.media);
		return mediaContent?.type || "";
	}

	get multiplier(): number {
		return this.prop?.multiplier ?? 2;
	}

	get propLockDate(): string {
		return formatPropTime(this.prop);
	}

	get availableFootballs(): number {
		const canceledPropIds = this._contestStore.currentContestProps
			.filter((e) => e.status === PropStatus.Canceled)
			.map((e) => e.id);
		const cancelled = this._answersStore.answers
			.filter((e) => canceledPropIds.includes(e.questionId))
			.reduce((acc, value) => acc + (value?.bet || 0), 0);

		return this._answersStore.availableFootballs + cancelled;
	}

	get propIndex(): number {
		return this._contestStore.getQuestionIndexById(this._questionId);
	}

	private get prop(): Empty<IProp> {
		if (!this._questionId) return;

		return this._contestStore.getPropById(this._questionId);
	}

	dispose(): void {
		return;
	}

	@action
	public onSliderUpdate = (value: number, emit = true): void => {
		if (!this.answer) {
			return;
		}

		this._answersStore.answerQuestion(
			{
				questionId: this.answer.questionId,
				pick: this.answer.pick,
				bet: value,
			},
			emit
		);
	};

	public guess = (guess: Guess): void => {
		if (!this._questionId) {
			console.warn("Invalid question id!");
			return;
		}

		if (this.answer?.pick === guess) {
			this._answersStore.clearAnswerById(this._questionId);
			return;
		}

		console.log("answer");
		this._answersStore.answerQuestion(
			{
				questionId: this._questionId,
				pick: guess,
			},
			true
			// Boolean(this.answer?.bet)
		);
	};

	init(param: IParams): void {
		this._questionId = param.questionId;
	}

	private prepareValue(value: number): number {
		if (value < 0) {
			return 0;
		}
		const availableFootballs = this._answersStore.availableFootballs;
		const max = availableFootballs + this.sliderValue;
		if (value > max) {
			return max;
		}
		return value;
	}

	private getPropImage(mediaContent?: IMedia): string {
		if (!mediaContent) {
			return "";
		}
		const squad = this._staticStore.getSquadById(Number(mediaContent.image || mediaContent.id));
		const player = this._staticStore.getPlayerById(
			Number(mediaContent.image || mediaContent.id)
		);

		switch (mediaContent.type) {
			case MediaType.Custom:
				return getImageFromBackend(getEmptyStringValue(mediaContent.image));
			case MediaType.Player:
				return getImageFromBackend(getEmptyStringValue(player?.headshot));
			case MediaType.Squad:
				return getImageFromBackend(getEmptyStringValue(squad?.logo));
			default:
				return "";
		}
	}
}
