import React from "react";
import {PureButton} from "views/components/common";
import styled from "@emotion/styled";
import {getClassNameAccordingStatement} from "data/utils/helpers";

interface IProps {
	isOpen: boolean;
	onClick: () => void;
}

const MenuButtonElement = styled(PureButton)`
	position: relative;
	top: 0;
	display: flex;
	flex-direction: column;
	gap: 3px;
	width: 24px;
	padding: 6px 3px;
	transition: 0.25s;

	.bar-1,
	.bar-2,
	.bar-3 {
		width: 100%;
		height: 2px;
		-webkit-border-radius: 2px;
		-moz-border-radius: 2px;
		border-radius: 2px;
		background: ${({theme}) => theme.brandColor.black};
		transition: 0.4s;
	}

	&.open {
		z-index: 992;
		top: 12px;

		> div {
			background: #ffffff;
		}

		.bar-1 {
			transform: translate(0, 5px) rotate(-45deg);
		}

		.bar-2 {
			opacity: 0;
		}

		.bar-3 {
			transform: translate(0, -5px) rotate(45deg);
		}
	}
`;

export const MenuButton: React.FC<IProps> = ({isOpen, onClick}) => {
	const className = getClassNameAccordingStatement("open", isOpen);

	return (
		<MenuButtonElement className={className} onClick={onClick}>
			<div className={`bar-1 ${className}`} />
			<div className="bar-2" />
			<div className="bar-3" />
		</MenuButtonElement>
	);
};
