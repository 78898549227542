import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IModalBeforePicksSaveController extends ViewController {
	get isOpen(): boolean;
	close: () => void;
	login: () => void;
	register: () => void;
}

@injectable()
export class ModalBeforePicksSaveController implements IModalBeforePicksSaveController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	public login = (): void => {
		this.close();
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	public register = (): void => {
		this.close();
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.BEFORE_PICKS_SAVE;
	}
}
