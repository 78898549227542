import {IContest, IProp} from "data/types/entities";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction, toJS} from "mobx";
import {ContestStatus, PropStatus, SummaryTab} from "data/enums";
import type {Empty, NullOrEmpty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {IContestsResponse, IJSONProvider} from "data/providers/json/json.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError, AxiosResponse} from "axios";
import {IAxiosApiError} from "data/types/api";

export interface IContestStore {
	get contests(): IContest[];

	get availableContests(): IContest[];

	get selectedContest(): Empty<IContest>;

	get currentContest(): Empty<IContest>;

	get currentContestProps(): IProp[];

	get firstOpenContest(): Empty<IContest>;

	get isLoading(): boolean;

	get currentSliderStep(): number;

	set currentSliderStep(value: number);

	get nonLockedProps(): IProp[];

	get nonLockedPropIds(): number[];

	get isSliderDisabled(): boolean;

	set isSliderDisabled(value: boolean);

	get lastActiveTab(): Empty<SummaryTab>;

	set lastActiveTab(value: Empty<SummaryTab>);

	fetchContests(): Promise<void>;

	setContestById(contestId: number): boolean;

	getQuestionIndexById(questionId: NullOrEmpty<number>): number;

	getQuestionByIndex(index: NullOrEmpty<number>): Empty<IProp>;

	getPropById(propId: Empty<number>): Empty<IProp>;

	nextQuestion(): void;

	getContestById(contestId: number): Empty<IContest>;

	checkSliderStep(): void;
}

@injectable()
export class ContestStore implements IContestStore {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _lastActiveTab: Empty<SummaryTab>;

	get lastActiveTab(): Empty<SummaryTab> {
		return this._lastActiveTab;
	}

	set lastActiveTab(value: Empty<SummaryTab>) {
		this._lastActiveTab = value;
	}

	@observable private _isSliderDisabled: boolean = false;

	get isSliderDisabled(): boolean {
		return this._isSliderDisabled;
	}

	set isSliderDisabled(value: boolean) {
		this._isSliderDisabled = value;
	}

	@observable private _currentSliderStep = 0;

	get currentSliderStep(): number {
		return this._currentSliderStep;
	}

	set currentSliderStep(value: number) {
		this._currentSliderStep = value;
	}

	@observable private _selectedContest: Empty<IContest>;

	get selectedContest(): Empty<IContest> {
		return this._selectedContest || this.currentContest;
	}

	@observable private _contests: IContest[] = [];

	public get contests(): IContest[] {
		return this._contests;
	}

	public get availableContests(): IContest[] {
		return this.contests.filter(
			(e) => ![ContestStatus.Canceled, ContestStatus.Draft].includes(e.status)
		);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get currentContestProps(): IProp[] {
		return this.selectedContest?.props || [];
	}

	get currentContest(): Empty<IContest> {
		const liveContest = this.contests.find((e) => e.status === ContestStatus.Live);
		const openContest = this.contests.find((e) => e.status === ContestStatus.Open);
		const completeContest = this.contests.find((e) => e.status === ContestStatus.Complete);
		return liveContest ?? openContest ?? completeContest;
	}

	get firstOpenContest(): Empty<IContest> {
		return this.contests.find((e) => e.status === ContestStatus.Open);
	}

	get nonLockedProps(): IProp[] {
		if (!this.selectedContest) {
			return [];
		}

		return this.selectedContest.props.filter((e) => e.status === PropStatus.Open);
	}

	get nonLockedPropIds(): number[] {
		return this.nonLockedProps.map((e) => e.id);
	}

	public getQuestionIndexById(questionId: NullOrEmpty<number>): number {
		return this.selectedContest?.props.findIndex((e) => e.id === questionId) ?? -1;
	}

	public getQuestionByIndex(index: NullOrEmpty<number>): Empty<IProp> {
		if (index === null || index === undefined) {
			return undefined;
		}
		return this.currentContestProps?.[index];
	}

	public getPropById(propId: Empty<number>): Empty<IProp> {
		return this.selectedContest?.props.find((e) => e.id === propId);
	}

	public getContestById(contestId: number): Empty<IContest> {
		return this.contests.find((e) => e.id === contestId);
	}

	public fetchContests() {
		if (this.isLoading) {
			return Promise.reject("Fetch contests request already in progress");
		}

		this.changLoadingState(true);
		return this._jsonProvider
			.contests()
			.then(this.onSuccess)
			.catch(this.onError)
			.finally(this.onFinally);
	}

	@action
	public setContestById(contestId: number): boolean {
		const contest = this.contests.find((e) => e.id === contestId);
		if (!contest) {
			return false;
		}

		this._selectedContest = contest;
		this._currentSliderStep = 0;
		return true;
	}

	@action
	public nextQuestion(): void {
		if (this.currentSliderStep === this.currentContestProps.length - 1) {
			return;
		}
		this._currentSliderStep += 1;
	}

	@action
	public checkSliderStep(): void {
		if (!this.selectedContest || this._currentSliderStep > 0) {
			const step = toJS(this._currentSliderStep);
			setTimeout(() => {
				this._currentSliderStep = step;
			}, 0);
			return;
		}

		const firstNonLocked = this.selectedContest.props.findIndex(
			(e) => e.status === PropStatus.Open
		);
		if (firstNonLocked !== -1) {
			this._currentSliderStep = firstNonLocked;
		}
	}

	@action
	private changLoadingState(value: boolean): void {
		runInAction(() => {
			this._isLoading = value;
		});
	}

	@action
	private onSuccess = (response: AxiosResponse<IContestsResponse>) => {
		runInAction(() => {
			this._contests = response.data.contests;

			// Re apply contest
			const selectedFromNew = this.contests.find((e) => e.id === this._selectedContest?.id);
			if (this._selectedContest && selectedFromNew) {
				this._selectedContest = selectedFromNew;
			}
		});
	};

	private onError = (e: unknown) => {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalsStore.showAxiosError(error);
	};

	private onFinally = () => {
		this.changLoadingState(false);
	};
}
