import {ViewController} from "data/types/structure";
import {IProp} from "data/types/entities";
import type {Empty} from "data/types/generics";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType, PropStatus} from "data/enums";
import {NavigateFunction} from "react-router-dom";
import {noop} from "lodash";

interface IParams {
	navigate: NavigateFunction;
}

export interface IPicksActionBarController extends ViewController<IParams> {
	saveContestAnswers: () => void;
	skipContestAnswers: () => Promise<void>;
	editProps: () => void;
	goToParlay: () => void;
	beforeGoToParlay: () => void;
	goToSummary: () => void;
	handleEditSave: () => void;

	get currentQuestion(): Empty<IProp>;

	get isParlay(): boolean;

	get isSaving(): boolean;

	get isPreviewSlide(): boolean;

	get canBeSaved(): boolean;

	get userHasAnswers(): boolean;

	get userHasParlay(): boolean;
}

@injectable()
export class PicksActionBarController implements IPicksActionBarController {
	@observable private _navigate: Empty<NavigateFunction>;

	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get currentQuestion(): Empty<IProp> {
		return this._contestStore.currentContest?.props[this.currentPropIndex];
	}

	get isParlay(): boolean {
		// +1 as we now have picks review page
		return this._contestStore.currentSliderStep === this.propsLength + 1;
	}

	get isSaving(): boolean {
		return this._answersStore.isSaving;
	}

	get canBeSaved(): boolean {
		return this._answersStore.parlayIds.length > 1 && !this.isSaving;
	}

	get isPreviewSlide(): boolean {
		return this._contestStore.currentSliderStep === this.propsLength;
	}

	get userHasAnswers(): boolean {
		return this._answersStore.userHasAnswers;
	}

	get userHasParlay() {
		return this._answersStore.userHasSavedParlay;
	}

	private get propsLength(): number {
		return this._contestStore.selectedContest?.props.length || 0;
	}

	private get currentPropIndex(): number {
		return this._contestStore.currentSliderStep;
	}

	private get votedParlayProps(): IProp[] {
		if (!this._contestStore.selectedContest) {
			return [];
		}

		const answerIds = this._answersStore.parlayAnswers.map((e) => e.questionId);

		return this._contestStore.selectedContest.props.filter((e) => answerIds.includes(e.id));
	}

	private get isParlayLocked(): boolean {
		return this.votedParlayProps.some((e) => e.status !== PropStatus.Open);
	}

	private get hasEnoughAnswers(): boolean {
		return this._answersStore.answers.length > 1;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
	}

	public saveContestAnswers = () => {
		if (!this.isParlayLocked && this._userStore.isAuthorized) {
			void this._answersStore
				.saveParlayForContest(this._contestStore.selectedContest?.id)
				.then(() => {
					void this.skipContestAnswers();
				});
		} else {
			void this.skipContestAnswers();
		}
	};

	public skipContestAnswers = () => {
		if (!this._contestStore.selectedContest) {
			return Promise.reject("Selected contest not found");
		}
		if (!this._answersStore.answersChanged) {
			this.goToSummary();
			return Promise.reject();
		}
		if (!this._userStore.isAuthorized) {
			this._modalsStore.showModal(ModalType.BEFORE_PICKS_SAVE);
			return Promise.reject();
		}

		return this._answersStore.saveAnswers(this._contestStore.selectedContest?.id);
	};

	public editProps = () => {
		this._contestStore.currentSliderStep = 0;
	};
	public goToParlay = () => {
		if (this.isParlayLocked) {
			this.goToSummary();
			return;
		}
		this._contestStore.currentSliderStep = this.propsLength + 1;
	};

	public beforeGoToParlay = () => {
		if (this.isParlayLocked || !this.hasEnoughAnswers) {
			this.skipContestAnswers()
				.then(() => {
					this.goToSummary();
				})
				.catch(noop);
			return;
		}
		this.goToParlay();
	};

	public goToSummary = () => {
		const ids = this._answersStore.answers.map((e) => e.id || 0);
		this._answersStore.clearAnswers(ids);
		this._answersStore.answersChanged = false;

		const contestId = this._contestStore.selectedContest?.id;

		// Wait for isBlocking rerender
		setTimeout(() => {
			if (contestId && this._navigate) {
				this._navigate(`/picks/${contestId}/summary`);
			}
		}, 200);
	};

	public handleEditSave = () => {
		if (this.isParlayLocked || !this.hasEnoughAnswers) {
			this.skipContestAnswers()
				.then(() => {
					this.goToSummary();
				})
				.catch(noop);
			return;
		}

		this.goToParlay();
	};
}
