import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import {ContestStatus, PropStatus} from "data/enums";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import {Empty} from "data/types/generics";

export interface IScoreBarController extends ViewController {
	get availableFootballs(): number;

	get earnedFootballs(): number;

	get answersCount(): number;

	get isLive(): boolean;

	get isOpen(): boolean;

	get isComplete(): boolean;

	get isLiveOrComplete(): boolean;

	get rank(): Empty<number>;

	get potentialEarning(): number;
}

@injectable()
export class ScoreBarController implements IScoreBarController {
	constructor(
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore
	) {
		makeAutoObservable(this);
	}

	get availableFootballs(): number {
		const canceledPropIds = this._contestStore.currentContestProps
			.filter((e) => e.status === PropStatus.Canceled)
			.map((e) => e.id);
		const cancelled = this._answersStore.answers
			.filter((e) => canceledPropIds.includes(e.questionId))
			.reduce((acc, value) => acc + (value?.bet || 0), 0);

		return this._answersStore.availableFootballs + cancelled;
	}

	get earnedFootballs(): number {
		return this._answersStore.earnedFootballs;
	}

	get answersCount(): number {
		return this._answersStore.answersLength;
	}

	get isComplete(): boolean {
		return this._contestStore.selectedContest?.status === ContestStatus.Complete;
	}

	get isLive(): boolean {
		return this._contestStore.selectedContest?.status === ContestStatus.Live;
	}

	get isOpen(): boolean {
		return this._contestStore.selectedContest?.status === ContestStatus.Open;
	}

	get isLiveOrComplete(): boolean {
		return this.isLive || this.isComplete;
	}

	get rank(): Empty<number> {
		const contest = this._contestStore.selectedContest;
		const dashboardInfo = this._dashboardStore.dashboardInfo;

		if (!contest || !dashboardInfo) {
			return;
		}

		const contestInfo = dashboardInfo.contests.find((e) => e.contestId === contest.id);
		return contestInfo?.contestRank;
	}

	get potentialEarning(): number {
		const fromAnswers = this.placedFootballs * 2;
		const fromParlay = this.parlayFootballs;

		return fromAnswers + fromParlay;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	private get placedFootballs(): number {
		return this._answersStore.answers.reduce((acc, value) => acc + (value.bet || 0), 0);
	}

	private get parlayFootballs(): number {
		if (!this._answersStore.parlayAnswers.length) {
			return 0;
		}

		return Math.pow(2, this._answersStore.parlayAnswers.length) * 30;
	}
}
