import {ViewController} from "data/types/structure";
import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IMenuStore} from "data/stores/menu/menu.store";

export interface IHeaderController extends ViewController {
	logout: () => void;
	toggleMenu: () => void;

	get isLogged(): boolean;

	get isMenuOpen(): boolean;
}

@injectable()
export class HeaderController implements IHeaderController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore
	) {
		makeAutoObservable(this);
	}

	public get isMenuOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	public get isLogged(): boolean {
		return this._userStore.isAuthorized;
	}

	public toggleMenu = (): void => {
		this._menuStore.toggleMenu();
	};

	public logout = (): void => {
		void this._userStore.logout();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
