import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable} from "inversify";
import React from "react";

export interface IHOCSecretKeyController extends ViewController {
	handleLogoClick: () => void;
	handleInputChange: (event: React.ChangeEvent<HTMLFormElement>) => void;

	get isSecretPassed(): boolean;

	get isFirstStepPassed(): boolean;
}

@injectable()
export class HOCSecretKeyController implements IHOCSecretKeyController {
	@observable private _secretClick: number = 0;
	private readonly _secretPhrase: string = "four";
	private readonly _storageKey: string = "94ab_secret_key";

	constructor() {
		makeAutoObservable(this);
	}

	@observable private _isSecretPassed: boolean = false;

	get isSecretPassed(): boolean {
		return this._isSecretPassed;
	}

	@observable private _isFirstStepPassed: boolean = false;

	get isFirstStepPassed(): boolean {
		return this._isFirstStepPassed;
	}

	@action
	public handleLogoClick = () => {
		this._secretClick += 1;

		if (this._secretClick > 7) {
			this._isFirstStepPassed = true;
		}
	};

	public handleInputChange = (event: React.ChangeEvent<HTMLFormElement>) => {
		if (String(event.target.value).toLowerCase() === this._secretPhrase) {
			this.passSecret();
		}
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.checkStorage();
	}

	@action
	private checkStorage(): void {
		this._isSecretPassed = JSON.parse(
			localStorage.getItem(this._storageKey) || "false"
		) as boolean;
	}

	@action
	private passSecret(): void {
		this._isSecretPassed = true;
		localStorage.setItem(this._storageKey, JSON.stringify(true));
	}
}
