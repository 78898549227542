import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Empty} from "data/types/generics";
import type {ILeague, ILeagueEditPayload} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IStaticStore} from "data/stores/static/static.store";
import {LeaguePrivacy} from "data/enums";
import React from "react";
import {noop} from "lodash";

export interface ILeagueAboutController extends ViewController {
	onFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
	handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onFormSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;

	get league(): Empty<ILeague>;

	get isCommissioner(): boolean;

	get form(): ILeagueEditPayload;

	get isFormHasChanges(): boolean;

	get isFormDisabled(): boolean;

	get isLoading(): boolean;

	get hasAdvertisement(): boolean;
}

@injectable()
export class LeagueAboutController implements ILeagueAboutController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _form: ILeagueEditPayload = {
		name: "",
		privacy: LeaguePrivacy.Public,
	};

	get form(): ILeagueEditPayload {
		return this._form;
	}

	get league(): Empty<ILeague> {
		return this._leaguesStore.selectedLeague;
	}

	get isCommissioner(): boolean {
		if (!this.league) return false;

		return this.league?.userId === this._userStore.user?.id;
	}

	get isFormHasChanges(): boolean {
		return this.form.name !== this.league?.name || this.form.privacy !== this.league?.privacy;
	}

	get isFormDisabled(): boolean {
		return !this.isFormHasChanges || this.isLoading;
	}

	get hasAdvertisement(): boolean {
		const adv = this._staticStore.advertisement;
		return Boolean(adv) && Boolean(adv?.fileName);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		if (this.league) {
			runInAction(() => {
				this._form = {
					name: this.league?.name || "",
					privacy: this.league?.privacy || LeaguePrivacy.Public,
				};
			});
		}
	}

	public handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = event.target;

		if (!name) {
			return;
		}

		this.changeForm(name, String(value));
	};

	public onFormChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
		const {name, value} = event.target;

		if (!name) {
			return;
		}

		this.changeForm(name, String(value));
	};

	@action
	public onFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>): void => {
		event.preventDefault();
		event.stopPropagation();

		if (!this.league) {
			return;
		}

		this._isLoading = true;
		this._leaguesStore
			.updateLeagueById(this.league.id, this.form)
			.then(() => {
				this._isLoading = false;
			})
			.catch(noop);
	};

	@action
	private changeForm(name: string, value: string) {
		this._form = {...this._form, [name]: value};
	}
}
