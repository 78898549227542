import {AbstractApiProvider} from "data/providers/api/abstract.api.provider";
import type {IApiAxiosResponse, IHttpClientService} from "data/services/http";
import {
	IDefaultLeagueGetParams,
	IInviteLeagueParams,
	ILeagueCreatePayload,
	IMyLeaguesResponse,
	IOverallRankingsParams,
	IOverallRankingsResponse,
	IServerAnswersResponse,
	IShowForJoinLeaguesResponse,
	ISingleLeaguesResponse,
} from "data/types/api";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {DEFAULT_LEAGUES_FETCH_LIMIT} from "data/constants";
import type {ILeague, ILeagueEditPayload, ILeagueUsersResponse} from "data/types/entities";

export interface ILeaguesApiProvider {
	fetchMyLeagues(
		params?: IDefaultLeagueGetParams
	): Promise<IApiAxiosResponse<IMyLeaguesResponse>>;

	fetchLeagueById(leagueId: number): Promise<IApiAxiosResponse<ISingleLeaguesResponse>>;

	fetchLeagueByCode(leagueCode: string): Promise<IApiAxiosResponse<ILeague>>;

	leaveLeague(leagueId: number): Promise<IApiAxiosResponse<unknown>>;

	updateLeagueById(
		leagueId: number,
		payload: ILeagueEditPayload
	): Promise<IApiAxiosResponse<ISingleLeaguesResponse>>;

	fetchShowForJoinLeagues(
		params?: IDefaultLeagueGetParams
	): Promise<IApiAxiosResponse<IShowForJoinLeaguesResponse>>;

	fetchLeagueUsers(
		leagueId: number,
		params?: IDefaultLeagueGetParams
	): Promise<IApiAxiosResponse<ILeagueUsersResponse>>;

	removeUserFormLeague(leagueId: number, userId: number): Promise<IApiAxiosResponse<unknown>>;

	joinToLeagueByCode(code: string): Promise<IApiAxiosResponse<ISingleLeaguesResponse>>;

	createLeague(params: ILeagueCreatePayload): Promise<IApiAxiosResponse<ISingleLeaguesResponse>>;

	inviteLeague(params: IInviteLeagueParams): Promise<IApiAxiosResponse<ISingleLeaguesResponse>>;

	fetchLeagueStandings(
		leagueId: number,
		params: IOverallRankingsParams
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>>;

	fetchUserPicks(
		userId: number,
		contestId: number
	): Promise<IApiAxiosResponse<IServerAnswersResponse>>;
}

@injectable()
export class LeaguesApiProvider extends AbstractApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {
		super();
	}

	public fetchLeagueById(leagueId: number): Promise<IApiAxiosResponse<ISingleLeaguesResponse>> {
		return this._http.get(`${this.language}/pickem/league/${leagueId}`);
	}

	public fetchLeagueByCode(leagueCode: string): Promise<IApiAxiosResponse<ILeague>> {
		return this._http.get(`${this.language}/pickem/league/show-by-code`, {leagueCode});
	}

	public leaveLeague(leagueId: number): Promise<IApiAxiosResponse<ISingleLeaguesResponse>> {
		return this._http.post(`${this.language}/pickem/league/${leagueId}/leave`);
	}

	public updateLeagueById(
		leagueId: number,
		payload: ILeagueEditPayload
	): Promise<IApiAxiosResponse<ISingleLeaguesResponse>> {
		return this._http.post(`${this.language}/pickem/league/${leagueId}`, payload);
	}

	public fetchMyLeagues(
		params?: IDefaultLeagueGetParams
	): Promise<IApiAxiosResponse<IMyLeaguesResponse>> {
		return this._http.get(`${this.language}/pickem/leagues`, {
			limit: DEFAULT_LEAGUES_FETCH_LIMIT,
			...params,
		});
	}

	public fetchShowForJoinLeagues(
		params: IDefaultLeagueGetParams = {}
	): Promise<IApiAxiosResponse<IShowForJoinLeaguesResponse>> {
		return this._http.get(`${this.language}/pickem/league/show-for-join`, {
			limit: DEFAULT_LEAGUES_FETCH_LIMIT,
			...params,
		});
	}

	public joinToLeagueByCode(code: string): Promise<IApiAxiosResponse<ISingleLeaguesResponse>> {
		return this._http.post(`${this.language}/pickem/league/${code}/join`);
	}

	public createLeague(
		params: ILeagueCreatePayload
	): Promise<IApiAxiosResponse<ISingleLeaguesResponse>> {
		return this._http.post(`${this.language}/pickem/league`, params);
	}

	public inviteLeague = (
		params: IInviteLeagueParams
	): Promise<IApiAxiosResponse<ISingleLeaguesResponse>> => {
		return this._http.post(`${this.language}/pickem/league/${params.league_id}/invite`, {
			invites: params.emails,
		});
	};

	public fetchLeagueUsers(
		leagueId: number,
		params?: IDefaultLeagueGetParams
	): Promise<IApiAxiosResponse<ILeagueUsersResponse>> {
		return this._http.get(`/${this.language}/pickem/league/${leagueId}/league-users`, {
			limit: DEFAULT_LEAGUES_FETCH_LIMIT,
			...params,
		});
	}

	public removeUserFormLeague(
		leagueId: number,
		userId: number
	): Promise<IApiAxiosResponse<unknown>> {
		return this._http.post(`/${this.language}/pickem/league/delete/${leagueId}/${userId}`);
	}

	public fetchLeagueStandings(
		leagueId: number,
		params: IOverallRankingsParams = {}
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>> {
		return this._http.get(`/${this.language}/ranking/league/${leagueId}`, params);
	}

	fetchUserPicks(
		userId: number,
		contestId: number
	): Promise<IApiAxiosResponse<IServerAnswersResponse>> {
		return this._http.get(`/${this.language}/answers/user/${userId}/${contestId}`);
	}
}
