import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {PropStatus} from "data/enums";

export interface IRangeSliderController extends ViewController {
	get min(): number;

	get max(): number;

	onDragStart: () => void;

	onDragEnd: () => void;
}

@injectable()
export class RangeSliderController implements IRangeSliderController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	public onDragStart = () => {
		this._contestStore.isSliderDisabled = true;

		setTimeout(() => {
			this.onDragEnd();
		}, 500);
	};

	public onDragEnd = () => {
		this._contestStore.isSliderDisabled = false;
	};

	private get canceledFootballs(): number {
		const canceledPropIds = this._contestStore.currentContestProps
			.filter((e) => e.status === PropStatus.Canceled)
			.map((e) => e.id);
		return this._answersStore.answers
			.filter((e) => canceledPropIds.includes(e.questionId))
			.reduce((acc, value) => acc + (value?.bet || 0), 0);
	}

	get max(): number {
		return this._answersStore.availableFootballs + this.canceledFootballs;
	}

	get min(): number {
		return 0;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
