import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router-dom";
import {isEqual, orderBy, snakeCase, sortBy} from "lodash";
import type {IStaticStore} from "data/stores/static/static.store";
import {IArticleEntity, ISectionsContent} from "data/types/entities";
import {Empty} from "data/types/generics";

interface IControllerProps {
	navigate: ReturnType<typeof useNavigate>;
	pathname: string;
}

export interface IHelpController extends ViewController<IControllerProps> {
	updatePathname: (pathname: string) => void;
	buildPathName: (pathname: string) => string;
	isActiveTab: (tabId: number) => boolean;
	isContactUs: (tabId: number) => boolean;

	get tabs(): ISectionsContent["sections"];

	get articles(): Empty<IArticleEntity[]>;

	get currentTab(): number | null;

	findSectionsById(sectionId: number): IArticleEntity[];
}

@injectable()
export class HelpController implements IHelpController {
	private static rootPath = "/help";
	private _navigate!: IControllerProps["navigate"];

	constructor(@inject(Bindings.StaticStore) private _staticStore: IStaticStore) {
		makeAutoObservable(this);
	}

	private _currentTab: number | null = null;

	get currentTab() {
		return this._currentTab;
	}

	get tabs() {
		return sortBy(this._staticStore.sections, "position");
	}

	get articles() {
		return this._staticStore.articles;
	}

	public findSectionsById(sectionId: number) {
		const sections = this.articles?.filter((article) => article.section_id === sectionId) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	public updatePathname = (pathname: string) => {
		const newTab =
			this.tabs.find((it) => pathname.includes(this.buildPathName(it.name)))?.id ?? null;

		if (newTab) {
			this.setCurrentTab(newTab);
		}
	};

	public setCurrentTab = (value: number) => {
		this._currentTab = value;
	};

	public isActiveTab = (tab: number) => {
		return isEqual(this._currentTab, tab);
	};

	public isContactUs = (tabId: number) => {
		const sections = this.findSectionsById(tabId);

		return sections.some((article) => article.title.toLowerCase().includes("contact us"));
	};

	public buildPathName = (name: string) => {
		return `/help/${snakeCase(name)}`;
	};

	async init({navigate, pathname}: IControllerProps) {
		await this._staticStore.fetchHelps();
		this._navigate = navigate;

		if (isEqual(pathname, HelpController.rootPath) && this.tabs.length > 0) {
			return navigate(this.buildPathName(this.tabs[0].name), {replace: true});
		}

		this.updatePathname(pathname);
	}

	dispose() {
		return;
	}
}
