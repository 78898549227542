import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, HELPS_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {HeaderController, IHeaderController} from "views/components/Header/header.controller";
import {
	FormRegistrationController,
	IFormRegistrationController,
} from "views/components/FormRegistration/formRegistration.controller";
import {
	FormUserUpdateController,
	IFormUserUpdateController,
} from "views/components/FormUserUpdate/formUserUpdate.controller";
import {IStaticStore, StaticStore} from "data/stores/static/static.store";
import {FormValidator, IFormValidator} from "data/utils/helpers/validators/FormValidator";
import {
	FormLoginController,
	IFormLoginController,
} from "views/components/FormLogin/formLogin.controller";
import {HOCMainController, IHOCMainController} from "views/components/HOCMain/HOCMain.controller";
import {IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {IModalController, ModalController} from "views/components/modals/modal.controller";
import {
	IModalTutorialController,
	ModalTutorialController,
} from "views/components/modals/ModalTutorial/modalTutorial.controller";
import {
	IModalForgotPasswordController,
	ModalForgotPasswordController,
} from "views/components/modals/ModalForgotPassword/modalForgotPassword.controller";
import {
	IModalResetPasswordController,
	ModalResetPasswordController,
} from "views/components/modals/ModalResetPassword/modalResetPassword.controller";
import {
	IResetPasswordPageController,
	ResetPasswordPageController,
} from "views/pages/reset_password/reset_password.page.controller";
import {IMenuStore, MenuStore} from "data/stores/menu/menu.store";
import {IMenuController, MenuController} from "data/stores/menu/menu.controller";
import {ContestStore, IContestStore} from "data/stores/contest/contest.store";
import {IPicksPageController, PicksPageController} from "views/pages/picks/picks_page.controller";
import {
	IRoundSelectorController,
	RoundSelectorController,
} from "views/components/RoundSelector/RoundSelector.controller";
import {
	HelpNavigationController,
	IHelpNavigationController,
} from "views/components/HelpNavigation/HelpNavigation.controller";
import {
	IPicksSliderController,
	PicksSliderController,
} from "views/components/PicksSlider/picksSlider.controller";
import {
	IPickCardController,
	PickCardController,
} from "views/components/PickCard/pickCard.controller";
import {AnswersStore, IAnswersStore} from "data/stores/answers/answers.store";
import {
	IPicksActionBarController,
	PicksActionBarController,
} from "views/components/PicksActionBar/picksActionBar.controller";
import {
	IScoreBarController,
	ScoreBarController,
} from "views/components/ScoreBar/scoreBar.controller";
import {AnswersApiProvider, IAnswersApiProvider} from "data/providers/api/answers.api.provider";
import {
	IRangeSliderController,
	RangeSliderController,
} from "views/components/RangeSlider/rangeSlider.controller";
import {
	DashboardController,
	IDashboardController,
} from "views/components/Dashboard/dashboard.controller";
import {IParlayController, ParlayController} from "views/components/Parlay/parlay.controller";
import {
	FormChangeUserPasswordController,
	IFormChangeUserPasswordController,
} from "views/components/FormChangeUserPassword/formChangeUserPassword.controller";
import {
	ISliderStepsController,
	SliderStepsController,
} from "views/components/PicksSlider/SliderSteps/sliderSteps.controller";
import {IParlayStore, ParlayStore} from "data/stores/parlay/parlay.store";
import {
	IModalParlayController,
	ModalParlayController,
} from "views/components/modals/ModalParlayTutorial/modalParlayTutorial.controller";
import {
	IModalPicksSavedController,
	ModalPicksSavedController,
} from "views/components/modals/ModalPicksSaved/modalPicksSaved.controller";
import {ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/leagues/my_leagues/my_leagues.controller";
import {ILeaguesApiProvider, LeaguesApiProvider} from "data/providers/api/leagues.api.provider";
import {
	IModalPicksSubmitController,
	ModalPicksSubmitController,
} from "views/components/modals/ModalPicksSubmit/modalPicksSubmit.controller";
import {
	IPicksSummaryController,
	PicksSummaryController,
} from "views/pages/picks/picks_summary/picksSummary.controller";
import {
	IModalLeaveContestController,
	ModalLeaveContestController,
} from "views/components/modals/ModalLeaveContest/modalLeaveContest.controller";
import {
	IParlaySummaryItemController,
	ParlaySummaryItemController,
} from "views/components/ParlaySummaryItem/parlaySummaryItem.controller";
import {
	ISummaryParlayController,
	SummaryParlayController,
} from "views/components/SummaryParlay/summaryParlay.controller";
import {
	DashboardContestItemController,
	IDashboardContestItemController,
} from "views/components/DashboardContestItem/dashboardContestItem.controller";
import {
	ILeagueItemController,
	LeagueItemController,
} from "views/components/LeagueItem/leagueItem.controller";
import {
	ILeaguesJoinController,
	LeaguesJoinController,
} from "views/pages/leagues/join/leagues_join.controller";
import {
	ILeagueJoinItemController,
	LeagueJoinItemController,
} from "views/components/LeagueJoinItem/leagueJoinItem.controller";
import {
	IJoinLeagueFiltersController,
	JoinLeagueFiltersController,
} from "views/components/JoinLeagueFilters/joinLeagueFilters.controller";
import {
	FormLeagueCreateController,
	IFormLeagueCreateController,
} from "views/components/FormLeagueCreate/formLeagueCreate.controller";
import {
	IViewLeagueOutletController,
	ViewLeagueOutletController,
} from "views/pages/leagues/view/viewLeague.controller";
import {
	ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/leagues/view/about/league_about.controller";
import {
	ILeaveLeagueController,
	LeaveLeagueController,
} from "views/components/LeaveLeague/leaveLeague.controller";
import {
	IModalLeaveLeagueController,
	ModalLeaveLeagueController,
} from "views/components/modals/ModalLeaveLeague/modalLeaveLeague.controller";
import {
	IInviteLeagueController,
	InviteLeagueController,
} from "views/pages/leagues/view/league_invites/league_invites.controller";
import {
	ILeagueUsersController,
	LeagueUsersController,
} from "views/components/LeagueUsers/leagueUsers.controller";
import {
	IModalRemoveLeagueUserController,
	ModalRemoveLeagueUserController,
} from "views/components/modals/ModalRemoveLeagueUser/ModalRemoveLeagueUser.controller";
import {DashboardStore, IDashboardStore} from "data/stores/dashboard/dashboard.store";
import {
	ISliderScreenController,
	SliderScreenController,
} from "views/components/modals/ModalTutorial/SliderScreen/sliderScreen.controller";
import {
	BonusCodesPageController,
	IBonusCodesPageController,
} from "views/pages/bonus_codes/bonus_codes.page.controller";
import {
	ContactUsController,
	IContactUsController,
} from "views/components/ContactUs/contactUs.controller";
import {
	ISquadImageController,
	SquadImageController,
} from "views/components/SquadImage/squadImage.controller";
import {
	IModalRegistrationController,
	ModalRegistrationController,
} from "views/components/modals/ModalRegistration/modalRegistration.controller";
import {
	IRegistrationPageController,
	RegistrationPageController,
} from "views/pages/registration/registration.controller";
import {
	IModalBeforePicksSaveController,
	ModalBeforePicksSaveController,
} from "views/components/modals/ModalBeforePicksSave/modalBeforePicksSave.controller";
import {ILoginPageController, LoginPageController} from "views/pages/login/login.controller";
import {
	IModalLoginController,
	ModalLoginController,
} from "views/components/modals/ModalLogin/modalLogin.controller";
import {
	ILeaderboardPageController,
	LeaderboardPageController,
} from "views/pages/leaderboard/leaderboard.controller";
import {ITitleStore, TitleStore} from "data/stores/title/title.store";
import {OverallLeaderboardController} from "views/components/Leaderboards/OverallLeaderboard/overallLeaderboard.controller";
import {IRankingsApiProvider, RankingsApiProvider} from "data/providers/api/rankings.api.provider";
import {IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {TeamLeaderboardController} from "views/components/Leaderboards/TeamLeaderboard/teamLeaderboard.controller";
import {ILeaderboardCommonController, ILeagueStandingsController} from "data/types/entities";
import {LeagueLadderController} from "views/pages/leagues/view/league_ladder/leagueLadder.controller";
import {INotificationService, NotificationService} from "data/services/notification.service";
import {
	INotificationController,
	NotificationController,
} from "views/components/Notification/notification.controller";
import {
	IPageTitleController,
	PageTitleController,
} from "views/components/PageTitle/pageTitle.controller";
import {
	IParlayCheckboxController,
	ParlayCheckboxController,
} from "views/components/Parlay/ParlayCheckbox/parlayCheckbox.controller";
import {ParlayItemController} from "views/components/ParlayItem/parlayItem.controller";
import {
	ILeagueStandingsRowController,
	LeagueStandingsRowController,
} from "views/components/LeagueStandingsRow/leagueStandingsRow.controller";
import {
	IRankingsPicksController,
	RankingsPicksController,
} from "views/components/RankingsPicks/rankingsPicks.controller";
import {
	AdvertisementController,
	IAdvertisementController,
} from "views/components/Advertisement/advertisement.controller";
import {HelpController, IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	IHelpListController,
} from "views/components/Help/help_list/help_list.controller";
import {
	IPicksReviewController,
	PicksReviewController,
} from "views/components/PicksReview/picksReview.controller";
import {ILiveScoringStore, LiveScoringStore} from "data/stores/liveScoring/livescoring.store";
import {
	HOCSecretKeyController,
	IHOCSecretKeyController,
} from "views/components/HOCSecretKey/HOCSecretKey.controller";
import {
	IShareControllerController,
	ShareControllerController,
} from "views/components/Share/share.controller";
import {
	FootballTooltipController,
	IFootballTooltipController,
} from "views/components/FootballTooltip/footballTooltip.controller";
import {IParlayPrefillService, ParlayPrefillService} from "data/services/parlayPrefill.service";
import {
	IInvitePageController,
	InvitePageController,
} from "views/pages/invite/invite_page.controller";
import {
	IModalNoPicksPlacedController,
	ModalNoPicksPlacedController,
} from "views/components/modals/ModalNoPicksPlaced/modalNoPicksPlaced.controller";
import {
	IProfileLinkBlockController,
	ProfileLinkBlockController,
} from "views/components/ProfileLinkBlock/profileLinkBlock.controller";
import {
	IModalSeasonEndController,
	ModalSeasonEndController,
} from "views/components/modals/ModalSeasonEnd/modalSeasonEnd.controller";
import {ISettingsStore, SettingsStore} from "data/stores/settings/settings.store";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHelpClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: HELPS_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IAnswersApiProvider>(Bindings.AnswersApiProvider).to(AnswersApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IRankingsApiProvider>(Bindings.RankingsApiProvider).to(RankingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore).inSingletonScope();
	bind<IStaticStore>(Bindings.StaticStore).to(StaticStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IMenuStore>(Bindings.MenuStore).to(MenuStore).inSingletonScope();
	bind<IContestStore>(Bindings.ContestStore).to(ContestStore).inSingletonScope();
	bind<IAnswersStore>(Bindings.AnswersStore).to(AnswersStore).inSingletonScope();
	bind<IParlayStore>(Bindings.ParlayStore).to(ParlayStore).inSingletonScope();
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IDashboardStore>(Bindings.DashboardStore).to(DashboardStore).inSingletonScope();
	bind<ITitleStore>(Bindings.TitleStore).to(TitleStore).inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<ILiveScoringStore>(Bindings.LiveScoringStore).to(LiveScoringStore).inSingletonScope();
	bind<ISettingsStore>(Bindings.SettingsStore).to(SettingsStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IFormLoginController>(Bindings.FormLoginController).to(FormLoginController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);

	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<IFormRegistrationController>(Bindings.FormRegistrationController).to(
		FormRegistrationController
	);
	bind<IFormUserUpdateController>(Bindings.FormUserUpdateController).to(FormUserUpdateController);
	bind<IFormChangeUserPasswordController>(Bindings.FormChangeUserPasswordController).to(
		FormChangeUserPasswordController
	);
	bind<IHOCMainController>(Bindings.HOCMainController).to(HOCMainController);
	bind<IModalController>(Bindings.ModalController).to(ModalController);
	bind<IModalTutorialController>(Bindings.ModalTutorialController).to(ModalTutorialController);
	bind<IModalForgotPasswordController>(Bindings.ModalForgotPasswordController).to(
		ModalForgotPasswordController
	);
	bind<IModalResetPasswordController>(Bindings.ModalResetPasswordController).to(
		ModalResetPasswordController
	);
	bind<IResetPasswordPageController>(Bindings.ResetPasswordPageController).to(
		ResetPasswordPageController
	);
	bind<IPicksPageController>(Bindings.PicksPageController).to(PicksPageController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IPicksSummaryController>(Bindings.PicksSummaryController).to(PicksSummaryController);

	bind<IMenuController>(Bindings.MenuController).to(MenuController);
	bind<IRoundSelectorController>(Bindings.RoundSelectorController).to(RoundSelectorController);
	bind<IHelpNavigationController>(Bindings.HelpNavigationController).to(HelpNavigationController);
	bind<IPicksSliderController>(Bindings.PicksSliderController).to(PicksSliderController);
	bind<IPickCardController>(Bindings.PickCardController).to(PickCardController);
	bind<IPicksActionBarController>(Bindings.PicksActionBarController).to(PicksActionBarController);
	bind<IScoreBarController>(Bindings.ScoreBarController).to(ScoreBarController);
	bind<IRangeSliderController>(Bindings.RangeSliderController).to(RangeSliderController);
	bind<IDashboardController>(Bindings.DashboardController).to(DashboardController);
	bind<IParlayController>(Bindings.ParlayController).to(ParlayController);
	bind<ISliderStepsController>(Bindings.SliderStepsController).to(SliderStepsController);
	bind<IModalParlayController>(Bindings.ModalParlayController).to(ModalParlayController);
	bind<IModalPicksSavedController>(Bindings.ModalPicksSavedController).to(
		ModalPicksSavedController
	);
	bind<IModalPicksSubmitController>(Bindings.ModalPicksSubmitController).to(
		ModalPicksSubmitController
	);
	bind<IModalLeaveContestController>(Bindings.ModalLeaveContestController).to(
		ModalLeaveContestController
	);
	bind<IParlaySummaryItemController>(Bindings.ParlaySummaryItemController).to(
		ParlaySummaryItemController
	);
	bind<IParlaySummaryItemController>(Bindings.ParlayItemController).to(ParlayItemController);
	bind<ISummaryParlayController>(Bindings.SummaryParlayController).to(SummaryParlayController);
	bind<IDashboardContestItemController>(Bindings.DashboardContestItemController).to(
		DashboardContestItemController
	);
	bind<ILeagueItemController>(Bindings.LeagueItemController).to(LeagueItemController);
	bind<ILeaguesJoinController>(Bindings.LeaguesJoinController).to(LeaguesJoinController);
	bind<ILeagueJoinItemController>(Bindings.LeagueJoinItemController).to(LeagueJoinItemController);
	bind<IJoinLeagueFiltersController>(Bindings.JoinLeagueFiltersController).to(
		JoinLeagueFiltersController
	);
	bind<IFormLeagueCreateController>(Bindings.FormLeagueCreateController).to(
		FormLeagueCreateController
	);
	bind<IViewLeagueOutletController>(Bindings.ViewLeagueOutletController).to(
		ViewLeagueOutletController
	);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILoginPageController>(Bindings.LoginPageController).to(LoginPageController);
	bind<ILeaveLeagueController>(Bindings.LeaveLeagueController).to(LeaveLeagueController);
	bind<IModalLeaveLeagueController>(Bindings.ModalLeaveLeagueController).to(
		ModalLeaveLeagueController
	);
	bind<IInviteLeagueController>(Bindings.InviteLeagueController).to(InviteLeagueController);
	bind<ILeagueUsersController>(Bindings.LeagueUsersController).to(LeagueUsersController);
	bind<IModalRemoveLeagueUserController>(Bindings.ModalRemoveLeagueUserController).to(
		ModalRemoveLeagueUserController
	);
	bind<ISliderScreenController>(Bindings.SliderScreenController).to(SliderScreenController);
	bind<IBonusCodesPageController>(Bindings.BonusCodesPageController).to(BonusCodesPageController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ISquadImageController>(Bindings.SquadImageController).to(SquadImageController);
	bind<IModalRegistrationController>(Bindings.ModalRegistrationController).to(
		ModalRegistrationController
	);
	bind<IRegistrationPageController>(Bindings.RegistrationPageController).to(
		RegistrationPageController
	);
	bind<IModalBeforePicksSaveController>(Bindings.ModalBeforePicksSaveController).to(
		ModalBeforePicksSaveController
	);
	bind<IModalLoginController>(Bindings.ModalLoginController).to(ModalLoginController);
	bind<ILeaderboardCommonController>(Bindings.OverallLeaderboardController).to(
		OverallLeaderboardController
	);
	bind<ILeaderboardCommonController>(Bindings.TeamLeaderboardController).to(
		TeamLeaderboardController
	);
	bind<ILeaderboardPageController>(Bindings.LeaderboardPageController).to(
		LeaderboardPageController
	);
	bind<ILeagueStandingsController>(Bindings.LeagueLadderController).to(LeagueLadderController);
	bind<INotificationController>(Bindings.NotificationController).to(NotificationController);
	bind<IPageTitleController>(Bindings.PageTitleController).to(PageTitleController);
	bind<IParlayCheckboxController>(Bindings.ParlayCheckboxController).to(ParlayCheckboxController);
	bind<ILeagueStandingsRowController>(Bindings.LeagueStandingsRowController).to(
		LeagueStandingsRowController
	);
	bind<IRankingsPicksController>(Bindings.RankingsPicksController).to(RankingsPicksController);
	bind<IAdvertisementController>(Bindings.AdvertisementController).to(AdvertisementController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IPicksReviewController>(Bindings.PicksReviewController).to(PicksReviewController);
	bind<IHOCSecretKeyController>(Bindings.HOCSecretKeyController).to(HOCSecretKeyController);
	bind<IInvitePageController>(Bindings.InvitePageController).to(InvitePageController);
	bind<IShareControllerController>(Bindings.ShareControllerController).to(
		ShareControllerController
	);
	bind<IFootballTooltipController>(Bindings.FootballTooltipController).to(
		FootballTooltipController
	);
	bind<IModalNoPicksPlacedController>(Bindings.ModalNoPicksPlacedController).to(
		ModalNoPicksPlacedController
	);
	bind<IProfileLinkBlockController>(Bindings.ProfileLinkBlockController).to(
		ProfileLinkBlockController
	);
	bind<IModalSeasonEndController>(Bindings.ModalSeasonEndController).to(ModalSeasonEndController);

	// Helpers
	bind<IFormValidator>(Bindings.FormValidator).to(FormValidator);
	bind<INotificationService>(Bindings.NotificationService)
		.to(NotificationService)
		.inSingletonScope();
	bind<IParlayPrefillService>(Bindings.ParlayPrefillService)
		.to(ParlayPrefillService)
		.inSingletonScope();
});
