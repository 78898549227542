import {inject, injectable} from "inversify";
import type {IApiAxiosResponse, IHttpClientService} from "data/services/http";
import {IOverallRankingsParams, IOverallRankingsResponse} from "data/types/api";
import {Bindings} from "data/constants/bindings";
import {AbstractApiProvider} from "data/providers/api/abstract.api.provider";

export interface IRankingsApiProvider {
	fetchOverallRankings(
		params: IOverallRankingsParams
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>>;

	fetchLeagueRankings(
		leagueId: number,
		params: IOverallRankingsParams
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>>;

	fetchTeamRankings(
		params: IOverallRankingsParams
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>>;
}

@injectable()
export class RankingsApiProvider extends AbstractApiProvider implements IRankingsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {
		super();
	}

	public fetchOverallRankings(
		params: IOverallRankingsParams
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>> {
		return this._http.get(`${this.language}/ranking/overall`, params);
	}

	public fetchLeagueRankings(
		leagueId: number,
		params: IOverallRankingsParams
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>> {
		return this._http.get(`${this.language}/ranking/league/${leagueId}`, params);
	}

	fetchTeamRankings(
		params: IOverallRankingsParams
	): Promise<IApiAxiosResponse<IOverallRankingsResponse>> {
		return this._http.get(`${this.language}/ranking/team`, params);
	}
}
