import {NavLink} from "react-router-dom";
import styled from "@emotion/styled";

export const PrimaryLink = styled(NavLink)`
	background: ${({theme}) => theme.button.primary.default.background};
	color: ${({theme}) => theme.button.primary.default.color};
	border: 1px solid ${({theme}) => theme.button.primary.default.background};
	padding: 14px 22px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	&:hover,
	&:active,
	&:focus &.active {
		background: ${({theme}) => theme.button.primary.hover.background};
		color: ${({theme}) => theme.button.primary.hover.color};
		border-color: ${({theme}) => theme.button.primary.hover.background};
	}

	&:disabled {
		background: ${({theme}) => theme.button.primary.disabled.background};
		color: ${({theme}) => theme.button.primary.disabled.color};
		border-color: ${({theme}) => theme.button.primary.disabled.background};
		opacity: ${({theme}) => theme.button.primary.disabled.opacity};
	}
`;
