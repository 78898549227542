import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {Empty} from "data/types/generics";
import {FootballsTooltipUtils} from "data/utils/FootballsTooltip.utils";

interface IParams {
	slideCount: number;
}

export interface IFootballTooltipController extends ViewController<IParams> {
	popoverClose: () => void;

	updateSlideCount: (slideCount: number) => void;

	get isTooltipVisible(): boolean;
}

@injectable()
export class FootballTooltipController implements IFootballTooltipController {
	@observable private _slideCount: Empty<number>;

	constructor(@inject(Bindings.ContestStore) private _contestStore: IContestStore) {
		makeAutoObservable(this);
	}

	get isTooltipVisible(): boolean {
		if (
			this._slideCount !== this._contestStore.currentSliderStep ||
			this._slideCount === undefined
		) {
			return false;
		}

		return !FootballsTooltipUtils.getIsTooltipViewed();
	}

	@action
	popoverClose = (): void => {
		FootballsTooltipUtils.setTooltipViewed();
		runInAction(() => {
			this._slideCount = undefined;
		});
	};

	dispose(): void {
		return;
	}

	// Wait for slider init
	@action
	init(param: IParams): void {
		setTimeout(() => {
			runInAction(() => {
				this._slideCount = param.slideCount;
			});
		}, 1000);
	}

	// Wait for slider init
	@action
	updateSlideCount = (slideCount: number) => {
		setTimeout(() => {
			runInAction(() => {
				this._slideCount = slideCount;
			});
		}, 1000);
	};
}
