import styled from "@emotion/styled";
import {PrimaryButton, PureButton} from "views/components/common";

export const ModalContent = styled.div`
	position: fixed;
	left: 20px;
	right: 20px;
	top: 80px;
	max-width: calc(420px - 40px);
	// Disabled as incorrect behaviour on mobile
	//width: 100%;
	margin: 0 auto;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background: #ffffff;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 68px 20px 20px 20px;
	gap: 12px;
	outline: none;

	&.full-screen {
		max-width: 420px;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&.iframe-content {
		left: 20px;
		right: 20px;
		top: 80px;
		bottom: 20px;
		padding-top: 52px;
	}
`;

export const ModalCloseButton = styled(PureButton)`
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 24px;
	height: 24px;
	padding: 0;
`;

export const ModalTitle = styled.h3`
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 118%;
	text-align: center;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: ${({theme}) => theme.text.color.black};
	margin: 10px 0;
`;

export const ModalText = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	color: ${({theme}) => theme.text.color.black};
	text-align: center;
	letter-spacing: 0.01em;
`;

export const ModalButton = styled(PrimaryButton)`
	max-width: 420px;
	width: 100%;
	margin-top: 12px;
`;
