import {ShareType, SocialNetwork} from "data/enums";
import {IShare} from "data/types/entities";
import {identity, set} from "lodash";
import {SHARE_URL} from "data/constants";
import i18n from "i18next";

export abstract class ShareUtility {
	private static callbacks = {
		[SocialNetwork.TWITTER]: (data: IShare) => ShareUtility.shareTW(data),
		[SocialNetwork.FACEBOOK]: (data: IShare) => ShareUtility.shareFB(data),
		[SocialNetwork.MOBILE]: (data: IShare) => ShareUtility.shareMobile(data),
	} as const;

	public static share(data: IShare): boolean | void | Window | null {
		const callback = ShareUtility.callbacks[data.socialNetwork];

		if (!callback || typeof callback !== "function") {
			throw new Error("Invalid share type");
		}

		return callback(data);
	}

	private static shareTW({message = "", ...params}: IShare): WindowProxy | null {
		return window.open(
			"https://twitter.com/share?url=" +
				encodeURIComponent(ShareUtility.getLinkForShare(params)) +
				"&text=" +
				encodeURIComponent(message),
			"twitter-share-dialog",
			"width=626,height=436"
		);
	}

	private static shareFB(data: IShare): void {
		if (!window.FB) {
			throw new Error("No Facebook share provider found");
		}
		return window.FB?.ui(
			{
				method: "share",
				display: "popup",
				href: ShareUtility.getLinkForShare(data),
			},
			identity
		);
	}

	private static shareMobile({message = "", ...params}: IShare) {
		try {
			void navigator.share({
				url: ShareUtility.getLinkForShare(params),
				text: message,
			});
		} catch (err) {
			const error = err as Error;
			throw new Error(error.message);
		}
		return true;
	}

	private static getLinkForShare(data: IShare): string {
		const params = {
			t: this.getTimestamp(),
		};

		if (data.type === ShareType.League) {
			set(params, "leagueId", data.leagueId);
		}

		const language = i18n.language || "en";

		return `${SHARE_URL}${language}/${data.type}/${ShareUtility.getEncodedJson(params)}`;
	}

	private static getTimestamp(): string {
		return Date.now().toString().substring(9);
	}

	private static getEncodedJson(object: Partial<IShare> & {t: string}): string {
		return window.btoa(JSON.stringify(object));
	}
}
