import {ViewController} from "data/types/structure";
import type {ILoginPayload} from "data/types/api";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import React from "react";
import {ModalType, RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {noop} from "lodash";
import {AxiosError} from "axios";

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

export interface IFormLoginController extends ViewController {
	handleLoginForm: (event: React.SyntheticEvent<ILoginForm>) => void;
	login: (params: ILoginPayload) => Promise<void>;
	resetFormErrors: () => void;
	togglePasswordVisibility: () => void;
	openForgotPasswordModal: () => void;
	goToRegister: () => void;
	error?: string;

	get isPasswordVisible(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class FormLoginController implements IFormLoginController {
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable _error?: string = undefined;

	get error() {
		return this._error;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	// private get isParlayLocked(): boolean {
	// 	return this.votedProps.some((e) => e.status !== PropStatus.Open);
	// }

	@action resetFormErrors = () => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
	};

	@action login({email, password}: ILoginPayload) {
		this._requestState = RequestState.PENDING;
		return this._userStore
			.login({email, password})
			.catch((e: AxiosError<{errors: {message: string}[]}>) => {
				this.onLoginError(e);
				return Promise.reject(e);
			});
	}

	@action handleLoginForm = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		this.login({
			email: email.value,
			password: password.value,
		})
			.then(() => {
				this.checkIsSaveRequired();
			})
			.catch(noop);
	};

	public openForgotPasswordModal = () => {
		this._modalsStore.showModal(ModalType.FORGOT_PASSWORD);
	};

	public goToRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	@action
	public togglePasswordVisibility = () => {
		runInAction(() => {
			this._isPasswordVisible = !this._isPasswordVisible;
		});
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action private onLoginError = (error: AxiosError<{errors: {message: string}[]}>) => {
		this._error = error.response?.data?.errors[0]?.message || error.message;
		this._requestState = RequestState.ERROR;
	};

	private checkIsSaveRequired(): void {
		if (this._answersStore.answersChanged && this._contestStore.selectedContest) {
			void this._answersStore.saveAnswers(this._contestStore.selectedContest?.id);
		}
		if (this._answersStore.parlayIds.length >= 2) {
			void this._answersStore.saveParlayForContest(this._contestStore.selectedContest?.id);
		}
		this._modalsStore.hideModal();
	}
}
