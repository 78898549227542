import {injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";

export interface IParlayStore {
	get isParlayTutorialViewed(): boolean;

	get isParlayTutorialOpen(): boolean;

	openParlayTutorial(): void;

	closeParlayTutorial(): void;
}

@injectable()
export class ParlayStore implements IParlayStore {
	private readonly _tutorialKey: string = "xfl_parlay_viewed";
	constructor() {
		makeAutoObservable(this);
	}

	@observable private _isParlayTutorialOpen: boolean = false;

	get isParlayTutorialOpen(): boolean {
		return this._isParlayTutorialOpen;
	}

	get isParlayTutorialViewed(): boolean {
		return this.getIsTutorialViewedFromStorage();
	}

	@action
	public closeParlayTutorial(): void {
		this._isParlayTutorialOpen = false;
		this.setTutorialViewedToStorage();
	}

	@action
	public openParlayTutorial(): void {
		this._isParlayTutorialOpen = true;
	}

	private setTutorialViewedToStorage(): void {
		localStorage.setItem(this._tutorialKey, JSON.stringify(true));
	}

	private getIsTutorialViewedFromStorage(): boolean {
		return JSON.parse(localStorage.getItem(this._tutorialKey) || "false") as boolean;
	}
}
