import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ITitleStore} from "data/stores/title/title.store";

export interface IPageTitleController extends ViewController {
	get title(): string;
}

@injectable()
export class PageTitleController implements IPageTitleController {
	constructor(@inject(Bindings.TitleStore) private _titleStore: ITitleStore) {
		makeAutoObservable(this);
	}

	get title(): string {
		return this._titleStore.title;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
