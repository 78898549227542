import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Empty} from "data/types/generics";
import {IServerAnswer} from "data/types/api";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {IProp} from "data/types/entities";
import {PropStatus} from "data/enums";
import {formatPropTime} from "data/utils/helpers";

interface IParams {
	propId: number;
}

export interface IParlayCheckboxController extends ViewController<IParams> {
	get prop(): Empty<IProp>;

	get isPropInParlay(): boolean;

	get propAnswer(): Empty<IServerAnswer>;

	get propIndex(): number;

	get propsLength(): number;

	get isLocked(): boolean;

	get isComplete(): boolean;

	get isCanceled(): boolean;

	get propLockDate(): string;
}

@injectable()
export class ParlayCheckboxController implements IParlayCheckboxController {
	@observable private _propId: number = 0;

	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get propAnswer(): Empty<IServerAnswer> {
		return this.answers.find((e) => e.questionId === this._propId);
	}

	get propsLength(): number {
		return this._contestStore.selectedContest?.props.length || 1;
	}

	get propIndex(): number {
		if (!this._contestStore.selectedContest) {
			return 0;
		}
		return (
			this._contestStore.selectedContest?.props.findIndex((e) => e.id === this._propId) + 1
		);
	}

	get isPropInParlay(): boolean {
		return this._answersStore.parlayIds.includes(this._propId);
	}

	get prop(): Empty<IProp> {
		return this._contestStore.getPropById(this._propId);
	}

	get isLocked(): boolean {
		return this.prop?.status === PropStatus.Locked;
	}

	get isComplete(): boolean {
		return this.prop?.status === PropStatus.Resulted;
	}

	get isCanceled(): boolean {
		return this.prop?.status === PropStatus.Canceled;
	}

	get propLockDate(): string {
		return formatPropTime(this.prop);
	}

	private get answers(): IServerAnswer[] {
		return this._answersStore.answers;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._propId = param.propId;
	}
}
