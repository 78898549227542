import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import React from "react";
import {ILeaguesFilters} from "data/types/api";

export interface IJoinLeagueFiltersController extends ViewController {
	onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	get filters(): ILeaguesFilters;

	setCode: (code: string) => void;
}

@injectable()
export class JoinLeagueFiltersController implements IJoinLeagueFiltersController {
	constructor(@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get filters(): ILeaguesFilters {
		return this._leaguesStore.leagueFilters;
	}

	public onFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}
		this.updateFilters(name, value);
	};

	public setCode = (code: string) => {
		this.updateFilters("search", code);
	};

	private updateFilters = (name: string, value: string) => {
		void this._leaguesStore.updateFilters({...this.filters, [name]: value});
	};
}
