import React, {useCallback} from "react";
import styled from "@emotion/styled";
import {range} from "lodash";
import {PureButton} from "views/components/common";
import {IconArrow} from "views/components/Icons/IconArrow";
import {observer} from "mobx-react";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding-bottom: 20px;
`;

const InnerContainer = styled(Wrapper)`
	position: relative;
	padding-bottom: 0;
`;

const Step = styled(PureButton)`
	width: 16px;
	height: 16px;
	background: ${({theme}) => theme.brandColor.grey};
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	&:active,
	&:focus,
	&:hover {
		background: ${({theme}) => theme.brandColor.black};
	}

	&:disabled {
		opacity: 0.4;
	}

	&.active {
		width: calc(16px * 2 + 8px);
		border-radius: 18px;
	}
`;

const ArrowButton = styled(PureButton)`
	padding: 0;

	&:disabled {
		path {
			fill: #b4b4b4;
			color: #b4b4b4;
		}
	}
`;

interface IProps {
	changeCurrentIndex: (index: number) => void;
	currentIndex: number;
	stepsCount: number;
	moveToNextStep: () => void;
}

export const SliderSteps: React.FC<IProps> = observer(
	({changeCurrentIndex, currentIndex, stepsCount, moveToNextStep}) => {
		const isPrevDisabled = currentIndex <= 0;

		const goPrev = useCallback(() => {
			changeCurrentIndex(currentIndex - 1);
		}, [currentIndex, changeCurrentIndex]);

		const goNext = useCallback(() => {
			if (currentIndex === stepsCount) {
				moveToNextStep();
			}
			changeCurrentIndex(currentIndex + 1);
		}, [changeCurrentIndex, currentIndex, moveToNextStep, stepsCount]);

		const onStepChange = useCallback(
			(event: React.SyntheticEvent<HTMLButtonElement>) => {
				const index = Number(event.currentTarget.dataset.index);
				changeCurrentIndex(index);
			},
			[changeCurrentIndex]
		);

		return (
			<Wrapper>
				<ArrowButton disabled={isPrevDisabled} onClick={goPrev}>
					<IconArrow direction="left" />
				</ArrowButton>
				<InnerContainer>
					{range(stepsCount).map((index) => (
						<Step
							data-index={index}
							key={index}
							onClick={onStepChange}
							className={index === currentIndex ? "active" : ""}
						/>
					))}
					<Step onClick={moveToNextStep} disabled={currentIndex !== --stepsCount} />
				</InnerContainer>
				<ArrowButton onClick={goNext}>
					<IconArrow direction="right" />
				</ArrowButton>
			</Wrapper>
		);
	}
);
