export abstract class FootballsTooltipUtils {
	private static _storageKey = "xfl_football_viewed";

	public static getIsTooltipViewed(): boolean {
		return JSON.parse(localStorage.getItem(this._storageKey) || "false") as boolean;
	}

	public static setTooltipViewed(): void {
		localStorage.setItem(this._storageKey, JSON.stringify(true));
	}
}
