import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {IDashboardResponse} from "data/types/entities";
import type {Empty} from "data/types/generics";
import {AxiosError} from "axios/index";
import {IAxiosApiError} from "data/types/api";
import {Bindings} from "data/constants/bindings";
import type {IAnswersApiProvider} from "data/providers/api/answers.api.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IDashboardStore {
	get dashboardInfo(): Empty<IDashboardResponse>;

	fetchDashboard(): Promise<void>;
}

@injectable()
export class DashboardStore implements IDashboardStore {
	constructor(
		@inject(Bindings.AnswersApiProvider) private _answersProvider: IAnswersApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _dashboardInfo: Empty<IDashboardResponse>;

	get dashboardInfo(): Empty<IDashboardResponse> {
		return this._dashboardInfo;
	}

	public async fetchDashboard(): Promise<void> {
		try {
			const response = await this._answersProvider.fetchDashboard();
			this._dashboardInfo = response.data.success;
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		}
	}

	protected onError(e: unknown) {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalsStore.showAxiosError(error);
	}
}
