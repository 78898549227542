import React, {useCallback} from "react";
import {ModalButton, ModalText, ModalTitle} from "views/components/modals/common";
import WelcomeImage from "assets/img/tutorial/welcome.png";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {TutorialStep} from "data/enums";
import styled from "@emotion/styled";

const TopBlock = styled.div`
	height: 240px;
	background: ${({theme}) => theme.brandColor.black};
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: 100%;
	}
`;

const Content = styled.div`
	padding: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	max-width: 335px;

	button {
		width: auto;
	}
`;

interface IProps {
	changeStep: (step: TutorialStep) => void;
}

export const WelcomeScreen: React.FC<IProps> = observer(({changeStep}) => {
	const {t} = useTranslation();

	const changeStepHandler = useCallback(() => {
		if (changeStep && typeof changeStep === "function") {
			changeStep(TutorialStep.Slider);
		}
	}, [changeStep]);

	return (
		<React.Fragment>
			<TopBlock>
				<img src={WelcomeImage} alt="welcome" />
			</TopBlock>
			<Content>
				<ModalTitle>{t("tutorial.welcome.title")}</ModalTitle>

				<ModalText>{t("tutorial.welcome.description")}</ModalText>

				<ModalButton onClick={changeStepHandler}>
					{t("tutorial.welcome.button")}
				</ModalButton>
			</Content>
		</React.Fragment>
	);
});
