import React from "react";
import {useViewController} from "data/services/locator";
import {IModalController} from "views/components/modals/modal.controller";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import {IModalMessage} from "data/types/modals";
import {Modal} from "@mui/material";
import {
	ModalButton,
	ModalCloseButton,
	ModalContent,
	ModalText,
	ModalTitle,
} from "views/components/modals/common";
import {Exist} from "views/components/Exist";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {ReactComponent as IconSuccess} from "assets/img/icons/success_rounded.svg";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";

export const ModalSuccess: React.FC = observer(() => {
	const {t} = useTranslation();
	const {isModalOpen, close, modalData} = useViewController<IModalController>(
		Bindings.ModalController
	);
	const isOpen = isModalOpen(ModalType.SUCCESS);

	const data = modalData as IModalMessage;
	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<ModalCloseButton onClick={close}>
					<IconClose />
				</ModalCloseButton>
				<IconSuccess />

				<Exist when={Boolean(data?.title)}>
					<ModalTitle>{t(data?.title ?? "")}</ModalTitle>
				</Exist>
				<ModalText>{t(data?.message) || "-"}</ModalText>

				<ModalButton fullWidth onClick={close}>
					{t(data?.buttonText || "modal.general.close")}
				</ModalButton>
			</ModalContent>
		</Modal>
	);
});
