import React from "react";
import {observer} from "mobx-react";
import {
	ModalButton,
	ModalCloseButton,
	ModalContent,
	ModalText,
	ModalTitle,
} from "views/components/modals/common";
import {Modal} from "@mui/material";
import WelcomeImage from "assets/img/tutorial/welcome.png";
import styled from "@emotion/styled";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {useTranslation} from "react-i18next";
import {useViewController} from "data/services/locator";
import {IModalSeasonEndController} from "views/components/modals/ModalSeasonEnd/modalSeasonEnd.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/Exist";
import Logo from "assets/img/icons/header-logo.png";

const TopBlock = styled.div`
	height: 240px;
	background: ${({theme}) => theme.brandColor.black};
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		height: 100%;
	}
`;

const ModalContentStyled = styled(ModalContent)`
	overflow: hidden;
	max-width: 335px;
	padding: 0;

	&.full-screen {
		img {
			max-width: 300px;
			padding-top: 120px;
		}
	}
`;

const Content = styled.div`
	padding: 20px;
	background: ${({theme}) => theme.brandColor.white};

	p {
		margin-bottom: 20px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const SModalCloseButton = styled(ModalCloseButton)`
	position: absolute;
	right: 12px;
	top: 12px;
	width: 40px;
	height: 40px;
	background: #333333;
	z-index: 3;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	svg {
		width: 24px;
		height: 24px;

		path {
			fill: ${({theme}) => theme.text.color.white};
		}
	}
`;

export const ModalSeasonEnd: React.FC = observer(() => {
	const {isOpen, isLoading, goToBingo, close, isCloseAvailable} =
		useViewController<IModalSeasonEndController>(Bindings.ModalSeasonEndController);
	const {t} = useTranslation();
	const modalClass = isCloseAvailable ? "" : "full-screen";

	return (
		<Modal open={isOpen}>
			<ModalContentStyled className={modalClass}>
				<Exist when={isCloseAvailable}>
					<TopBlock>
						<img src={WelcomeImage} alt="welcome" />
					</TopBlock>
				</Exist>
				<Exist when={!isCloseAvailable}>
					<img src={Logo} alt="logo" />
				</Exist>

				<Exist when={isCloseAvailable}>
					<SModalCloseButton disabled={isLoading} onClick={close}>
						<IconClose />
					</SModalCloseButton>
				</Exist>

				<Content>
					<ModalTitle>{t("modal.season_end.title")}</ModalTitle>

					<ModalText>{t("modal.season_end.text_1")}</ModalText>

					<Exist when={isCloseAvailable}>
						<ModalButton disabled={isLoading} onClick={goToBingo}>
							{t("modal.season_end.close")}
						</ModalButton>
					</Exist>
				</Content>
			</ModalContentStyled>
		</Modal>
	);
});
