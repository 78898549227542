import React from "react";
import styled from "@emotion/styled";
import {IDirectionIconProps} from "data/types/icons";

const SVG = styled.svg`
	&.right {
		transform: rotate(0);
	}

	&.bottom {
		transform: rotate(90deg);
	}

	&.left {
		transform: rotate(180deg);
	}

	&.top {
		transform: rotate(270deg);
	}
`;

export const IconArrow: React.FC<IDirectionIconProps> = ({
	width = 25,
	height = 24,
	color = "#121212",
	direction = "bottom",
}) => {
	return (
		<SVG
			className={direction}
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 25 24"
			fill="none">
			<path
				d="M7.87999 21.01C8.36999 21.5 9.15999 21.5 9.64999 21.01L17.96 12.7C18.35 12.31 18.35 11.68 17.96 11.29L9.64999 2.98005C9.15999 2.49005 8.36999 2.49005 7.87999 2.98005C7.38999 3.47005 7.38999 4.26005 7.87999 4.75005L15.12 12L7.86999 19.25C7.38999 19.73 7.38999 20.5301 7.87999 21.01Z"
				fill={color}
			/>
		</SVG>
	);
};
