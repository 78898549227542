import React from "react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {IHeaderController} from "views/components/Header/header.controller";
import {Bindings} from "data/constants/bindings";
import {MenuButton} from "views/components/MenuButton/menuButton.component";
import {observer} from "mobx-react";
import {Exist} from "views/components/Exist";

import headerLogo from "assets/img/icons/header-logo.png";
import {ReactComponent as LogoutIcon} from "assets/img/icons/logout.svg";
import {NavLink} from "react-router-dom";

const HeaderElement = styled.header`
	width: 100%;
	height: 56px;
	background: ${({theme}) => theme.brandColor.white};

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	border-bottom: 2px solid ${({theme}) => theme.brandColor.black};
`;

const LeftBlock = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	padding-left: 8px;
`;

const Logo = styled.img`
	width: auto;
	max-height: 56px;
`;

export const Header: React.FC = observer(() => {
	const {isMenuOpen, toggleMenu, isLogged} = useViewController<IHeaderController>(
		Bindings.HeaderController
	);

	return (
		<HeaderElement>
			<LeftBlock>
				<MenuButton isOpen={isMenuOpen} onClick={toggleMenu} />
				<Logo src={headerLogo} alt="XFL Pickem" />
			</LeftBlock>

			<Exist when={!isLogged}>
				<NavLink to="/login">
					<LogoutIcon />
				</NavLink>
			</Exist>
		</HeaderElement>
	);
});
