import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {
	ILeague,
	ILeagueStandingsController,
	IRankingsRow,
	IRankingsUser,
} from "data/types/entities";
import React from "react";
import {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IStaticStore} from "data/stores/static/static.store";
import {ContestStatus} from "data/enums";

@injectable()
export class LeagueLadderController implements ILeagueStandingsController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leagueStore: ILeaguesStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _selectedContestWeek: number = 0;

	get selectedContestWeek(): number {
		return this._selectedContestWeek;
	}

	get contestsList() {
		return this._contestStore.contests
			.filter((e) => {
				const byIds = e.id >= (this.league?.startContest || 0);
				const byStatus = [ContestStatus.Complete].includes(e.status);
				return byIds && byStatus;
			})
			.map((e) => e.id);
	}

	get isOverallSelect(): boolean {
		return !this.selectedContestWeek;
	}

	get nextPage(): boolean {
		return this._leagueStore.standingsNextPage;
	}

	get rankings(): IRankingsRow[] {
		return this._leagueStore.standings.rankings;
	}

	get userRow(): Empty<IRankingsUser> {
		return this._leagueStore.standings.user;
	}

	get isCurrentUserInList(): boolean {
		return this.rankings.some((e) => e.userId === this._userStore.user?.id);
	}

	get isPicksCanBeViewed(): boolean {
		const contest = this._contestStore.getContestById(this.selectedContestWeek);
		if (!contest) {
			return false;
		}
		return contest.status === ContestStatus.Complete;
	}

	get showNoData() {
		if (this.rankings.length === 0 && !this.isLoading) {
			return true;
		}
		const contest = this._contestStore.getContestById(this.selectedContestWeek);
		if (!contest) {
			return false;
		}

		return [ContestStatus.Open, ContestStatus.Live, ContestStatus.Draft].includes(
			contest.status
		);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.fetchStandings();
	}

	public handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;

		if (value === null || value === undefined) {
			return;
		}

		this._selectedContestWeek = Number(value);
		this.fetchStandings();
	};

	@action
	public loadMore = (): void => {
		this._isLoading = true;
		this._leagueStore.loadMoreLeagueStandings(this.selectedContestWeek).finally(() => {
			this._isLoading = false;
		});
	};

	@action
	protected fetchStandings() {
		this._isLoading = true;
		this._leagueStore.fetchLeagueStandings(this.selectedContestWeek).finally(() => {
			this._isLoading = false;
		});
	}

	private get league(): Empty<ILeague> {
		return this._leagueStore.selectedLeague;
	}
}
