import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {LeaderboardTab} from "data/enums";
import React from "react";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITitleStore} from "data/stores/title/title.store";
import i18n from "i18next";

export interface ILeaderboardPageController extends ViewController {
	setActiveTab: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	getIsTabActive: (tab: LeaderboardTab) => boolean;
	getTabClass: (tab: LeaderboardTab) => string;

	get currentTab(): LeaderboardTab;

	get userHasFavouriteTeam(): boolean;
}

@injectable()
export class LeaderboardPageController implements ILeaderboardPageController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	@observable private _currentTab: LeaderboardTab = LeaderboardTab.Overall;

	get currentTab(): LeaderboardTab {
		return this._currentTab;
	}

	get userHasFavouriteTeam(): boolean {
		return this._userStore.userHasFavouriteTeam;
	}

	@action
	public setActiveTab = (event: React.SyntheticEvent<HTMLButtonElement, Event>): void => {
		const tab = event.currentTarget.dataset.tab as LeaderboardTab;

		if (!tab) return;

		this._currentTab = tab;
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		this._titleStore.title = i18n.t("title.leaderboard.title");
	}

	public getIsTabActive = (tab: LeaderboardTab): boolean => {
		return this.currentTab === tab;
	};

	public getTabClass = (tab: LeaderboardTab): string => {
		return this.getIsTabActive(tab) ? "active" : "";
	};
}
