import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const PureButton = styled(Button)`
	font-family: "FK Grotesk Neue";
	min-width: unset;
	max-width: unset;
	color: ${({theme}) => theme.button.tertiary.color};
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;

	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 118%;
	text-align: center;
	letter-spacing: 0.01em;
	text-transform: uppercase;

	&:disabled {
		cursor: default;
		pointer-events: none;
	}
`;
