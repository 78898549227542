import React, {useEffect, useRef} from "react";
import {ModalText, ModalTitle} from "views/components/modals/common";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {TutorialStep} from "data/enums";
import {partial} from "lodash";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ISliderScreenController} from "./sliderScreen.controller";
import {SliderSteps} from "./SliderSteps/sliderSteps.component";

const SliderContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 335px;
	transition: 0.5s;
`;

const SliderItem = styled.div`
	width: 100%;
	min-width: 100%;
	max-width: 335px;

	&:not(.active) {
		height: 0;

		.content {
			transition: 0.25s;
			opacity: 0;
		}
	}
`;

const TopBlock = styled.div`
	height: 240px;
	background: ${({theme}) => theme.brandColor.black};
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
		max-width: 335px;
	}
`;

const Content = styled.div`
	padding: 20px;
	max-width: 335px;
`;

interface IProps {
	changeStep: (step: TutorialStep) => void;
}

export const SliderScreen: React.FC<IProps> = observer(({changeStep}) => {
	const {t} = useTranslation();
	const containerRef = useRef<HTMLDivElement>(null);
	const {sliderItems, currentIndex, changeCurrentIndex, getActiveClass} =
		useViewController<ISliderScreenController>(Bindings.SliderScreenController);

	useEffect(() => {
		const rootBlock = document.getElementById("modal-tutorial");
		if (!rootBlock || !containerRef.current) {
			console.warn("No slider element found");
			return;
		}
		containerRef.current.style.transform = `translateX(-${
			currentIndex * rootBlock.offsetWidth
		}px)`;
	}, [currentIndex]);

	return (
		<React.Fragment>
			<SliderContainer ref={containerRef} id={"modal-tutorial"}>
				{sliderItems.map((item, index) => (
					<SliderItem key={index} className={getActiveClass(index)}>
						<TopBlock>
							<img src={item.img} alt={t(item.title).toString()} />
						</TopBlock>
						<Content className="content">
							<ModalTitle>{t(item.title)}</ModalTitle>
							<ModalText>{t(item.description)}</ModalText>
						</Content>
					</SliderItem>
				))}
			</SliderContainer>

			<SliderSteps
				currentIndex={currentIndex}
				changeCurrentIndex={changeCurrentIndex}
				stepsCount={sliderItems.length}
				moveToNextStep={partial(changeStep, TutorialStep.Finish)}
			/>
		</React.Fragment>
	);
});
