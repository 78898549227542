import {ViewController} from "data/types/structure";
import type {ILeagueCreatePayload} from "data/types/api";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {AxiosError} from "axios";
import type {ILeague} from "data/types/entities";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITitleStore} from "data/stores/title/title.store";
import i18n from "i18next";

interface ILeagueCreateForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	privacy: HTMLInputElement;
}

export interface IFormLeagueCreateController extends ViewController {
	handleForm: (event: React.SyntheticEvent<ILeagueCreateForm>) => void;
	createLeague: (params: ILeagueCreatePayload) => Promise<void>;
	resetFormErrors: () => void;

	error?: string;
	get isLoading(): boolean;
	get isLeagueCreated(): boolean;
	get createdLeague(): ILeague | undefined;
}

@injectable()
export class FormLeagueCreateController implements IFormLeagueCreateController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _error?: string = undefined;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leagueStore: ILeaguesStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	get error() {
		return this._error;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isLeagueCreated() {
		return Boolean(this._leagueStore.selectedLeague);
	}

	get createdLeague() {
		return this._leagueStore.selectedLeague;
	}

	@action resetFormErrors = () => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
	};

	@action private onFormError = (error: AxiosError<{errors: {message: string}[]}>) => {
		this._error = error.response?.data?.errors[0]?.message || error.message;
		this._requestState = RequestState.ERROR;
	};

	@action createLeague({name, privacy}: ILeagueCreatePayload) {
		this._requestState = RequestState.PENDING;
		return this._leagueStore
			.createLeague({name, privacy})
			.catch(this.onFormError)
			.finally(this.onFinally);
	}

	@action handleForm = (event: React.SyntheticEvent<ILeagueCreateForm>) => {
		event.preventDefault();
		const {leagueName, privacy} = event.currentTarget;

		return void this.createLeague({
			name: leagueName.value,
			privacy: privacy.value,
		});
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		this._leagueStore.clearSelectedLeague();
		this._titleStore.title = i18n.t("title.leagues.create");
	}

	private onFinally = () => {
		this._requestState = RequestState.SUCCESS;
	};
}
