import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IAnswersPayload, IParlayPayload, IServerAnswersResponse} from "data/types/api";
import type {IApiAxiosResponse, IHttpClientService} from "data/services/http";
import {AbstractApiProvider} from "data/providers/api/abstract.api.provider";
import {IDashboardResponse} from "data/types/entities";

export interface IAnswersApiProvider {
	fetchAnswersByContestId(contestId: number): Promise<IApiAxiosResponse<IServerAnswersResponse>>;

	saveAnswersForContest(payload: IAnswersPayload, contestId: number): Promise<unknown>;

	saveParlayForContest(contestId: number, payload: IParlayPayload): Promise<unknown>;

	fetchDashboard(): Promise<IApiAxiosResponse<IDashboardResponse>>;
}

@injectable()
export class AnswersApiProvider extends AbstractApiProvider implements IAnswersApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {
		super();
	}

	public fetchDashboard(): Promise<IApiAxiosResponse<IDashboardResponse>> {
		return this._http.get(`${this.language}/dashboard`);
	}

	public fetchAnswersByContestId(
		contestId: number
	): Promise<IApiAxiosResponse<IServerAnswersResponse>> {
		return this._http.get(`${this.language}/answers/${contestId}`);
	}

	public saveAnswersForContest(payload: IAnswersPayload, contestId: number): Promise<unknown> {
		return this._http.post(`${this.language}/answer/${contestId}`, payload);
	}

	public saveParlayForContest(contestId: number, payload: IParlayPayload): Promise<unknown> {
		return this._http.post(`${this.language}/answer/parlay/${contestId}`, payload);
	}
}
