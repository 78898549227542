import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ShareType, SocialNetwork} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ShareUtility} from "data/utils/ShareUtility";
import i18n from "i18next";
import {getDashedValue} from "data/utils/helpers";

interface IParams {
	type: ShareType;
}

export interface IShareControllerController extends ViewController<IParams> {
	shareNative: () => void;
	shareFacebook: () => void;
	shareTwitter: () => void;

	popupShare: () => void;
	popupClose: () => void;

	get isPopoverOpen(): boolean;
}

@injectable()
export class ShareControllerController implements IShareControllerController {
	@observable private _shareType: ShareType = ShareType.General;

	constructor(@inject(Bindings.LeaguesStore) private _leagueStore: ILeaguesStore) {
		makeAutoObservable(this);
	}

	@observable private _isPopoverOpen: boolean = false;

	get isPopoverOpen(): boolean {
		return this._isPopoverOpen;
	}

	public shareNative = () => {
		ShareUtility.share({
			type: this._shareType,
			socialNetwork: SocialNetwork.MOBILE,
			leagueId: this._leagueStore.selectedLeague?.id || 0,
			message: this.getShareMessage(),
		});
	};

	public shareFacebook = () => {
		ShareUtility.share({
			type: this._shareType,
			socialNetwork: SocialNetwork.FACEBOOK,
			leagueId: this._leagueStore.selectedLeague?.id || 0,
			message: this.getShareMessage(),
		});
	};

	public shareTwitter = () => {
		ShareUtility.share({
			type: this._shareType,
			socialNetwork: SocialNetwork.TWITTER,
			leagueId: this._leagueStore.selectedLeague?.id || 0,
			message: this.getShareMessage(),
		});
	};

	@action
	public popupShare = () => {
		this._isPopoverOpen = true;
	};

	@action
	public popupClose = () => {
		this._isPopoverOpen = false;
	};

	private getShareMessage(): string {
		const league = this._leagueStore.selectedLeague;
		if (this._shareType === ShareType.General) {
			return i18n.t("share.type.general");
		}

		return i18n.t("share.type.league", {X: getDashedValue(league?.code)});
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._shareType = param.type;
	}
}
