import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IStaticStore} from "data/stores/static/static.store";
import {Empty} from "data/types/generics";
import {ISquad} from "data/types/api";
import {getImageFromBackend} from "data/utils/helpers";

interface IParams {
	squadId: number | undefined;
}

export interface ISquadImageController extends ViewController<IParams> {
	onLoaded: () => void;

	onError: () => void;

	get squadImage(): string;

	get squad(): Empty<ISquad>;

	get isLoading(): boolean;

	get className(): string;
}

@injectable()
export class SquadImageController implements ISquadImageController {
	@observable private _squadId: number | undefined;

	constructor(@inject(Bindings.StaticStore) private _staticStore: IStaticStore) {
		makeAutoObservable(this);
	}

	@observable private _className: string = "";

	get className(): string {
		return this._className;
	}

	@observable private _isLoading: boolean = true;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get squadImage(): string {
		if (!this.squad) {
			return "";
		}
		return getImageFromBackend(this.squad.logo);
	}

	public get squad(): Empty<ISquad> {
		return this._staticStore.getSquadById(this._squadId);
	}

	@action
	public onLoaded = () => {
		this._className = "";
		this._isLoading = false;
	};

	@action
	public onError = () => {
		this._className += "error";
		this._isLoading = false;
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._squadId = param.squadId;
	}
}
