import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {ICountry} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";
import type {IModalsStore} from "data/stores/modals/modals.store";

interface IDefaultCounter {
	initialNumber: number;
}

export interface IMyAccountController extends ViewController<IDefaultCounter> {
	counter: number;
	get countries(): ICountry[];
	get userName(): string;
	get user(): IUser | undefined;
	increment: () => void;
	decrement: () => void;
	logout: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable counter = 0;

	@action increment = () => {
		this.counter++;
	};

	@action decrement = () => {
		this.counter--;
	};

	get countries() {
		return this._countriesStore.list;
	}

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore
	) {
		makeAutoObservable(this);
	}

	get userName() {
		return this._userStore.fullName;
	}

	get user() {
		return this._userStore.user;
	}

	dispose(): void {
		return;
	}

	logout = () => this._userStore.logout().catch(this.catchError);

	init({initialNumber}: IDefaultCounter): void {
		this.counter = initialNumber;
		void this._countriesStore.fetchCountries();
	}

	private catchError = (e: unknown) => {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalsStore.showAxiosError(error);
	};
}
