import "reflect-metadata";
import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import Routes from "routes";
import {BrowserRouter} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	DIContainer,
	services,
	providers,
	stores,
	controllers,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {PREPROD_URL, PROD_URL, SENTRY_DOMAIN_ALLOWED, UAT_URL} from "data/constants";
import {ThemeProvider} from "@mui/material";
import {theme} from "assets/theme";

import "assets/css/reset.css";
import "assets/css/core.css";
import "assets/css/fonts.css";
import {Preloader} from "views/components/Preloader/preloader.component";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://6115b7ec73e64b2ebef6d921457a7e10@o151969.ingest.sentry.io/4504316277882880",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	allowUrls: [UAT_URL, PREPROD_URL, PROD_URL, SENTRY_DOMAIN_ALLOWED],
	integrations: [
		new BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
});

const root = document.getElementById("root");

if (root) {
	DIContainer.load(services, providers, stores, controllers);

	createRoot(root).render(
		<React.StrictMode>
			<InjectionProvider container={DIContainer}>
				<ThemeProvider theme={theme}>
					<BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
						<Suspense fallback={<Preloader fixed />}>
							<Routes />
						</Suspense>
					</BrowserRouter>
				</ThemeProvider>
			</InjectionProvider>
		</React.StrictMode>
	);
} else {
	throw Error("Root element was not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
