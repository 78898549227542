import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import {Empty} from "data/types/generics";

export interface IParlayPrefillService {
	getOrGenerateParlayPrefill: () => boolean;
}

@injectable()
export class ParlayPrefillService implements IParlayPrefillService {
	private readonly _storageKey = "xfl_ab_prefill";

	constructor(@inject(Bindings.ContestStore) private _contestStore: IContestStore) {
		makeAutoObservable(this);
	}

	public getOrGenerateParlayPrefill(): boolean {
		const currentContestId = this.getCurrentContestId();
		if (!currentContestId) {
			return false;
		}
		const storageMapper = this.getStorageMapper();

		const storageValue = storageMapper[currentContestId];
		console.log(storageValue);
		if (storageValue !== undefined) {
			return Boolean(storageValue);
		}
		return Boolean(this.generatePrefill());
	}

	protected generatePrefill(): number {
		const prefill = Math.round(Math.random());
		this.savePrefill(prefill);
		return prefill;
	}

	protected savePrefill(value: number): void {
		const contestId = this.getCurrentContestId();
		const storageMapper = this.getStorageMapper();
		if (!contestId) {
			return;
		}

		storageMapper[contestId] = value;
		localStorage.setItem(this._storageKey, JSON.stringify(storageMapper));
	}

	private getCurrentContestId(): Empty<number> {
		return this._contestStore.selectedContest?.id;
	}

	private getStorageMapper(): Record<number, number> {
		return JSON.parse(localStorage.getItem(this._storageKey) || "{}") as Record<number, number>;
	}
}
