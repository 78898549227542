import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ModalType, TutorialStep} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IModalTutorialController extends ViewController {
	step: TutorialStep;
	changeStep: (step: TutorialStep) => void;
	close: () => void;
	replay: () => void;

	get isOpen(): boolean;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	@observable step = TutorialStep.Welcome;

	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TUTORIAL;
	}

	@action changeStep = (step: TutorialStep) => {
		this.step = step;
	};

	dispose(): void {
		return;
	}

	init(): void {
		reaction(
			() => this.isOpen,
			() => {
				this.replay();
			}
		);
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	public replay = () => {
		this.changeStep(TutorialStep.Welcome);
	};
}
