import {ISquad} from "data/types/api";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {Empty} from "data/types/generics";
import type {
	IAdvertisement,
	IArticleEntity,
	IHelpContent,
	IPlayer,
	ISectionEntity,
} from "data/types/entities";

export interface IStaticStore {
	get isLoading(): boolean;

	get squads(): ISquad[];

	get players(): IPlayer[];

	get advertisement(): Empty<IAdvertisement>;

	get sections(): ISectionEntity[];

	get articles(): IArticleEntity[];

	fetchPlayers(): Promise<void>;

	getPlayerById(playerId: number): Empty<IPlayer>;

	fetchSquads(): Promise<void>;

	getSquadById(squadId: Empty<number>): Empty<ISquad>;

	fetchHelps(): Promise<void>;

	fetchAdvertisement(): void;
}

@injectable()
export class StaticStore implements IStaticStore {
	@observable private _helpContent: IHelpContent = {
		articles: undefined,
		sections: undefined,
		categories: undefined,
	};

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _advertisement: Empty<IAdvertisement>;

	get advertisement(): Empty<IAdvertisement> {
		return this._advertisement;
	}

	@observable private _players: IPlayer[] = [];

	get players(): IPlayer[] {
		return this._players;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _squads: ISquad[] = [];

	get squads(): ISquad[] {
		return this._squads;
	}

	get articles() {
		return this._helpContent.articles?.articles ?? [];
	}

	get sections() {
		return this._helpContent.sections?.sections ?? [];
	}

	@action
	public async fetchSquads(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.squads();

			runInAction(() => {
				this._squads = data.squads;
			});
		} catch (e) {
			this._modalsStore.showError({message: "Error while loading squads"});
		}
	}

	public getSquadById(squadId: Empty<number>): Empty<ISquad> {
		return this._squads.find((e) => e.id === squadId);
	}

	public getPlayerById(playerId: number): Empty<IPlayer> {
		return this._players.find((e) => e.id === playerId);
	}

	@action
	public async fetchPlayers(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.players();

			runInAction(() => {
				this._players = data.players;
			});
		} catch (e) {
			this._modalsStore.showError({message: "Error while loading squads"});
		}
	}

	public async fetchAdvertisement(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.advertisement();
			runInAction(() => {
				this._advertisement = data;
			});
		} catch (e) {
			console.error(e);
		}
	}

	public async fetchHelps(): Promise<void> {
		await this.fetchArticles();
		await this.fetchCategories();
		await this.fetchSections();
	}

	@action
	private async fetchArticles(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.helpArticles();
			runInAction(() => {
				this._helpContent.articles = data;
			});
		} catch (e) {
			this._modalsStore.showError({message: "Error while loading Help Articles"});
		}
	}

	@action
	private async fetchCategories(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.helpArticles();
			runInAction(() => {
				this._helpContent.articles = data;
			});
		} catch (e) {
			this._modalsStore.showError({message: "Error while loading Terms"});
		}
	}

	@action
	private async fetchSections(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.helpSections();
			runInAction(() => {
				this._helpContent.sections = data;
			});
		} catch (e) {
			this._modalsStore.showError({message: "Error while loading FAQs"});
		}
	}
}
