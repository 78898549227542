import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {makeAutoObservable} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IStaticStore} from "data/stores/static/static.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";

export interface IHOCMainController extends ViewController {
	get isLoadingUser(): boolean;
}

@injectable()
export class HOCMainController implements IHOCMainController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	get isLoadingUser(): boolean {
		return this._userStore.isRequestingUser;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.fetchUser();
		this.fetchContests();
		this.fetchSquads();
		this.fetchAdvertisement();
		this.fetchSettings();
	}

	private fetchUser(): void {
		void this._userStore.fetchUser();
	}

	private fetchSettings(): void {
		void this._settingsStore.fetchSettings();
	}

	private fetchContests(): void {
		void this._contestStore.fetchContests();
	}

	private fetchSquads(): void {
		void this._staticStore.fetchSquads();
	}

	private fetchAdvertisement(): void {
		void this._staticStore.fetchAdvertisement();
	}
}
