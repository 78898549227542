import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRankingsApiProvider} from "data/providers/api/rankings.api.provider";
import type {
	IRankingsPagination,
	IRankingsPaginationObject,
	IRankingsTableData,
	IRankingsTableObject,
} from "data/types/entities";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeaguesApiProvider} from "data/providers/api/leagues.api.provider";
import {AxiosError} from "axios";
import {IAxiosApiError, IOverallRankingsResponse} from "data/types/api";

export interface IRankingsStore {
	fetchOverallLeaderboard: (contestId: number, withClear?: boolean) => Promise<void>;
	loadMoreOverallLeaderboard: (contestId: number) => Promise<void>;

	fetchTeamLeaderboard: (contestId: number, withClear?: boolean) => Promise<void>;
	loadMoreTeamLeaderboard: (contestId: number) => Promise<void>;

	fetchUserPicks(
		userId: number,
		contestId: number
	): ReturnType<ILeaguesApiProvider["fetchUserPicks"]>;

	get isLoading(): boolean;

	get overallTableData(): IRankingsTableData["overall"];

	get teamTableData(): IRankingsTableData["team"];

	get overallNextPage(): boolean;

	get teamNextPage(): boolean;
}

const defaultPagination: IRankingsPaginationObject = {
	page: 1,
	next: false,
};

const defaultTableData: IRankingsTableObject = {
	rankings: [],
	user: undefined,
};

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _pagination: IRankingsPagination = {
		overall: defaultPagination,
		team: defaultPagination,
	};
	@observable private _tableData: IRankingsTableData = {
		overall: defaultTableData,
		team: defaultTableData,
	};

	constructor(
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesApiProvider) private _leaguesApiProvider: ILeaguesApiProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get overallNextPage(): boolean {
		return this._pagination.overall.next;
	}

	get teamNextPage(): boolean {
		return this._pagination.team.next;
	}

	get overallTableData(): IRankingsTableData["overall"] {
		return this._tableData.overall;
	}

	get teamTableData(): IRankingsTableData["team"] {
		return this._tableData.team;
	}

	@action
	public async fetchOverallLeaderboard(contestId: number, withClear = true): Promise<void> {
		if (withClear) {
			this.clearPagination("overall");
		}

		try {
			this._isLoading = true;
			const page = this._pagination.overall.page;
			const response = await this._rankingsApiProvider.fetchOverallRankings({
				page,
				contestId: contestId || undefined,
			});
			this.updateTableAccordingResponse(response.data.success, "overall");
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		} finally {
			this._isLoading = false;
		}
	}

	public loadMoreOverallLeaderboard(contestId: number): Promise<void> {
		this.addPageToPagination("overall");
		return this.fetchOverallLeaderboard(contestId, false);
	}

	@action
	public async fetchTeamLeaderboard(contestId: number, withClear = true): Promise<void> {
		if (withClear) {
			this.clearPagination("team");
		}

		try {
			this._isLoading = true;
			const page = this._pagination.team.page;
			const response = await this._rankingsApiProvider.fetchTeamRankings({
				page,
				contestId: contestId || undefined,
			});
			this.updateTableAccordingResponse(response.data.success, "team");
			return Promise.resolve();
		} catch (e) {
			this.onError(e);
			return Promise.reject(e);
		} finally {
			this._isLoading = false;
		}
	}

	public fetchUserPicks(userId: number, contestId: number) {
		return this._leaguesApiProvider.fetchUserPicks(userId, contestId);
	}

	public loadMoreTeamLeaderboard(contestId: number): Promise<void> {
		this.addPageToPagination("team");
		return this.fetchTeamLeaderboard(contestId, false);
	}

	protected onError(e: unknown) {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		this._modalsStore.showAxiosError(error);
	}

	protected updateTableAccordingResponse(
		data: IOverallRankingsResponse,
		key: keyof IRankingsTableData
	): void {
		const page = this._pagination[key].page;
		const localRankings = this._tableData[key].rankings;

		runInAction(() => {
			const {next, rankings, user} = data;
			this._tableData[key] = {
				rankings: page > 1 ? [...localRankings, ...rankings] : rankings,
				user,
			};
			this._pagination[key].next = next;
		});
	}

	@action
	protected clearPagination(key: keyof IRankingsPagination) {
		this._pagination[key].next = false;
		this._pagination[key].page = 1;
		this._tableData[key].rankings = [];
		this._tableData[key].user = undefined;
	}

	@action
	protected addPageToPagination(key: keyof IRankingsPagination) {
		this._pagination = {
			...this._pagination,
			[key]: {
				next: false,
				page: this._pagination[key].page + 1,
			},
		};
	}
}
