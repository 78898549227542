import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";

export interface ILoginPageController extends ViewController {
	get isModalOpen(): boolean;

	get hasModalBeenOpen(): boolean;
}

@injectable()
export class LoginPageController implements ILoginPageController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	@observable private _hasModalBeenOpen: boolean = false;

	get hasModalBeenOpen(): boolean {
		return this._hasModalBeenOpen;
	}

	get isModalOpen(): boolean {
		return this._modalsStore.modal !== null;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this._modalsStore.showModal(ModalType.LOGIN);
		runInAction(() => {
			this.clearAnswers();
			this._hasModalBeenOpen = true;
		});
	}

	private clearAnswers(): void {
		const ids = this._contestStore.nonLockedPropIds;
		this._answersStore.clearAnswers(ids);
	}
}
