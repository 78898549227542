import React from "react";
import {ModalButton, ModalText, ModalTitle} from "views/components/modals/common";
import IconFinish from "assets/img/tutorial/finish.png";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled";
import {SecondaryButton} from "views/components/common";

const TopBlock = styled.div`
	height: 240px;
	background: ${({theme}) => theme.brandColor.black};
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 100%;
	}
`;

const Content = styled.div`
	padding: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	max-width: 335px;
`;

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	padding: 20px 0 0 0;
	width: 100%;

	button {
		margin: 0;
		max-width: 50%;
		width: 100%;
	}
`;

interface IProps {
	close: () => void;
	replay: () => void;
}

export const FinishScreen: React.FC<IProps> = observer(({close, replay}) => {
	const {t} = useTranslation();

	return (
		<React.Fragment>
			<TopBlock>
				<img src={IconFinish} alt="Final" />
			</TopBlock>
			<Content>
				<ModalTitle>{t("tutorial.finish.title")}</ModalTitle>
				<ModalText>{t("tutorial.finish.description")}</ModalText>

				<Buttons>
					<SecondaryButton onClick={replay}>
						{t("tutorial.finish.replay")}
					</SecondaryButton>
					<ModalButton fullWidth onClick={close}>
						{t("tutorial.finish.button")}
					</ModalButton>
				</Buttons>
			</Content>
		</React.Fragment>
	);
});
