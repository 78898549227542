import {createTheme} from "@mui/material";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject = {
	text: {
		color: {
			black: "#121212",
			grey: "#59616A",
			lightGrey: "#DBDBDB",
			white: "#FFFFFF",
			links: "#333333",
			danger: "#C20000",
		},
		fontSize: {
			heading: {
				sm: "18px",
				md: "20px",
				lg: "24px",
				xl: "32px",
			},
			regular: {
				small_11: "11px",
				small_13: "13px",
				text: "14px",
			},
		},
		fontFamily: "All-Pro Sans, sans-serif",
	},
	brandColor: {
		black: "#121212",
		dark: "#333333",
		grey: "#999999",
		lightGrey: "#DBDBDB",
		white: "#FFFFFF",
		danger: "#C20000",
		placeholder: "#FF00B8",
		action: "#2196F3",
	},
	button: {
		primary: {
			default: {
				background: "#121212",
				color: "#FFFFFF",
			},
			hover: {
				background:
					"linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(18, 18, 18, 0.8)",
				color: "#FFFFFF",
			},
			disabled: {
				background: "#B8B8B8",
				color: "#FFFFFF",
				opacity: 0.3,
			},
		},
		secondary: {
			default: {
				background: "#FFFFFF",
				color: "#121212",
				borderColor: "#121212",
			},
			hover: {
				background: "#F1F1F1",
				borderColor: "#F1F1F1",
				color: "#121212",
			},
			disabled: {
				borderColor: "#121212",
				background: "#F1F1F1",
				color: "#121212",
				opacity: 0.3,
			},
		},
		tertiary: {
			background: "transparent",
			color: "#121212",
			hoverOpacity: 0.8,
			disabledOpacity: 0.5,
		},
	},
	typography: {
		fontFamily: ["Poppins, sans-serif"].join(","),
	},
	roundSelector: {
		timerColor: "#0099FF",
		liveColor: "#29CC6A",
		completeColor: "#0099FF",
	},

	dashboard: {
		open: {
			background: "#FFFFFF",
			color: "#121212",
		},
		live: {
			background: "#121212",
			color: "#FFFFFF",
		},
		complete: {
			background: "#0099FF",
			color: "#FFFFFF",
		},
	},
};

type ITheme = typeof themeObject;
export const theme = createTheme(themeObject);

declare module "@mui/material/styles" {
	/**
	 *Disabled as it's allowing to avoid `any` type per MUI documentation
	 * https://mui.com/material-ui/customization/theming/#custom-variables
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}

declare module "@emotion/react" {
	/**
	 *Disabled as it's allowing to avoid `any` type per Emotion documentation
	 * https://emotion.sh/docs/typescript#define-a-theme
	 */

	// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-interface
	interface Theme extends ITheme {}
}
