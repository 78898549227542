import {AbstractValidator} from "data/utils/helpers/validators/abstract.validator";
import {THTMLFormElements} from "data/types/validators";
import i18n from "i18next";

export class PostcodeValidator extends AbstractValidator {
	getErrorMessage(): string {
		return i18n.t("form.common.postcode_invalid");
	}

	validate(formControl: THTMLFormElements): boolean {
		return /^[0-9]{5}$/.test(formControl.value);
	}
}
