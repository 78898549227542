import styled from "@emotion/styled";
import {Switch} from "@mui/material";
import {BASE_FONT} from "data/constants";

export const CustomSwitch = styled(Switch)({
	".Mui-checked": {
		".MuiSwitch-thumb": {
			background: "#121212",
		},

		"&+.MuiSwitch-track": {
			background: "#4D4D4D",
			backgroundColor: "#4D4D4D !important",
		},
	},

	".MuiTypography-root": {
		fontFamily: BASE_FONT,
	},
});
