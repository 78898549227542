import styled from "@emotion/styled";
import pageBackground from "assets/img/pageBackground.svg";
export const Container = styled.div`
	padding: 20px;
	position: relative;
`;

export const AuthContainer = styled(Container)`
	padding: 68px 20px 150px 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background: url("${pageBackground}") #ffffff;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0 bottom;

	overflow: auto;
	max-width: 420px;
	margin: 0 auto;

	&.full-screen {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	> img {
		height: 90px;
		width: auto;
		margin-bottom: 8px;
	}

	h1 {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		margin-bottom: 28px;
	}

	a.close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const RegisterLink = styled.span`
	display: block;
	text-align: center;
	margin-top: 16px;
	margin-bottom: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	letter-spacing: 0.01em;
	text-transform: capitalize;
	color: ${({theme}) => theme.text.color.black};

	a,
	button {
		text-decoration: underline;
		text-transform: capitalize;
		padding: 0;
	}
`;

export const MenuBlock = styled.div`
	padding: 24px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 16px;
	width: 100%;

	a,
	button {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 118%;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.text.color.white};
		padding: 0;

		&:hover {
			text-decoration: underline;
		}
	}

	.sub-link {
		padding-left: 12px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 16px;
	}

	&.bb {
		border-bottom: 1px solid ${({theme}) => theme.brandColor.dark};
	}
`;

export const PropCounter = styled.div`
	position: absolute;
	left: 16px;
	top: 16px;
	line-height: 0;
	height: 24px;

	padding: 6px 8px;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.01em;
	color: ${({theme}) => theme.text.color.black};
	display: flex;
	justify-content: center;
	align-items: center;

	background: ${({theme}) => theme.brandColor.white};
	border-radius: 50px;

	&.dark {
		color: ${({theme}) => theme.brandColor.white};
		background: ${({theme}) => theme.text.color.black};
	}
`;

export const ResultsBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
	margin-top: auto;
	width: 100%;

	div {
		width: 50%;
		height: 50px;
		border: 1px solid ${({theme}) => theme.brandColor.lightGrey};
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 8px 12px;
		transition: 0.5s;

		p {
			font-weight: 500;
			font-size: 11px;
			text-align: center;
			letter-spacing: 0.01em;
			color: ${({theme}) => theme.text.color.black};

			&.points {
				font-size: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				gap: 4px;

				svg {
					margin-top: -2px;
					width: 14px;
					height: 14px;
				}
			}
		}
	}

	.wager.selected {
		background: rgba(198, 60, 246, 0.1);
	}

	.payout.selected {
		background: rgba(33, 150, 243, 0.1);
	}

	.wager input {
		display: inline;
		background: transparent;
		border: none;
		width: 50px;
		text-align: right;
		margin-left: -30px;
		outline: none;
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&.canceled {
		filter: grayscale(100%);
	}
`;

export const BasePageLayout = styled.div`
	padding: 20px;

	&.with-adv {
		padding-bottom: 0;
	}

	&.commissioner form {
		padding-bottom: 0;
		border-bottom: none;
	}
`;

export const OddsTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 140%;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.text.color.black};

		&.odds {
			display: flex;
			justify-content: center;
			align-items: center;

			height: 24px;
			padding: 0 8px;

			color: ${({theme}) => theme.text.color.white};
			background: ${({theme}) => theme.brandColor.black};
			-webkit-border-radius: 50px;
			-moz-border-radius: 50px;
			border-radius: 50px;

			font-weight: 500;
			font-size: 11px;
			line-height: 118%;
			letter-spacing: 0.01em;
		}
	}
`;

export const WagerBlockIconBet = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;
	min-width: 32px;
	background: ${({theme}) => theme.brandColor.black};
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	span {
		color: ${({theme}) => theme.text.color.white};
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		text-align: center;
		letter-spacing: 0.01em;
	}

	svg {
		top: -4px;
		right: -4px;
		position: absolute;
		width: 14px;
		height: 14px;
		border: 1px solid #ffffff;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;

		path,
		circle {
			transform: scale(1.3) translate(-6px, -6px);
		}
	}

	&.add {
		background: ${({theme}) => theme.brandColor.action};

		svg {
			position: relative;
			top: 0;
			left: 0;
			border: none;

			path,
			circle {
				transform: unset;
			}
		}

		&.locked {
			background: ${({theme}) => theme.brandColor.black};

			svg {
				width: 20px;
				height: 20px;
			}
		}

		&.canceled {
			background: ${({theme}) => theme.brandColor.grey};
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}

	.bet-circle {
		margin-left: 12px;
		font-weight: 700;
		font-size: 11px;
		color: ${({theme}) => theme.text.color.white};
	}

	&.correct {
		background: #18c971;

		svg {
			width: 20px;
			height: auto;
			margin-left: 2px;
		}
	}

	&.incorrect {
		background: #c20000;

		svg {
			width: 24px;
			height: auto;

			path {
				fill: ${({theme}) => theme.text.color.white};
			}
		}
	}

	&.non-resulted {
		svg {
			width: 24px;
			height: auto;

			path {
				fill: ${({theme}) => theme.text.color.white};
			}
		}
	}
`;

export const ContestLabel = styled.div`
	height: 24px;
	padding: 0 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2px;

	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	border-radius: 50px;

	font-size: 11px;
	font-weight: 500;
	line-height: 118%;
	letter-spacing: 0.01em;
	color: ${({theme}) => theme.dashboard.open.color};
	background: ${({theme}) => theme.dashboard.open.background};

	&.live {
		color: ${({theme}) => theme.dashboard.live.color};
		background: ${({theme}) => theme.dashboard.live.background};

		> span {
			color: ${({theme}) => theme.dashboard.live.color};
		}

		&.white {
			background: ${({theme}) => theme.dashboard.live.color};
			color: ${({theme}) => theme.dashboard.live.background};

			> span {
				color: ${({theme}) => theme.dashboard.live.background};
			}

			svg path {
				fill: ${({theme}) => theme.dashboard.live.background};
			}
		}

		&.success {
			background: #18c971;
			color: ${({theme}) => theme.text.color.white};
		}
	}

	&.complete {
		color: ${({theme}) => theme.dashboard.complete.color};
		background: ${({theme}) => theme.dashboard.complete.background};
	}

	&.canceled {
		background: ${({theme}) => theme.brandColor.lightGrey};
		color: ${({theme}) => theme.brandColor.grey};
	}

	&.public {
		text-transform: capitalize;
		background: #18c971;
		color: ${({theme}) => theme.text.color.white};
	}

	&.private {
		text-transform: capitalize;
		background: #2196f3;
		color: ${({theme}) => theme.text.color.white};
	}

	svg {
		height: 70%;
	}
`;

export const PreloaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 12px 0;
`;

export const PayoutElement = styled.div`
	background: rgba(33, 150, 243, 0.1);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2px 8px;

	font-style: normal;
	font-weight: 500;
	font-size: 11px;
	letter-spacing: 0.01em;
	color: ${({theme}) => theme.brandColor.action};
	gap: 4px;

	span {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 4px;
	}

	svg {
		width: 14px;
		height: 14px;

		* {
			fill: ${({theme}) => theme.brandColor.action};
		}
	}

	&.correct {
		background: rgba(24, 201, 113, 0.1);
		color: #18c971;

		svg * {
			fill: #18c971;
		}
	}

	&.incorrect {
		background: rgba(197, 26, 32, 0.1);
		color: ${({theme}) => theme.text.color.danger};

		svg * {
			fill: ${({theme}) => theme.text.color.danger};
		}
	}
`;

export const SquadImageWrapper = styled.div`
	width: 44px;
	height: auto;

	img,
	.preloader {
		width: 44px;
		height: auto;
		max-width: 44px;
	}
`;

export const GuessResult = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.guess,
	.reverse-guess {
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 118%;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.text.color.black};
		text-transform: capitalize;
	}

	.reverse-guess {
		margin-right: auto;
		background: rgba(33, 150, 243, 0.1);
		border-radius: 10px;
		padding: 0 8px;
		color: #2196f3;
		margin-left: 8px;
		line-height: 0;
		height: 21px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const HelpContent = styled.div`
	color: ${({theme}) => theme.text.color.black};
	font-size: 100%;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: currentColor;
		margin-bottom: 0.75em;
	}

	h4 {
		font-size: 1.125em;
		line-height: 1.75em;
	}

	h3 {
		font-size: 1.25em;
		line-height: 2em;
		margin-bottom: 0.625em;
	}

	li {
		line-height: 18px;
	}

	p,
	table,
	ul,
	ol {
		font-weight: 500;
		font-size: 15px;
		line-height: 140%;
		color: ${({theme}) => theme.text.color.black};
		letter-spacing: 0.01em;
	}

	ol,
	ul {
		margin-left: 1em;
	}

	ul {
		list-style-type: circle;
	}

	ol {
		list-style-type: decimal;
	}

	i {
		font-style: italic;
	}

	b,
	strong {
		font-weight: bold;
	}

	a {
		color: var(--primaryColor);
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;

		@media (max-width: 639px) {
			width: 100%;
		}

		td {
			text-align: right;
			padding: 10px;
			border: 1px solid #eaeaea;
			background-color: #ffffff;

			&:first-of-type {
				text-align: left;
				text-transform: uppercase;
				font-weight: 700;
				border: none;
				background: none;
			}
		}
	}

	img {
		max-width: 100%;
	}

	*:last-child {
		margin-bottom: 0;
	}
`;

export const StatsBlock = styled.div`
	background: ${({theme}) => theme.brandColor.lightGrey};
	border-radius: 4px;
	padding: 8px;
	min-width: 66px;

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 9px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.text.color.black};
		text-transform: uppercase;
		margin-bottom: 2px;

		&.stats {
			font-size: 11px;
			display: flex;
			justify-content: center;
			align-items: flex-start;
			gap: 2px;
			margin-bottom: 0;

			svg {
				width: 12px;
				height: 12px;
			}
		}

		&.name {
			font-size: 9px;
		}
	}
`;

export * from "./league";
