import {PureButton} from "./pureButton";
import styled from "@emotion/styled";

export const PrimaryButton = styled(PureButton)`
	background: ${({theme}) => theme.button.primary.default.background};
	color: ${({theme}) => theme.button.primary.default.color};
	border: 1px solid ${({theme}) => theme.button.primary.default.background};
	padding: 14px 22px;
	height: 48px;

	.preloader svg {
		color: ${({theme}) => theme.button.primary.default.color};
	}

	&:hover,
	&:active,
	&:focus &.active {
		background: ${({theme}) => theme.button.primary.hover.background};
		color: ${({theme}) => theme.button.primary.hover.color};
		border-color: ${({theme}) => theme.button.primary.hover.background};
	}

	&:disabled {
		background: ${({theme}) => theme.button.primary.disabled.background};
		color: ${({theme}) => theme.button.primary.disabled.color};
		border-color: ${({theme}) => theme.button.primary.disabled.background};
		opacity: ${({theme}) => theme.button.primary.disabled.opacity};
	}
`;
