import React from "react";
import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IProp} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import type {ILiveScoringStore} from "data/stores/liveScoring/livescoring.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {Empty} from "data/types/generics";
import {PropStatus, SummaryTab} from "data/enums";
import {NavigateFunction, useNavigate} from "react-router-dom";
import i18n from "i18next";

interface IParams {
	contestId: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IPicksSummaryController extends ViewController<IParams> {
	setActiveTab: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
	getIsTabActive: (tab: SummaryTab) => boolean;
	getActiveTabClass: (tab: SummaryTab) => string;
	setParlayStep: () => void;
	clickParlayTab: () => void;
	goToPicks: () => void;

	get props(): IProp[];

	get activeTab(): SummaryTab;

	get isParlayDisabled(): boolean;

	get parlayMessage(): string;

	get parlayGuessClass(): string;

	get oddsCount(): number;

	get selectedPropsLength(): number;

	get isParlayLocked(): boolean;

	get isParlayCanBeEdit(): boolean;

	get isPicksCanBeEdit(): boolean;
}

@injectable()
export class PicksSummaryController implements IPicksSummaryController {
	@observable private _navigate: Empty<NavigateFunction>;

	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore,
		@inject(Bindings.LiveScoringStore) private _liveScoringStore: ILiveScoringStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _activeTab: SummaryTab = SummaryTab.Picks;

	get activeTab(): SummaryTab {
		return this._activeTab;
	}

	get props(): IProp[] {
		return this._contestStore.selectedContest?.props ?? [];
	}

	get isParlayDisabled(): boolean {
		const openPropsLength =
			this._contestStore.selectedContest?.props.filter((e) => e.status === PropStatus.Open)
				.length ?? 0;
		return openPropsLength < 2 && !this._answersStore.userHasSavedParlay;
	}

	get parlayGuessClass(): string {
		const answers = this._answersStore.parlayAnswers;

		const incorrect = answers.some((e) => {
			const prop = this._contestStore.getPropById(e.questionId);
			if (!prop || !prop.correct) {
				return false;
			}
			return prop.correct !== e.pick;
		});

		if (incorrect) {
			return "incorrect";
		}

		const correct = answers.every((e) => {
			const prop = this._contestStore.getPropById(e.questionId);
			if (!prop || !prop.correct) {
				return false;
			}
			return prop.correct === e.pick;
		});

		if (correct) {
			return "correct";
		}

		return "";
	}

	get parlayMessage(): string {
		return this._answersStore.userHasSavedParlay
			? "game.summary.parlay_exists"
			: "game.summary.parlay_build";
	}

	get oddsCount(): number {
		return Math.pow(2, this._answersStore.parlayIds.length);
	}

	get selectedPropsLength(): number {
		return this._answersStore.parlayIds.length;
	}

	get isParlayLocked(): boolean {
		return this.votedProps.some((e) => e.status !== PropStatus.Open);
	}

	get isParlayCanBeEdit() {
		if (this.isParlayLocked) {
			return false;
		}

		return this.getIsTabActive(SummaryTab.Parlay);
	}

	get isPicksCanBeEdit() {
		const contest = this._contestStore.selectedContest;
		if (!contest) {
			return false;
		}
		const nonCanceled = contest.props.filter((e) => e.status !== PropStatus.Canceled);

		return (
			nonCanceled.some((e) => e.status === PropStatus.Open) &&
			this.getIsTabActive(SummaryTab.Picks)
		);
	}

	private get votedProps(): IProp[] {
		const answerIds = this._answersStore.parlayAnswers.map((e) => e.questionId);

		return (
			this._contestStore.selectedContest?.props.filter(
				(e) => answerIds.includes(e.id) && e.status !== PropStatus.Canceled
			) ?? []
		);
	}

	dispose(): void {
		this._liveScoringStore.unsubscribeLiveScoring();
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
		this._liveScoringStore.subscribeLiveScoring("summary");
		if (param.contestId) {
			this._contestStore.setContestById(param.contestId);
			void this._answersStore.fetchAnswersByContestId(param.contestId);
		}

		if (!this._dashboardStore.dashboardInfo) {
			void this._dashboardStore.fetchDashboard();
		}
		this.checkTabs();
	}

	@action
	public setActiveTab = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
		const tab = event.currentTarget.dataset.tab as SummaryTab;

		if (!tab) {
			return;
		}

		this._activeTab = tab;
	};

	public getActiveTabClass = (tab: SummaryTab): string => {
		return this.getIsTabActive(tab) ? "active" : "";
	};

	public getIsTabActive = (tab: SummaryTab): boolean => {
		return this._activeTab === tab;
	};

	public setParlayStep = () => {
		const contest = this._contestStore.selectedContest;
		if (!contest) {
			return;
		}

		this._contestStore.lastActiveTab = SummaryTab.Parlay;
		this._contestStore.currentSliderStep = contest.props.length + 1;
	};

	@action
	public clickParlayTab = () => {
		if (this._answersStore.userHasSavedParlay || this.isParlayLocked) {
			this._activeTab = SummaryTab.Parlay;
			return;
		}

		if (this._answersStore.answers.length < 2) {
			this.showErrorMessageNoPicks();
			return;
		}

		if (this._navigate) {
			this.setParlayStep();
			this._navigate("/picks");
		}
	};

	public goToPicks = () => {
		if (this._navigate) {
			this.setPicksStep();
			this._contestStore.lastActiveTab = SummaryTab.Picks;
			this._navigate("/picks");
		}
	};

	private setPicksStep = () => {
		const contest = this._contestStore.selectedContest;
		if (!contest) {
			return;
		}

		this._contestStore.currentSliderStep = 0;
	};

	private showErrorMessageNoPicks() {
		this._modalsStore.showError({
			title: i18n.t("error.not_enough_picks.title") ?? "Error",
			message: i18n.t("error.not_enough_picks.text"),
			buttonText: i18n.t("error.not_enough_picks.action") ?? "Close",
		});
	}

	@action
	private checkTabs(): void {
		if (this._contestStore.lastActiveTab) {
			this._activeTab = this._contestStore.lastActiveTab;
		}
		this._contestStore.lastActiveTab = undefined;
	}
}
