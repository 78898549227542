import React from "react";
import {ViewController} from "data/types/structure";
import type {IResetPasswordForm} from "data/types/forms";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ValidationScheme} from "data/types/validators";
import {PasswordValidator} from "data/utils/helpers/validators/PasswordValidator";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IFormValidator} from "data/utils/helpers/validators/FormValidator";
import type {Empty} from "data/types/generics";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {IAxiosApiError, IResetPasswordPayload} from "data/types/api";
import {IResetPasswordModalContent} from "data/types/modals";
import type {IUserStore} from "data/stores/user/user.store";
import {AxiosError} from "axios";
import {getErrorMessageFromAxiosResponse} from "data/utils/helpers";

export interface IModalResetPasswordController extends ViewController {
	close: () => void;
	handleFormSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;

	togglePasswordVisibility: () => void;
	toggleConfirmPasswordVisibility: () => void;

	get form(): IResetPasswordForm;

	get formErrors(): Record<string, Empty<string>>;

	get isLoading(): boolean;

	get isSuccess(): boolean;

	get isOpen(): boolean;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;

	get error(): Empty<string>;
}

@injectable()
export class ModalResetPasswordController implements IModalResetPasswordController {
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
	};

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
		this._formValidator.enterScheme(this._validationScheme);
	}

	@observable private _error: Empty<string>;

	get error(): Empty<string> {
		return this._error;
	}

	@observable private _isSuccess: boolean = false;

	get isSuccess(): boolean {
		return this._isSuccess;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _form: IResetPasswordForm = {
		password: "",
		confirmPassword: "",
	};

	get form(): IResetPasswordForm {
		return this._form;
	}

	get formErrors(): Record<string, Empty<string>> {
		return this._formValidator.formErrors;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.RESET_PASSWORD;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	close = (): void => {
		this._modalsStore.hideModal();
		this.changeSuccessState(true);

		setTimeout(() => {
			this.changeSuccessState(false);
		}, 2000);
	};

	handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>): void => {
		event.preventDefault();
		event.stopPropagation();

		const isValid = this._formValidator.validate(event.currentTarget);
		if (!isValid || this.isLoading) {
			return;
		}

		if (this.form.password !== this.form.confirmPassword) {
			this._formValidator.setError("confirmPassword", "Password don't match");
			return;
		}

		const payload: IResetPasswordPayload = {
			password: this.form.password,
			token: (this._modalsStore.modalContent as IResetPasswordModalContent).token,
		};

		this.changeLoadingState(true);
		this._userStore
			.resetPasswordRequest(payload)
			.then(this.onSuccess)
			.catch(this.catchError)
			.finally(this.onFinally);
	};

	handleFormChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
		const {name, value} = event.target;

		if (!name) {
			return;
		}
		const keyName = name as keyof IResetPasswordForm;

		runInAction(() => {
			this._form = {
				...this._form,
				[keyName]: String(value),
			};
		});
		this._formValidator.clearError(keyName);
		this.clearError();
	};

	toggleConfirmPasswordVisibility = (): void => {
		runInAction(() => {
			this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
		});
	};

	togglePasswordVisibility = (): void => {
		runInAction(() => {
			this._isPasswordVisible = !this._isPasswordVisible;
		});
	};

	@action
	private changeLoadingState(value: boolean): void {
		runInAction(() => {
			this._isLoading = value;
		});
	}

	@action
	private changeSuccessState(value: boolean): void {
		runInAction(() => {
			this._isSuccess = value;
		});
	}

	@action
	private onSuccess = () => {
		this.changeSuccessState(true);
		this.close();
	};

	private catchError = (e: unknown) => {
		const error = e as AxiosError<IAxiosApiError, unknown>;
		runInAction(() => {
			this._error = getErrorMessageFromAxiosResponse(error);
		});
	};

	private clearError(): void {
		runInAction(() => {
			this._error = undefined;
		});
	}

	@action
	private onFinally = () => {
		this.changeLoadingState(false);

		setTimeout(() => {
			this.changeSuccessState(false);
		}, 2000);
	};
}
