import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {Header} from "views/components/Header/header.component";
import {Footer} from "views/components/Footer/footer.component";
import {HOCMain} from "views/components/HOCMain/HOCMain.component";
import {HOCi18n} from "views/components/HOCi18n";
import styled from "@emotion/styled";
import {Menu} from "views/components/Menu/menu.component";
import {HOCSecretKey} from "views/components/HOCSecretKey/HOCSecretKey.component";

// Auth
const Registration = lazy(
	retryFailLoad(() => import("views/pages/registration/registration.page"))
);
const Login = lazy(retryFailLoad(() => import("views/pages/login/login.page")));
const ResetPassword = lazy(
	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
);

const Home = lazy(retryFailLoad(() => import("views/pages/home/home.page")));
const Picks = lazy(retryFailLoad(() => import("views/pages/picks/picks.page")));
const PicksSummary = lazy(
	retryFailLoad(() => import("views/pages/picks/picks_summary/picksSummary.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

const HelpPage = lazy(retryFailLoad(() => import("views/pages/help/help.page")));

const LeaguesOutlet = lazy(retryFailLoad(() => import("views/pages/leagues/leagues.outlet")));
const MyLeagues = lazy(
	retryFailLoad(() => import("views/pages/leagues/my_leagues/my_leagues.page"))
);
const LeagueCreatePage = lazy(() => import("views/pages/leagues/create/league_create.page"));
const LeaguesJoinPage = lazy(() => import("views/pages/leagues/join/leagues_join.page"));
const ViewLeagueOutlet = lazy(() => import("views/pages/leagues/view/viewLeague.outlet"));
const LeagueAboutPage = lazy(() => import("views/pages/leagues/view/about/league_about.page"));
const LeagueInvitePage = lazy(
	() => import("views/pages/leagues/view/league_invites/league_invites.page")
);
const LeagueLadderPage = lazy(
	() => import("views/pages/leagues/view/league_ladder/leagueLadder.page")
);

const BonusCodesPage = lazy(() => import("views/pages/bonus_codes/bonus_codes.page"));
const Leaderboard = lazy(() => import("views/pages/leaderboard/leaderboard.page"));
const InvitePage = lazy(() => import("views/pages/invite/invite.page"));

const AppWrapper = styled.div`
	background: radial-gradient(
		${({theme}) => theme.brandColor.grey} 0%,
		${({theme}) => theme.brandColor.lightGrey} 25%
	);
`;

const AppContainer = styled.div`
	width: 100%;
	max-width: 420px;
	position: relative;
	margin: 0 auto;
	flex-flow: column wrap;
	-webkit-box-pack: unset;
	justify-content: unset;
	display: flex;
	flex: 1 1 0;
	align-items: flex-start;
	overflow: hidden;
`;

export const RootRoutes: React.FC = () => (
	<React.Fragment>
		<AppWrapper>
			<AppContainer id="app-container">
				<HOCi18n>
					<HOCSecretKey>
						<React.Fragment>
							<Header />
							<Menu />
							<HOCMain>
								<Routes>
									<Route index element={<Home />} />
									<Route path="/picks" element={<Picks />} />
									<Route path="/picks/:id/summary" element={<PicksSummary />} />
									<Route element={<NotAuthOnlyRoute />}>
										<Route path="/reset-password" element={<ResetPassword />} />
										<Route path="registration" element={<Registration />} />
										<Route path="login" element={<Login />} />
									</Route>
									<Route element={<PrivateRoute />}>
										<Route path="/my-account" element={<MyAccount />} />
										<Route path="/leagues" element={<LeaguesOutlet />}>
											<Route path="create" element={<LeagueCreatePage />} />
											<Route
												path="join/:code"
												element={<LeaguesJoinPage />}
											/>
											<Route path="join" element={<LeaguesJoinPage />} />
											<Route path="" element={<MyLeagues />} />
											<Route path="view/:id" element={<ViewLeagueOutlet />}>
												<Route
													path="standings"
													element={<LeagueLadderPage />}
												/>
												<Route
													path="invite"
													element={<LeagueInvitePage />}
												/>
												<Route path="about" element={<LeagueAboutPage />} />
											</Route>
										</Route>
										<Route path="/bonus-codes" element={<BonusCodesPage />} />
										<Route path="/rankings" element={<Leaderboard />} />
									</Route>
									<Route path="invite/:code" element={<InvitePage />} />
									<Route path="help/:section" element={<HelpPage />} />
									<Route path="help" element={<HelpPage />} />
									<Route path="*" element={<NotFound />} />
								</Routes>
							</HOCMain>
							<Footer />
						</React.Fragment>
					</HOCSecretKey>
				</HOCi18n>
			</AppContainer>
		</AppWrapper>
	</React.Fragment>
);

export default RootRoutes;
