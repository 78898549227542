import React from "react";
import {MenuBlock} from "views/components/common";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const MenuNonLogged: React.FC = () => {
	const {t} = useTranslation();
	return (
		<React.Fragment>
			<MenuBlock className="bb">
				<NavLink to="/">{t("menu.nav.home")}</NavLink>
				<NavLink to="/login">{t("menu.nav.auth")}</NavLink>
			</MenuBlock>
			<MenuBlock>
				<NavLink to="/help">{t("menu.nav.help")}</NavLink>
			</MenuBlock>
		</React.Fragment>
	);
};
