export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	ERROR,
	SUCCESS,
	PARLAY_TUTORIAL,
	PICKS_SAVED,
	PICKS_SUBMIT,
	CHANGE_CONTEST,
	LEAVE_LEAGUE,
	REMOVE_LEAGUE_USER,
	TUTORIAL,
	REGISTRATION,
	LOGIN,
	BEFORE_PICKS_SAVE,
	NO_PICKS_BET,
	POST_SEASON,
}

export enum Locale {
	English = "en",
}

export enum ContestStatus {
	Draft = "draft",
	Open = "open",
	Live = "live",
	Complete = "complete",
	Canceled = "canceled",
}

export enum PropStatus {
	Open = "open",
	Locked = "locked",
	Resulted = "resulted",
	Canceled = "canceled",
}

export enum PropType {
	Custom = "custom",
	Player = "player",
	Squad = "squad",
	Sponsored = "sponsored",
}

export enum HelpSection {
	HowToPlay = "how-to-play",
	FAQs = "faqs",
	Terms = "terms",
	ContactUs = "contact-us",
}

export enum Guess {
	Over = "over",
	Under = "under",
}

export enum SummaryTab {
	Picks = "picks",
	Parlay = "parlay",
}

export enum LeaguePrivacy {
	Public = "public",
	Private = "private",
}

export enum LeagueFiltersPrivacy {
	Public = "public",
	Private = "private",
	All = "all",
}

export enum MediaType {
	Custom = "custom",
	Squad = "squad",
	Player = `player`,
}

export enum TutorialStep {
	Welcome = "welcome",
	Slider = "slider",
	Finish = "finish",
}

export enum LeaderboardTab {
	Overall = "overall",
	FavoriteTeam = "favorite_team",
}

export enum ShareType {
	General = "general",
	League = "league",
}

export enum ShareAppearance {
	SmallView = "smallView",
	ButtonsView = "buttonsView",
}

export enum SocialNetwork {
	FACEBOOK,
	TWITTER,
	MOBILE,
}

export enum PeriodStatus {
	PostSeason = "postseason",
	Season = "season",
}
