import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {ModalType} from "data/enums";
import {noop} from "lodash";

export interface IModalLeaveLeagueController extends ViewController {
	close: () => void;

	submitLeave: () => void;

	get isOpen(): boolean;

	get isJoined(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class ModalLeaveLeagueController implements IModalLeaveLeagueController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isJoined(): boolean {
		return Boolean(this._leaguesStore.selectedLeague?.isJoined);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.LEAVE_LEAGUE;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	@action
	public submitLeave = (): void => {
		if (!this._leaguesStore.selectedLeague) {
			return;
		}

		try {
			this._isLoading = true;
			this._leaguesStore
				.leaveLeagueById(this._leaguesStore.selectedLeague.id)
				.then(this.close.bind(this))
				.catch(noop);
		} finally {
			this._isLoading = false;
		}
	};
}
