export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const SPONSORED_URL = process.env.REACT_APP_SPONSORED_URL || "";
export const HELPS_URL = process.env.REACT_APP_HELP_URL || "";
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IS_SECRET_ENABLED = JSON.parse(
	process.env.REACT_APP_IS_SECRET_ENABLED || "false"
) as boolean;
export const IS_POSTSEASON_CAN_BE_CLOSED = JSON.parse(
	process.env.REACT_APP_IS_POSTSEASON_CAN_BE_CLOSED || "true"
) as boolean;

// Sentry
export const UAT_URL = "https://xfl-pickem.uat.f2p.media.geniussports.com";
export const PREPROD_URL = "https://xfl-pickem.preprod.f2p.media.geniussports.com";
export const PROD_URL = "https://xfl-pickem.us.f2p.media.geniussports.com";
export const SENTRY_DOMAIN_ALLOWED = ".f2p.media.geniussports.com";
export const BOXES_URL = process.env.REACT_APP_BINGO_URL || "";

export const POSTCODE_COUNTRY = "USA";
export const VALIDATOR_CLASS = "form-control-to-validate";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const TRANSLATION_PASSWORD = "form.common.password";
export const DEFAULT_CHIPS_STEP = 5;
export const DEFAULT_CHIPS_COUNT = 300;
export const EMAIL_PATTERN = "[A-za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
export const DEFAULT_LEAGUES_FETCH_LIMIT = 10;
export const DEFAULT_NO_TEAM_KEY = "xfl fan";
export const PROP_TIME_FORMAT = "L/d h:mm a ZZZZ";
export const DEFAULT_WAGER = 30;
export const LIVE_SCORING_FETCH_TIMING = 1000 * 60;
export const BASE_FONT = "'FK Grotesk Neue', sans-serif";
