import React from "react";
import {MenuBlock, PureButton} from "views/components/common";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useViewController} from "data/services/locator";
import {IMenuController} from "data/stores/menu/menu.controller";
import {Bindings} from "data/constants/bindings";

export const MenuLogged: React.FC = () => {
	const {t} = useTranslation();
	const {logout} = useViewController<IMenuController>(Bindings.MenuController);

	return (
		<React.Fragment>
			<MenuBlock className="bb">
				<NavLink to="/">{t("menu.nav.home_logged")}</NavLink>
				<NavLink to="/leagues">{t("menu.nav.leagues")}</NavLink>
				<div className="sub-link">
					<NavLink to="/leagues/create">{t("menu.nav.leagues_create")}</NavLink>
					<NavLink to="/leagues/join">{t("menu.nav.leagues_join")}</NavLink>
				</div>
				<NavLink to="/rankings">{t("menu.nav.leadeboard")}</NavLink>
			</MenuBlock>
			<MenuBlock className="bb">
				<NavLink to="/help">{t("menu.nav.help")}</NavLink>
				<NavLink to="/my-account">{t("menu.nav.account")}</NavLink>
				<NavLink to="/bonus-codes">{t("menu.nav.bonus")}</NavLink>
			</MenuBlock>
			<MenuBlock>
				<PureButton onClick={logout}>{t("menu.nav.logout")}</PureButton>
			</MenuBlock>
		</React.Fragment>
	);
};
