import React, {useEffect} from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {IMenuController} from "data/stores/menu/menu.controller";
import {Bindings} from "data/constants/bindings";
import MenuGameLogo from "assets/img/icons/header-logo-white.png";
import {Exist} from "views/components/Exist";
import {MenuLogged} from "views/components/Menu/menuLogged.component";
import {MenuNonLogged} from "views/components/Menu/menuNonLogged.component";
import {useLocation} from "react-router";

const MenuBackdrop = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	background-color: transparent;
	transition: 0.25s background-color;

	&.open {
		z-index: 990;
		background-color: rgba(153, 153, 153, 0.6);
	}
`;

const LogoWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 4px 0 24px 28px;
	border-bottom: 1px solid ${({theme}) => theme.brandColor.dark};

	img {
		max-height: 56px;
		width: auto;
	}
`;

const MenuElement = styled.div`
	padding: 8px 20px 0 20px;
	z-index: 991;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: 100vh;
	transform: translateX(-100%);
	transition: 0.25s;
	width: 280px;
	background: ${({theme}) => theme.brandColor.black};

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	&.open {
		transform: translateX(0);
	}
`;

export const Menu: React.FC = observer(() => {
	const location = useLocation();
	const {menuClass, isLogged, closeMenu} = useViewController<IMenuController>(
		Bindings.MenuController
	);

	useEffect(() => {
		closeMenu();
	}, [location, closeMenu]);

	return (
		<React.Fragment>
			<MenuBackdrop className={menuClass} />
			<MenuElement className={menuClass}>
				<LogoWrapper>
					<img src={MenuGameLogo} alt="logo" />
				</LogoWrapper>
				<Exist when={isLogged}>
					<MenuLogged />
				</Exist>
				<Exist when={!isLogged}>
					<MenuNonLogged />
				</Exist>
			</MenuElement>
		</React.Fragment>
	);
});
