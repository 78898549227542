import React from "react";
import styled from "@emotion/styled";
import footerBackground from "assets/img/footer.svg";
import geniusLogo from "assets/img/icons/logo_genius.svg";
import {useTranslation} from "react-i18next";

const FooterElement = styled.footer`
	background: url(${footerBackground});
	background-size: cover;
	height: 144px;
	padding: 16px 24px;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const Rights = styled.div`
	p {
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.text.color.white};
	}
`;

const Links = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;

	a {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.text.color.white};
		text-transform: capitalize;
	}
`;

const GeniusLogo = styled.img`
	height: 32px;
	width: auto;
`;

export const Footer: React.FC = () => {
	const {t} = useTranslation();
	return (
		<FooterElement>
			<Links>
				<a href="https://www.xfl.com/terms-and-conditions" target="_blank" rel="noreferrer">
					{t("component.footer.terms")}
				</a>
				<a
					href="https://www.xfl.com/privacy-policy#:~:text=We%20collect%20your%20email%20address,websites%20and%20social%20media%20channels."
					target="_blank"
					rel="noreferrer">
					{t("component.footer.privacy")}
				</a>
			</Links>

			<Rights>
				<p>{t("component.footer.copyright_date")}</p>
				<p>{t("component.footer.copyright")}</p>
			</Rights>

			<GeniusLogo src={geniusLogo} alt="Genius Logo" />
		</FooterElement>
	);
};
