import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Empty} from "data/types/generics";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import {BOXES_URL, IS_POSTSEASON_CAN_BE_CLOSED} from "data/constants";

export interface IModalSeasonEndController extends ViewController {
	close: () => void;
	goToBingo: () => void;

	get isOpen(): boolean;

	get isLoading(): boolean;

	get isCloseAvailable(): boolean;
}

@injectable()
export class ModalSeasonEndController implements IModalSeasonEndController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.POST_SEASON;
	}

	get isCloseAvailable(): boolean {
		return IS_POSTSEASON_CAN_BE_CLOSED;
	}

	protected get user(): Empty<IUser> {
		return this._userStore.user;
	}

	dispose(): void {
		this._subscriptions$.forEach((disposer) => disposer());
	}

	init(param: void): void {
		this.checkModal();
		const subscription$ = reaction(
			() => [this.user, this._settingsStore.settings],
			() => this.checkModal()
		);
		this._subscriptions$.push(subscription$);
	}

	public close = () => {
		if (this._userStore.isAuthorized) {
			this._userStore.update({postseasonModal: true}).finally(this.onFinal.bind(this));
		}
		this._settingsStore.postSeasonModalShown();
		this._modalsStore.hideModal();
	};

	public goToBingo = () => {
		if (!this._userStore.isAuthorized) {
			window.location.href = BOXES_URL;
			return;
		}
		this._userStore
			.update({postseasonModal: true})
			.then(() => {
				window.location.href = BOXES_URL;
			})
			.finally(this.onFinal.bind(this));
	};

	protected checkModal() {
		this._settingsStore.checkPostSeasonModal();

		if (this.user?.postseasonModal === true) {
			return;
		}

		if (this.user?.postseasonModal === false) {
			this._modalsStore.showModal(ModalType.POST_SEASON);
			return;
		}
		if (this._settingsStore.isPostSeasonModalShouldBeShown) {
			this._modalsStore.showModal(ModalType.POST_SEASON);
		}
	}

	@action
	protected onFinal() {
		this._isLoading = false;
	}
}
