import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IServerAnswer} from "data/types/api";
import {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {IProp} from "data/types/entities";
import {PropStatus} from "data/enums";
import {formatPropTime} from "data/utils/helpers";

interface IParam {
	propId: number;
}

export interface IParlaySummaryItemController extends ViewController<IParam> {
	goToQuestion: () => void;

	get answer(): Empty<IServerAnswer>;

	get answered(): boolean;

	get prop(): Empty<IProp>;

	get propIndex(): number;

	get propsLength(): number;

	get isOpen(): boolean;

	get isLocked(): boolean;

	get isComplete(): boolean;

	get isCanceled(): boolean;

	get propLockDate(): string;

	get isNonOpen(): boolean;

	get isResulted(): boolean;

	get isCorrect(): boolean;

	get isIncorrect(): boolean;

	get squadId(): Empty<number>;

	get bet(): number;

	get multiplier(): number;
}

@injectable()
export class ParlaySummaryItemController implements IParlaySummaryItemController {
	@observable private _propId?: number;

	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get prop(): Empty<IProp> {
		if (!this._propId) return;

		return this._contestStore.getPropById(this._propId);
	}

	get answer(): Empty<IServerAnswer> {
		if (!this._propId) return;

		return this._answersStore.getAnswerById(this._propId);
	}

	get answered(): boolean {
		return Boolean(this.answer);
	}

	get propIndex(): number {
		return this._contestStore.getQuestionIndexById(this._propId);
	}

	get propsLength(): number {
		return this._contestStore.selectedContest?.props.length || 1;
	}

	get isOpen(): boolean {
		return this.prop?.status === PropStatus.Open;
	}

	get isLocked(): boolean {
		return this.prop?.status === PropStatus.Locked;
	}

	get isComplete(): boolean {
		return this.prop?.status === PropStatus.Resulted;
	}

	get isCanceled(): boolean {
		return this.prop?.status === PropStatus.Canceled;
	}

	get propLockDate(): string {
		return formatPropTime(this.prop);
	}

	get isNonOpen(): boolean {
		return this.isComplete || this.isLocked || this.isCanceled;
	}

	get isCorrect(): boolean {
		return Number(this.answer?.outcome) > 0;
	}

	get isIncorrect(): boolean {
		return this.answer?.outcome === 0;
	}

	get isResulted(): boolean {
		if (!this.answer) {
			return false;
		}
		return this.answer?.outcome !== null;
	}

	get squadId(): Empty<number> {
		return this.prop?.squadId;
	}

	get multiplier(): number {
		return this.prop?.multiplier || 2;
	}

	get bet(): number {
		return this.answer?.bet || 0;
	}

	public goToQuestion = (): void => {
		this._contestStore.currentSliderStep = this.propIndex;
	};

	dispose(): void {
		return;
	}

	init(param: IParam): void {
		this._propId = param.propId;
	}
}
