import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IJoinLeague} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import i18n from "i18next";
import type {ITitleStore} from "data/stores/title/title.store";

export interface ILeaguesJoinController extends ViewController {
	loadMore: () => void;

	get leagues(): IJoinLeague[];

	get isLoading(): boolean;

	get nextPage(): boolean;
}

@injectable()
export class LeaguesJoinController implements ILeaguesJoinController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._leaguesStore.isJoinLeaguesLoading;
	}

	get leagues(): IJoinLeague[] {
		return this._leaguesStore.joinLeagues;
	}

	get nextPage(): boolean {
		return this._leaguesStore.loadMoreMapper["joinLeagues"];
	}

	public loadMore = () => {
		void this._leaguesStore.fetchJoinLeaguesNext();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		void this._leaguesStore.fetchJoinLeagues();
		this._titleStore.title = i18n.t("title.leagues.join");
	}
}
