import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {LIVE_SCORING_FETCH_TIMING} from "data/constants";
import type {IChecksums} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {Empty} from "data/types/generics";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";

export interface ILiveScoringStore {
	subscribeLiveScoring: (location?: string) => void;
	unsubscribeLiveScoring: () => void;
}

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

@injectable()
export class LiveScoringStore implements ILiveScoringStore {
	@observable protected _interval: Empty<NodeJS.Timeout>;
	@observable protected _location: Empty<string>;

	@observable protected _isSubscribed: boolean = false;
	protected readonly _actions: IChecksumAction;
	@observable protected _checksums: IChecksums = {
		contests: null,
		settings: null,
	};

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	@action
	public subscribeLiveScoring(location?: string) {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;
		this._location = location;

		void this.fetchChecksums();
		this._interval = setInterval(() => {
			void this.fetchChecksums();
		}, LIVE_SCORING_FETCH_TIMING);
	}

	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;
		this._location = undefined;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	@action
	protected async fetchChecksums(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.checksums();
			this.compareChecksums(data);
		} catch (e) {
			console.error("Error while fetching checksums");
		}
	}

	@action
	protected compareChecksums(checksums: IChecksums): void {
		Object.keys(checksums).forEach((e) => {
			const key = e as keyof IChecksums;
			const oldChecksum = this._checksums[key];

			if (oldChecksum !== checksums[key] && oldChecksum !== null) {
				const action = this._actions[key];
				if (action && typeof action === "function") {
					void action();
				}
			}
		});
		this._checksums = checksums;
	}

	private generateActions(): IChecksumAction {
		return {
			contests: () => this.fetchContestsAction(),
			settings: () => this.fetchSettings(),
		};
	}

	private fetchContestsAction(): Promise<void> {
		return this._contestStore.fetchContests().then(() => {
			const contestId = this._contestStore.selectedContest?.id;

			if (this._location === "summary" && contestId) {
				void this._answersStore.fetchAnswersByContestId(contestId);
			}
			if (this._location === "dashboard") {
				void this._dashboardStore.fetchDashboard();
			}
		});
	}

	private fetchSettings() {
		return this._settingsStore.fetchSettings();
	}
}
