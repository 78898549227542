import styled from "@emotion/styled";
import {css} from "@emotion/react";

export const HeadingBase = css`
	font-family: "FK Grotesk Neue", sans-serif;
	font-style: normal;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	line-height: 104%;
`;

export const Heading1 = styled.h1`
	${HeadingBase};
	font-weight: 700;
	font-size: 88px;
	line-height: 94%;
	color: ${({theme}) => theme.text.color.black};
`;

export const Heading2 = styled.h2`
	${HeadingBase};
	font-weight: 700;
	font-size: 65px;
	color: ${({theme}) => theme.text.color.black};
`;

export const Heading3 = styled.h3`
	${HeadingBase};
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	color: ${({theme}) => theme.text.color.black};
`;

export const Heading4 = styled.h4`
	${HeadingBase};
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	color: ${({theme}) => theme.text.color.black};
`;

export const Heading5 = styled.h5`
	font-weight: 700;
	font-size: 26px;
	letter-spacing: -0.02em;
	${HeadingBase};
	color: ${({theme}) => theme.text.color.black};
`;

export const ErrorMessage = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	text-align: center;
	letter-spacing: 0.01em;
	color: ${({theme}) => theme.text.color.danger};
	margin-bottom: 28px;

	&.no-margin {
		margin-bottom: 0;
	}
`;

export const AccountSectionTitle = styled.h3`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: ${({theme}) => theme.text.color.black};
	margin-bottom: 12px;
`;

export const AccountSectionText = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 140%;
	letter-spacing: 0.01em;
	color: ${({theme}) => theme.text.color.black};
	margin-bottom: 12px;
`;

export const HelpTitle = styled.h3`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: ${({theme}) => theme.text.color.black};
	margin-bottom: 16px;
`;

export const HelpText = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 140%;
	color: ${({theme}) => theme.text.color.black};
	letter-spacing: 0.01em;
	margin-bottom: 16px;
`;
