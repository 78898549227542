import styled from "@emotion/styled";

export const LeagueItemBlock = styled.div`
	width: 100%;
	padding: 12px;
	background: ${({theme}) => theme.brandColor.black};
	border: 1px solid ${({theme}) => theme.brandColor.lightGrey};
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;

	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

export const LeagueItemContent = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
`;

export const LeagueItemTopBlock = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;

	img {
		margin-left: auto;
		max-height: 35px;
		width: auto;
		height: 100%;
	}

	> .content {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 4px;
	}
`;

export const LeagueItemTitle = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	color: ${({theme}) => theme.text.color.white};
`;
export const LeagueItemText = styled(LeagueItemTitle)`
	font-size: 11px;
	text-transform: capitalize;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
`;

export const CreateJoinBlock = styled.div`
	width: 100%;
	padding: 12px;
	background: ${({theme}) => theme.brandColor.black};
	border: 1px solid ${({theme}) => theme.brandColor.lightGrey};
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 15px;
		line-height: 140%;
		letter-spacing: 0.01em;
		color: ${({theme}) => theme.text.color.white};
		margin-bottom: 16px;

		&.title {
			text-transform: uppercase;
			margin-bottom: 4px;
		}
	}

	a {
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		border: 1px solid ${({theme}) => theme.text.color.white};
		text-transform: uppercase;

		&.secondary {
			margin-bottom: 8px;
		}
	}
`;
