import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IStaticStore} from "data/stores/static/static.store";
import {Empty} from "data/types/generics";
import {IAdvertisement} from "data/types/entities";

export interface IAdvertisementController extends ViewController {
	get advertisement(): Empty<IAdvertisement>;
}

@injectable()
export class AdvertisementController implements IAdvertisementController {
	constructor(@inject(Bindings.StaticStore) private _staticStore: IStaticStore) {
		makeAutoObservable(this);
	}

	get advertisement(): Empty<IAdvertisement> {
		return this._staticStore.advertisement;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
