import {makeAutoObservable, observable, toJS} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IContest, IDashboardContest, IProp} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {Empty} from "data/types/generics";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IDashboardStore} from "data/stores/dashboard/dashboard.store";
import {ContestStatus, ModalType} from "data/enums";
import {first} from "lodash";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

interface IParams {
	contestId: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IDashboardContestItemController extends ViewController<IParams> {
	setContestActive: () => void;

	navigateToPicks: () => void;

	get contestEntity(): Empty<IContest>;

	get isLive(): boolean;

	get isComplete(): boolean;

	get isOpen(): boolean;

	get loggedIn(): boolean;

	get contestLockTime(): string;
}

@injectable()
export class DashboardContestItemController implements IDashboardContestItemController {
	@observable private _navigate: Empty<IParams["navigate"]>;
	@observable private _contestId: number = 0;

	constructor(
		@inject(Bindings.DashboardStore) private _dashboardStore: IDashboardStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get contestEntity(): Empty<IContest> {
		if (!this._contestId) {
			return;
		}
		return this._contestStore.getContestById(this._contestId);
	}

	get isOpen(): boolean {
		return this.contestEntity?.status === ContestStatus.Open;
	}

	get loggedIn(): boolean {
		return this._userStore.isAuthorized;
	}

	get isLive(): boolean {
		return this.contestEntity?.status === ContestStatus.Live;
	}

	get isComplete(): boolean {
		return this.contestEntity?.status === ContestStatus.Complete;
	}

	get contestLockTime(): string {
		if (!this.contestEntity) {
			return "";
		}

		return this.firstProp?.lockDate ?? "";
	}

	protected get dashboardContest(): Empty<IDashboardContest> {
		return this._dashboardStore.dashboardInfo?.contests.find(
			(e) => e.contestId === this._contestId
		);
	}

	private get firstProp(): Empty<IProp> {
		const contest = toJS(this.contestEntity);
		return first(
			contest?.props.sort((propOne, propTwo) => {
				const startOne = new Date(propOne.lockDate).getTime();
				const startTwo = new Date(propTwo.lockDate).getTime();

				if (startOne === startTwo) {
					return 0;
				}

				return startOne > startTwo ? 1 : -1;
			})
		);
	}

	public setContestActive = () => {
		this._contestStore.setContestById(this._contestId);
	};

	public navigateToPicks = () => {
		if (!this.dashboardContest) return;

		this.checkTutorial();
		this.setContestActive();
		this.navigateToContest();
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
		this._navigate = param.navigate;
	}

	protected checkTutorial(): void {
		const user = this._userStore.user;
		const isCookieTutorialViewed = Cookies.get("isTutorialViewed");

		if (isCookieTutorialViewed) {
			this.setTutorialViewed();
			return;
		}

		if (user && !user.isTutorialViewed) {
			this.showTutorialModal();
			this.setTutorialViewed();
			return;
		}

		if (user?.isTutorialViewed) {
			this.setTutorialViewed();
			return;
		}

		if (!Cookies.get("isTutorialViewed")) {
			this.showTutorialModal();
			this.setTutorialViewed();
		}
	}

	protected setTutorialViewed(): void {
		Cookies.set("isTutorialViewed", "true", {expires: 1});

		if (this._userStore.user) {
			void this._userStore.setTutorialViewed();
		}
	}

	protected navigateToContest(): void {
		if (!this.dashboardContest) return;

		const contest = this.dashboardContest;
		if (contest.answers || this.isComplete) {
			this._navigate?.(`/picks/${contest.contestId}/summary`);
			return;
		}
		this._navigate?.("/picks");
	}

	private showTutorialModal(): void {
		this._modalsStore.showModal(ModalType.TUTORIAL);
	}
}
