import i18n from "i18next";
import {injectable} from "inversify";

export interface IAbstractApiProvider {
	get language(): string;
}

@injectable()
export class AbstractApiProvider implements IAbstractApiProvider {
	get language(): string {
		return i18n.language || "en";
	}
}
