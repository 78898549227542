import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {useNavigate} from "react-router-dom";
import type {Empty} from "data/types/generics";
import {ILeague} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType} from "data/enums";
import {noop} from "lodash";

interface IParams {
	navigate: ReturnType<typeof useNavigate>;
	code: string;
}

export interface IInvitePageController extends ViewController<IParams> {
	close: () => void;

	goToJoin: () => void;

	goToLeague: () => void;

	get isLogged(): boolean;

	get league(): Empty<ILeague>;

	get isJoined(): boolean;
}

@injectable()
export class InvitePageController implements IInvitePageController {
	@observable private _subscription$: IReactionDisposer[] = [];
	@observable private _navigate: Empty<ReturnType<typeof useNavigate>>;
	@observable private _code: string = "";
	@observable private _listenLoginMode: boolean = false;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLogged(): boolean {
		return this._userStore.isAuthorized;
	}

	get league(): Empty<ILeague> {
		return this._leaguesStore.myLeagues.find((e) => e.code === this._code);
	}

	get isJoined(): boolean {
		return Boolean(this.league?.isJoined);
	}

	dispose(): void {
		this._subscription$.forEach((disposer) => disposer());
	}

	init(param: IParams): void {
		this._navigate = param.navigate;
		this._code = param.code;

		this.fetchLeague();
		const sub$ = reaction(
			() => [this.isLogged, this._listenLoginMode],
			() => {
				if (this.isLogged && this._listenLoginMode) {
					this.navigateToJoinPage();
				}
			}
		);
		this._subscription$.push(sub$);
	}

	public close = (): void => {
		this._navigate?.("/");
	};

	public goToJoin = (): void => {
		if (!this.league) {
			this.close();
			return;
		}

		if (!this.isLogged) {
			this.openLoginModal();
			return;
		}

		this.navigateToJoinPage();
	};

	public goToLeague = (): void => {
		if (!this.league) {
			this.close();
			return;
		}

		this._navigate?.(`/leagues/view/${this.league.id}/standings`);
	};

	protected fetchLeague() {
		this._leaguesStore.fetchLeagueByCode(this._code).then(noop).catch(this.close.bind(this));
	}

	@action
	protected openLoginModal(): void {
		this._listenLoginMode = true;
		this._modalsStore.showModal(ModalType.LOGIN);
	}

	protected navigateToJoinPage(): void {
		if (!this.league) return;

		this._navigate?.(`/leagues/join/${this.league.code}`);
	}
}
