import React from "react";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IStaticStore} from "data/stores/static/static.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ILeaderboardCommonController, IRankingsRow, IRankingsUser} from "data/types/entities";
import {Empty} from "data/types/generics";
import {getImageFromBackend} from "data/utils/helpers";
import {ContestStatus} from "data/enums";
import {ISquad} from "data/types/api";

@injectable()
export class TeamLeaderboardController implements ILeaderboardCommonController {
	constructor(
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get primaryColor(): string {
		return this.squad?.color ?? "#121212";
	}

	get showNoData() {
		if (this.rankings.length === 0 && !this.isLoading) {
			return true;
		}

		const contest = this._contestStore.getContestById(this.selectedContestWeek);
		if (!contest) {
			return false;
		}

		return [ContestStatus.Open, ContestStatus.Live, ContestStatus.Draft].includes(
			contest.status
		);
	}

	get type(): string {
		return "team";
	}

	get squadImage(): string {
		if (!this.squad) {
			return "";
		}
		return getImageFromBackend(this.squad.logo);
	}

	get isCurrentUserInList(): boolean {
		return this.rankings.some((e) => e.userId === this._userStore.user?.id);
	}

	private get squad(): Empty<ISquad> {
		const user = this._userStore.user;
		return this._staticStore.getSquadById(Number(user?.favouriteTeam));
	}

	get isOverallSelect(): boolean {
		return this.selectedContestWeek === 0;
	}

	@observable private _selectedContestWeek: number = 0;

	get selectedContestWeek(): number {
		return this._selectedContestWeek;
	}

	get isLoading(): boolean {
		return this._rankingsStore.isLoading;
	}

	get contestsList() {
		return this._contestStore.contests
			.filter((e) => e.status === ContestStatus.Complete)
			.map((e) => e.id);
	}

	get nextPage(): boolean {
		return this._rankingsStore.teamNextPage;
	}

	get rankings(): IRankingsRow[] {
		return this._rankingsStore.teamTableData.rankings;
	}

	get userRow(): Empty<IRankingsUser> {
		return this._rankingsStore.teamTableData.user;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.fetchTeamLeaderboards();
	}

	public handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const value = event.target.value;
		if (value === undefined || value === null) {
			return;
		}

		this._selectedContestWeek = Number(value);
		this.fetchTeamLeaderboards();
	};

	public getUserClass = (userId: number): string => {
		return userId === this._userStore.user?.id ? "user" : "";
	};

	public getCelebrityClass = (isCelebrity: boolean): string => {
		return "";
	};

	public loadMore = (): void => {
		void this._rankingsStore.loadMoreTeamLeaderboard(this.selectedContestWeek);
	};

	protected fetchTeamLeaderboards(): void {
		void this._rankingsStore.fetchTeamLeaderboard(this.selectedContestWeek);
	}
}
