import type {Empty} from "data/types/generics";
import {injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {THTMLFormElements, ValidationScheme} from "data/types/validators";

export interface IFormValidator {
	get formErrors(): Record<string, Empty<string>>;

	get isValid(): boolean;

	validate(form: HTMLFormElement): boolean;

	enterScheme(scheme: ValidationScheme): void;

	clearError(name: string): void;
	clearErrors(): void;

	setError(name: string, errorMessage: string): void;
}

@injectable()
export class FormValidator implements IFormValidator {
	@observable private _scheme: Empty<ValidationScheme>;

	constructor() {
		makeAutoObservable(this);
	}

	@observable private _formErrors: Record<string, string> = {};

	get formErrors(): Record<string, Empty<string>> {
		return this._formErrors;
	}

	@observable private _isValid: boolean = true;

	get isValid(): boolean {
		return false;
	}

	@action
	public enterScheme(scheme: ValidationScheme): void {
		runInAction(() => {
			this._scheme = scheme;
		});
	}

	@action
	public clearError(name: string): void {
		runInAction(() => {
			delete this._formErrors[name];
		});
	}

	@action clearErrors(): void {
		this._formErrors = {};
	}

	@action
	public setError(name: string, errorMessage: string): void {
		runInAction(() => {
			this._formErrors[name] = errorMessage;
		});
	}

	validate(form: HTMLFormElement): boolean {
		let isValid = true;

		if (!this._scheme) {
			return true;
		}

		const keys = Object.keys(this._scheme);
		const fieldsArray = Array.from(form.getElementsByTagName("input"))
			.filter((e) => keys.includes(e.getAttribute("name") || ""))
			.map((element) => {
				return [element.getAttribute("name") || "", element];
			});

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const fields = Object.fromEntries(new Map(fieldsArray));

		keys.forEach((key) => {
			const validators = this._scheme![key];
			const field = fields[key];

			if (validators && field) {
				validators.forEach((validator) => {
					const isFormControlValid = validator.validate(field as THTMLFormElements);
					if (!isFormControlValid) {
						runInAction(() => {
							console.log(validator.getErrorMessage());
							this._formErrors[key] = validator.getErrorMessage();
						});
						isValid = false;
					}
				});
			}
		});

		runInAction(() => {
			this._isValid = isValid;
		});

		return isValid;
	}
}
