import {ViewController} from "data/types/structure";
import React from "react";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {IStaticStore} from "data/stores/static/static.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IFormValidator} from "data/utils/helpers/validators/FormValidator";
import type {IUpdateForm} from "data/types/forms";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IUserUpdatePayload} from "data/types/api";
import {ValidationScheme} from "data/types/validators";
import {PasswordValidator} from "data/utils/helpers/validators/PasswordValidator";
import {Empty} from "data/types/generics";
import {PostcodeValidator} from "data/utils/helpers/validators/Postcode.validator";
import {isBoolean, parseStringToBoolean} from "data/utils/helpers";
import {AxiosError} from "axios";
import {ModalType} from "data/enums";

export interface IFormChangeUserPasswordController extends ViewController {
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;

	handleFormSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;

	togglePasswordVisibility: () => void;

	toggleConfirmPasswordVisibility: () => void;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;

	get form(): IUpdateForm;

	get formErrors(): Record<string, Empty<string>>;

	get isLoading(): boolean;

	get error(): string | undefined;
}

@injectable()
export class FormChangeUserPasswordController implements IFormChangeUserPasswordController {
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
		postCode: [new PostcodeValidator()],
	};

	constructor(
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore,
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		this._formValidator.enterScheme(this._validationScheme);
		makeAutoObservable(this);
	}

	@observable private _error: string | undefined;

	get error(): string | undefined {
		return this._error;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _form: IUpdateForm = {
		password: "",
		confirmPassword: "",
	};

	get form(): IUpdateForm {
		return this._form;
	}

	get formErrors(): Record<string, Empty<string>> {
		return this._formValidator.formErrors;
	}

	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
		this.formChangeAction(event.target as unknown as HTMLInputElement);
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>): void => {
		event.preventDefault();
		event.stopPropagation();
		const isValid = this._formValidator.validate(event.currentTarget);

		if (!isValid) {
			return;
		}

		if (this.form.password !== this.form.confirmPassword) {
			this._formValidator.setError("confirmPassword", "Passwords don't match");
			return;
		}

		const payload: IUserUpdatePayload = {
			password: this.form.password,
		};

		this.changeLoadingState(true);

		this._userStore
			.update(payload)
			.then(this.onSuccess.bind(this))
			.catch((error: AxiosError<{errors: {message: string}[]}>) => {
				this._error = error.response?.data?.errors[0]?.message || error.message;
			})
			.finally(() => this.changeLoadingState(false));
	};

	@action
	public toggleConfirmPasswordVisibility = (): void => {
		runInAction(() => {
			this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
		});
	};

	@action
	public togglePasswordVisibility = (): void => {
		runInAction(() => {
			this._isPasswordVisible = !this._isPasswordVisible;
		});
	};

	@action
	public clearFormError = () => {
		runInAction(() => {
			this._error = undefined;
		});
	};

	@action
	public checkUsername = (): void => {
		this._userStore.checkUsername(this.form.username).then(console.log).catch(console.warn);
	};

	init(param: void): void {
		return;
	}

	dispose(): void {
		return;
	}

	@action
	private formChangeAction(target: HTMLInputElement) {
		const {name, value, checked, type} = target;

		const inputValue = type === "checkbox" ? checked : value;

		if (!name) {
			return;
		}
		const keyName = name as keyof IUpdateForm;

		runInAction(() => {
			this._form = {
				...this._form,
				[keyName]: this.parseChangeValue(inputValue),
			};
		});
		this.clearErrorFor(keyName);
		this.clearFormError();
	}

	private clearErrorFor(name: string) {
		this._formValidator.clearError(name);
	}

	private parseChangeValue<T>(value: T): T | boolean {
		if (isBoolean(value)) {
			return parseStringToBoolean(String(value));
		}
		return value;
	}

	private changeLoadingState(value: boolean): void {
		runInAction(() => {
			this._isLoading = value;
		});
	}

	private onSuccess(): void {
		this._form = {password: "", confirmPassword: ""};
		this._modalsStore.showModal(ModalType.SUCCESS, {
			title: "modal.password.title",
			message: "modal.password.message",
			buttonText: "modal.password.action",
		});
	}
}
