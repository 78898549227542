import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {INotificationService} from "data/services/notification.service";
import {ILeague} from "data/types/entities";
import {uniqBy} from "lodash";
import {RequestState} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import React from "react";
import {EMAIL_PATTERN} from "data/constants";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/api";

export interface IInviteLeagueController extends ViewController {
	sendEmailsRequest: () => void;
	share: () => void;
	handleEmailChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

	get isEmailInProcess(): boolean;

	get isEmailProcessed(): boolean;

	get isEmailsSendDisabled(): boolean;

	get emailString(): string;

	get areEmailsValid(): boolean;

	get league(): ILeague | undefined;
}

@injectable()
export class InviteLeagueController implements IInviteLeagueController {
	@observable private _inviteEmailsState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.NotificationService) private _notificationService: INotificationService
	) {
		makeAutoObservable(this);
	}

	@observable private _emailString: string = "";

	get emailString(): string {
		return this._emailString;
	}

	@observable private _areEmailsValid: boolean = true;

	get areEmailsValid() {
		return this._areEmailsValid;
	}

	get league() {
		return this._leaguesStore.selectedLeague;
	}

	get isEmailInProcess(): boolean {
		return this._inviteEmailsState === RequestState.PENDING;
	}

	get isEmailProcessed(): boolean {
		return this._inviteEmailsState === RequestState.SUCCESS;
	}

	get isEmailsSendDisabled(): boolean {
		return !this.emailString || this.isEmailInProcess || this.isEmailProcessed;
	}

	get _shareMessage() {
		return `Join this league and be a part of the fun! Elite team name is essential!`;
	}

	@action
	handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		this._emailString = event.currentTarget.value;
		if (!this.areEmailsValid) {
			this.setEmailsValid(true);
		}
	};

	@action
	sendEmailsRequest = () => {
		if (!this.league) return;

		this.setEmailsValid(true);
		const emailsArray = this.parseEmails();
		const hasInvalidEmails = emailsArray.some(
			(it) => it.length && !new RegExp(EMAIL_PATTERN).test(it)
		);

		if (hasInvalidEmails || emailsArray.length === 0) {
			this.setEmailsValid(false);
			return;
		}

		const identityEmails = uniqBy(
			emailsArray.filter(Boolean).map((email) => ({
				email,
			})),
			"email"
		);

		this.setEmailsRequestState(RequestState.PENDING);
		this._leaguesStore
			.inviteLeague({
				league_id: this.league.id,
				emails: identityEmails,
			})
			.then((_e) => {
				this.setEmailsRequestState(RequestState.SUCCESS);
				this.showSuccessNotification();
			})
			.catch((e) => {
				const error = e as AxiosError<IAxiosApiError, unknown>;
				this._modalsStore.showAxiosError(error);
				this.setEmailsRequestState(RequestState.ERROR);
			})
			.finally(() => {
				setTimeout(() => {
					this.setEmailsRequestState(RequestState.IDLE);
				}, 2000);
			});
	};

	share() {
		return;
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	@action
	private setEmailsRequestState(state: RequestState) {
		runInAction(() => {
			this._inviteEmailsState = state;
		});
	}

	private setEmailsValid(isValid: boolean) {
		runInAction(() => {
			this._areEmailsValid = isValid;
		});
	}

	private parseEmails(): string[] {
		const emailsByNewLine = this.emailString.replace(/\n/g, ",").replace(/,+/, ",");
		return emailsByNewLine.split(",").map((it) => it.trim());
	}

	private showSuccessNotification() {
		this._notificationService.showNotification({
			text: "notification.email.success",
			timeToShow: 5000,
			cancellable: true,
		});
	}
}
