import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {IAnswerBase, IServerAnswer} from "data/types/api";
import {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {IProp} from "data/types/entities";
import {PropStatus} from "data/enums";
import {formatPropTime} from "data/utils/helpers";
import {IParlaySummaryItemController} from "views/components/ParlaySummaryItem/parlaySummaryItem.controller";

interface IParam {
	propId: number;
}

@injectable()
export class ParlayItemController implements IParlaySummaryItemController {
	@observable private _propId?: number;

	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get prop(): Empty<IProp> {
		if (!this._propId) return;

		return this._contestStore.getPropById(this._propId);
	}

	get answer(): Empty<IServerAnswer> {
		if (!this._propId) return;

		if (!this.parlayAnswer) {
			return this._answersStore.getAnswerById(this._propId);
		}

		return this.parlayAnswer;
	}

	private get parlayAnswer(): Empty<IAnswerBase> {
		if (!this._propId) return;

		return this._answersStore.getParlayAnswerById(this._propId);
	}

	get answered(): boolean {
		return Boolean(this.parlayAnswer);
	}

	get propIndex(): number {
		return this._contestStore.getQuestionIndexById(this._propId);
	}

	get propsLength(): number {
		return this._contestStore.selectedContest?.props.length || 1;
	}

	get isOpen(): boolean {
		return this.prop?.status === PropStatus.Open;
	}

	get isLocked(): boolean {
		return this.prop?.status === PropStatus.Locked;
	}

	get isComplete(): boolean {
		return this.prop?.status === PropStatus.Resulted;
	}

	get isCanceled(): boolean {
		return this.prop?.status === PropStatus.Canceled;
	}

	get propLockDate(): string {
		return formatPropTime(this.prop);
	}

	get isNonOpen(): boolean {
		return this.isComplete || this.isLocked;
	}

	get isCorrect(): boolean {
		if (!this.prop?.correct) {
			return false;
		}
		return this.prop?.correct === this.parlayAnswer?.pick;
	}

	get isIncorrect(): boolean {
		if (!this.prop?.correct) {
			return false;
		}
		return this.prop?.correct !== this.parlayAnswer?.pick;
	}

	get isResulted(): boolean {
		if (!this.parlayAnswer) {
			return false;
		}
		return Boolean(this.prop?.correct);
	}

	get squadId(): Empty<number> {
		return this.prop?.squadId;
	}

	get bet(): number {
		return this.answer?.bet ?? 0;
	}

	get multiplier(): number {
		return this.prop?.multiplier ?? 2;
	}

	public goToQuestion = (): void => {
		this._contestStore.currentSliderStep = this.propIndex;
	};

	dispose(): void {
		return;
	}

	init(param: IParam): void {
		this._propId = param.propId;
	}
}
