import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {SummaryTab} from "data/enums";
import React from "react";
import {AxiosError} from "axios";
import type {IAxiosApiError, IParlayEntity, IServerAnswer} from "data/types/api";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {Empty} from "data/types/generics";
import {IContest, IProp, IUserPicksRowProps} from "data/types/entities";

interface IParams {
	userId: number;
	contestId: number;
}

export interface IRankingsPicksController extends ViewController<IParams> {
	changeTab: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	getIsTabActive: (tab: SummaryTab) => boolean;

	get picks(): IUserPicksRowProps[];

	get parlayPicks(): IUserPicksRowProps[];

	get isLoading(): boolean;

	get activeTab(): SummaryTab;

	get oddsCount(): number;

	get isParlayCorrect(): boolean;

	get hasNoParlay(): boolean;

	get hasNoData(): boolean;
}

@injectable()
export class RankingsPicksController implements IRankingsPicksController {
	@observable _contestParlay: IParlayEntity = {
		picks: [],
		outcome: null,
	};
	@observable private _contestId: number = 0;
	@observable private _answers: IServerAnswer[] = [];

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	@observable private _activeTab: SummaryTab = SummaryTab.Picks;

	get activeTab(): SummaryTab {
		return this._activeTab;
	}

	get picks(): IUserPicksRowProps[] {
		return this._answers.map((e) => {
			const prop = this.getPropById(e.questionId);

			return {
				key: e.questionId,
				answer: e.pick,
				bet: e.bet,
				correct: Boolean(e.outcome),
				title: prop?.title || "-",
				squadId: prop?.squadId,
			};
		});
	}

	get parlayPicks(): IUserPicksRowProps[] {
		if (!this._contestParlay.picks) {
			return [];
		}

		return this._contestParlay.picks.map((e) => {
			const prop = this.getPropById(e.questionId);

			return {
				key: e.questionId,
				answer: e.pick,
				bet: e.bet,
				correct: prop?.correct === e.pick,
				title: prop?.title || "-",
				squadId: prop?.squadId,
			};
		});
	}

	@observable private _isLoading: boolean = true;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get oddsCount(): number {
		return Math.pow(2, this.parlayPicks.length);
	}

	get isParlayCorrect(): boolean {
		return this.parlayPicks.every((e) => e.correct);
	}

	get hasNoParlay(): boolean {
		return this.parlayPicks.length === 0;
	}

	get hasNoData(): boolean {
		return this.hasNoParlay && this.hasNoPicks;
	}

	protected get contest(): Empty<IContest> {
		return this._contestStore.getContestById(this._contestId);
	}

	protected get hasNoPicks(): boolean {
		return this.picks.length === 0;
	}

	public getIsTabActive = (tab: SummaryTab): boolean => {
		return this.activeTab === tab;
	};

	@action
	public changeTab = (event: React.SyntheticEvent<HTMLButtonElement, Event>): void => {
		const tab = event.currentTarget.dataset.tab as SummaryTab;

		if (!tab) {
			return;
		}

		this._activeTab = tab;
	};

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
		void this.fetchPicks(param.userId, param.contestId);
	}

	@action
	protected async fetchPicks(userId: number, contestId: number): Promise<void> {
		try {
			this._isLoading = true;
			const response = await this._rankingsStore.fetchUserPicks(userId, contestId);
			const {items, parlay} = response.data.success;

			runInAction(() => {
				this._answers = items;
				this._contestParlay = parlay || {picks: [], outcome: null};
			});
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		} finally {
			this._isLoading = false;
		}
	}

	protected getPropById(id: number): Empty<IProp> {
		return this.contest?.props.find((e) => e.id === id);
	}
}
