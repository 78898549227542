import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {ModalType} from "data/enums";

interface IModalContent {
	contestId: number;
}

export interface IModalLeaveContestController extends ViewController {
	onChangeConfirm: () => void;
	onConfirm: () => void;
	onClose: () => void;

	get isBlocked(): boolean;

	get isOpen(): boolean;
}

@injectable()
export class ModalLeaveContestController implements IModalLeaveContestController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.CHANGE_CONTEST;
	}

	get isBlocked(): boolean {
		return this._answersStore.answersChanged;
	}

	onClose = (): void => {
		this._modalsStore.hideModal();
	};

	public onChangeConfirm = () => {
		const content = this._modalsStore.modalContent as IModalContent;
		this._contestStore.setContestById(content.contestId);
		this.onConfirm();
	};

	public onConfirm = () => {
		const ids = this._contestStore.nonLockedPropIds;
		this._answersStore.clearAnswers(ids, true);
		this._answersStore.answersChanged = false;
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
