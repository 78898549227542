import React from "react";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, PropStatus} from "data/enums";

export interface ISliderStepsController extends ViewController {
	onStepChange: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	handleStepChange: (step: number) => void;

	getIsStepActive: (step: number) => string;

	getIsStepViewed: (step: number) => string;

	getIsStepAnswered: (step: number) => string;

	getIsStepCanceled: (step: number) => string;

	get currentStep(): number;

	get stepsCount(): number;

	get isNonPicksRange(): boolean;

	get cantGoNext(): boolean;
}

@injectable()
export class SliderStepsController implements ISliderStepsController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get currentStep(): number {
		return this._contestStore.currentSliderStep;
	}

	get stepsCount(): number {
		return (this._contestStore.selectedContest?.props ?? []).length;
	}

	get isNonPicksRange(): boolean {
		return this.currentStep >= this.stepsCount;
	}

	get cantGoNext(): boolean {
		const fullAnswers = this._answersStore.answers.filter((e) => Boolean(e.bet));
		return this.currentStep === this.stepsCount - 1 && !fullAnswers.length;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public onStepChange = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
		const index = Number(event.currentTarget.dataset.index);
		this.handleStepChange(index);
	};

	public handleStepChange = (step: number): void => {
		if (step === null || step === undefined) {
			return;
		}

		const isModalOpened = this.checkIsNoBetModalShouldBeOpen(this.currentStep, step);
		if (isModalOpened) {
			return;
		}

		this._contestStore.currentSliderStep = step;
	};

	getIsStepActive = (step: number) => {
		return step === this.currentStep ? "active" : "";
	};

	getIsStepViewed = (step: number) => {
		return step < this.currentStep ? "viewed" : "";
	};

	getIsStepAnswered = (step: number) => {
		const prop = this._contestStore.selectedContest?.props[step];
		const answer = this._answersStore.getAnswerById(prop?.id ?? 0);

		return answer ? "answered" : "";
	};

	getIsStepCanceled = (step: number) => {
		const prop = this._contestStore.selectedContest?.props[step];

		return prop?.status === PropStatus.Canceled ? "canceled" : "";
	};

	private checkIsNoBetModalShouldBeOpen(step: number, nextStep: number): boolean {
		const property = this._contestStore.getQuestionByIndex(step);
		if (!property || property.status !== PropStatus.Open) {
			return false;
		}
		const answer = this._answersStore.getAnswerById(property.id);
		if (!answer || Number(answer.bet) > 0) {
			return false;
		}
		this._modalsStore.showModal(ModalType.NO_PICKS_BET, {prev: step, next: nextStep, answer});
		return true;
	}
}
