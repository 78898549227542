import React, {useEffect, useState} from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {Preloader} from "views/components/Preloader/preloader.component";
import {JSON_URL} from "data/constants";
import {observer} from "mobx-react";

interface IProps {
	children: React.ReactNode;
}

export const HOCi18n: React.FC<IProps> = observer(({children}) => {
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (window?.location?.search?.includes("skipTranslation=true")) {
			setInitialized(true);

			return;
		}

		void i18n
			.use(LanguageDetector)
			.use(Backend)
			.use(initReactI18next)
			.init(
				{
					/**
					 * i18nextBrowserLanguageDetector
					 */
					detection: {
						order: ["navigator"],
					},
					supportedLngs: ["en"],
					backend: {
						crossDomain: true,
						loadPath: `${JSON_URL}loco/{{lng}}.json`,
					},
					fallbackLng: "en",
					debug: false,
					keySeparator: ".",
					interpolation: {
						escapeValue: false,
						formatSeparator: ",",
					},
				},
				() => {
					setInitialized(true);
				}
			);
	}, []);

	return initialized ? <React.Fragment>{children}</React.Fragment> : <Preloader fixed />;
});
