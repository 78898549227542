import React from "react";
import {CircularProgress} from "@mui/material";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	&.fixed {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: ${({theme}) => theme.text.color.white};
		z-index: 1000;
	}

	svg {
		color: ${({theme}) => theme.brandColor.black};
	}

	&.button {
		svg {
			transform: scale(0.6);
		}
	}
`;

interface IProps {
	fixed?: boolean;
	button?: boolean;
}

export const Preloader: React.FC<IProps> = ({fixed, button}) => {
	const fixedClass = fixed ? "fixed" : "";
	const buttonClass = button ? "button" : "";
	return (
		<Wrapper className={`${fixedClass} ${buttonClass} preloader`}>
			<CircularProgress />
		</Wrapper>
	);
};
