import {IAxiosApiError, IAxiosErrorMessageObject} from "data/types/api";
import {AxiosError} from "axios";
import {Empty, NullOrEmpty} from "data/types/generics";
import {DEFAULT_WAGER, IMAGES_URL, PROP_TIME_FORMAT, SPONSORED_URL} from "data/constants";
import {IProp} from "data/types/entities";
import {DateTime} from "luxon";

export function getClassNameAccordingStatement(className: string, statement: boolean): string {
	return statement ? className : "";
}

export function getEmptyStringValue<T>(value: NullOrEmpty<T>): string {
	return getDashedValue(value, "");
}

export function getDashedValue<T>(value: NullOrEmpty<T>, replacer = "-"): string {
	if (value === null || value === undefined) {
		return replacer;
	}
	return String(value);
}

export function getNonUndefinedValue<T>(value: NullOrEmpty<T>) {
	if (value === undefined || value === null) {
		return 0;
	}
	return value;
}

export function isBoolean<T>(value: T): boolean {
	return value === "true" || value === "false";
}

export function parseStringToBoolean(value: string): boolean {
	return value === "true";
}

export function getErrorMessageFromAxiosResponse(event: AxiosError<IAxiosApiError, unknown>) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

export function getImageFromBackend(image: string): string {
	return IMAGES_URL + image;
}

export function getSponsoredImageFromBackend(id: number): string {
	return `${SPONSORED_URL}${id}.png`;
}

export function formatPropTime(prop: Empty<IProp>): string {
	if (!prop) {
		return "-";
	}

	return DateTime.fromISO(prop.lockDate).toFormat(PROP_TIME_FORMAT).toString();
}

export function getOddsAccordingMultiplier(multiplier: number): number {
	if (!multiplier || multiplier < 4) {
		return 0;
	}
	return multiplier * 100 - 100;
}

export function getParlayPayoutAccordingMultiplier(multiplier: number): number {
	if (!multiplier || multiplier < 4) {
		return DEFAULT_WAGER;
	}
	return multiplier * DEFAULT_WAGER;
}

export function getIsIOS() {
	if (!navigator) {
		return false;
	}
	return (
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
			navigator.platform
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
}

export function getPropCardClasses(
	isPropCancelled: boolean,
	isQuestionAnswered: boolean,
	sliderValue: number
) {
	const canceledClass = isPropCancelled ? "canceled" : "";
	const answeredClass = isQuestionAnswered ? "answered" : "";
	const sliderValueClass = sliderValue > 0 ? "selected" : "";

	return {canceledClass, answeredClass, sliderValueClass};
}

export function withTimeStamp(path: string, delimiter = "?"): string {
	const timestamp = new Date().getTime();
	return `${path}${delimiter}=${timestamp}`;
}
