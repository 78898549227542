import {ViewController} from "data/types/structure";
import {HelpSection} from "data/enums";
import {injectable} from "inversify";
import {Empty} from "data/types/generics";

export interface IHelpNavigationController extends ViewController {
	getIsParamCorrect: (param: Empty<string>) => boolean;
}

@injectable()
export class HelpNavigationController implements IHelpNavigationController {
	public getIsParamCorrect(param: Empty<string>): boolean {
		return [
			HelpSection.HowToPlay,
			HelpSection.FAQs,
			HelpSection.Terms,
			HelpSection.ContactUs,
		].some((e) => e === param);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
