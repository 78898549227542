import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ILeague} from "data/types/entities";
import type {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ITitleStore} from "data/stores/title/title.store";
import type {IUserStore} from "data/stores/user/user.store";

interface IParams {
	leagueId: number;
}

export interface IViewLeagueOutletController extends ViewController<IParams> {
	get league(): Empty<ILeague>;
	get isCommissioner(): boolean;
}

@injectable()
export class ViewLeagueOutletController implements IViewLeagueOutletController {
	@observable private _leagueId: Empty<number>;
	@observable private _subscription$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore
	) {
		makeAutoObservable(this);
	}

	get league(): Empty<ILeague> {
		return this._leaguesStore.selectedLeague;
	}

	get isCommissioner(): boolean {
		if (!this.league) return false;

		return this.league?.userId === this._userStore.user?.id;
	}

	dispose(): void {
		this._subscription$.forEach((subscription) => subscription());
		this._leaguesStore.clearSelectedLeague();
	}

	init(param: IParams): void {
		this._leagueId = param.leagueId;
		if (this._leagueId && !this.league) {
			this.fetchLeagueById();
		}

		this._leaguesStore.setSelectedLeagueById(param.leagueId);

		const dispose1 = reaction(
			() => this._leaguesStore.myLeagues,
			() => {
				if (this._leaguesStore.selectedLeague) return;

				this._leaguesStore.setSelectedLeagueById(param.leagueId);
			}
		);

		this.setLeagueName();
		const dispose2 = reaction(
			() => this._leaguesStore.selectedLeague,
			() => this.setLeagueName()
		);
		this._subscription$.push(dispose1);
		this._subscription$.push(dispose2);
	}

	private fetchLeagueById(): void {
		void this._leaguesStore.fetchLeagueById(this._leagueId!);
	}

	private setLeagueName(): void {
		if (this._leaguesStore.selectedLeague) {
			this._titleStore.title = this._leaguesStore.selectedLeague.name;
		}
	}
}
