import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ILeagueUser} from "data/types/entities";
import {ModalType} from "data/enums";

export interface ILeagueUsersController extends ViewController {
	fetchUsers: () => void;

	getIsCurrentUser: (userId: number) => boolean;
	getUserClass: (userId: number) => string;

	removeUser: (userId: number) => void;

	loadMore: () => void;

	get users(): ILeagueUser[];

	get isLoading(): boolean;

	get nextPage(): boolean;
}

@injectable()
export class LeagueUsersController implements ILeagueUsersController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get users(): ILeagueUser[] {
		return this._leaguesStore.leagueUsers;
	}

	get nextPage(): boolean {
		return this._leaguesStore.loadMoreMapper.leagueUsers;
	}

	@action
	public fetchUsers = (): void => {
		this._isLoading = true;
		this._leaguesStore.fetchLeagueUsers().finally(() => (this._isLoading = false));
	};

	public getIsCurrentUser = (userId: number): boolean => {
		if (!this._userStore.user) {
			return false;
		}
		return this._userStore.user?.id === userId;
	};

	public getUserClass = (userId: number): string => {
		return this.getIsCurrentUser(userId) ? "user" : "";
	};

	public removeUser = (userId: number): void => {
		this._modalsStore.showModal(ModalType.REMOVE_LEAGUE_USER, {userId});
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.fetchUsers();
	}

	public loadMore = (): void => {
		this._isLoading = true;
		this._leaguesStore.fetchLeagueUsersLoadMore().finally(() => {
			this._isLoading = false;
		});
	};
}
