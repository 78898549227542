import {ViewController} from "data/types/structure";
import {IContest} from "data/types/entities";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import {Bindings} from "data/constants/bindings";
import {Empty} from "data/types/generics";
import {SelectChangeEvent} from "@mui/material";
import {ContestStatus, ModalType} from "data/enums";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IRoundSelectorController extends ViewController {
	openDropdown: () => void;
	closeDropdown: () => void;
	handleContestChange: (event: SelectChangeEvent<number>) => void;

	get selectedContest(): Empty<IContest>;

	get isOpen(): boolean;

	get isDropdownCanBeOpen(): boolean;

	get contests(): IContest[];

	get isContestLive(): boolean;

	get isContestComplete(): boolean;

	get isContestScheduled(): boolean;
}

@injectable()
export class RoundSelectorController implements IRoundSelectorController {
	constructor(
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isContestScheduled(): boolean {
		return this.selectedContest?.status === ContestStatus.Open;
	}

	get isContestLive(): boolean {
		return this.selectedContest?.status === ContestStatus.Live;
	}

	get isContestComplete(): boolean {
		return this.selectedContest?.status === ContestStatus.Complete;
	}

	@observable private _isOpen: boolean = false;

	get isOpen(): boolean {
		return this._isOpen;
	}

	get selectedContest(): Empty<IContest> {
		return this._contestStore.selectedContest;
	}

	get isDropdownCanBeOpen(): boolean {
		return this.contests.length > 1;
	}

	get contests(): IContest[] {
		return this._contestStore.availableContests;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action
	public closeDropdown = (): void => {
		this._isOpen = false;
	};

	@action
	public openDropdown = (): void => {
		this._isOpen = true;
	};

	public handleContestChange = (event: SelectChangeEvent<number>): void => {
		const contestId = Number(event.target.value);
		if (!contestId) {
			return;
		}

		if (this._answersStore.answersChanged) {
			this._modalsStore.showModal(ModalType.CHANGE_CONTEST, {contestId});
			return;
		}

		this._contestStore.setContestById(contestId);
		void this._answersStore.fetchAnswersByContestId(contestId);
	};
}
