import React, {useEffect} from "react";
import {
	ModalButton,
	ModalCloseButton,
	ModalContent,
	ModalText,
	ModalTitle,
} from "views/components/modals/common";
import {IconButton, InputAdornment, Modal} from "@mui/material";
import {ReactComponent as IconReset} from "assets/img/icons/reset.svg";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {useViewController} from "data/services/locator";
import {IModalResetPasswordController} from "views/components/modals/ModalResetPassword/modalResetPassword.controller";
import {Bindings} from "data/constants/bindings";
import {ErrorMessage, Input} from "views/components/common";
import {observer} from "mobx-react";
import {ReactComponent as EyeIcon} from "assets/img/icons/eye.svg";
import {useNavigate} from "react-router-dom";
import {Exist} from "views/components/Exist";
import {useTranslation} from "react-i18next";
import {TRANSLATION_PASSWORD} from "data/constants";

export const ModalResetPassword: React.FC = observer(() => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const {
		isOpen,
		close,
		formErrors,
		form,
		isConfirmPasswordVisible,
		isPasswordVisible,
		isLoading,
		isSuccess,
		error,
		handleFormSubmit,
		togglePasswordVisibility,
		toggleConfirmPasswordVisibility,
		handleFormChange,
	} = useViewController<IModalResetPasswordController>(Bindings.ModalResetPasswordController);

	useEffect(() => {
		if (isSuccess) {
			navigate("/login");
		}
	}, [isSuccess, navigate]);

	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<ModalCloseButton onClick={close}>
					<IconClose />
				</ModalCloseButton>
				<IconReset />

				<ModalTitle>{t("modal.reset.title")}</ModalTitle>
				<ModalText>{t("modal.reset.text")}</ModalText>

				<Exist when={Boolean(error)}>
					<ErrorMessage className="no-margin">{error}</ErrorMessage>
				</Exist>

				<form onSubmit={handleFormSubmit} onChange={handleFormChange}>
					<Input
						name="password"
						label={t(TRANSLATION_PASSWORD)}
						placeholder={String(t(TRANSLATION_PASSWORD))}
						type={isPasswordVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={togglePasswordVisibility}
										edge="end">
										<EyeIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
						required
						value={form.password}
						InputLabelProps={{shrink: true}}
						helperText={t("form.common.password_requirements")}
						error={Boolean(formErrors["password"])}
					/>
					<Input
						name="confirmPassword"
						label={t("form.common.password_confirm")}
						placeholder={String(t(TRANSLATION_PASSWORD))}
						required
						value={form.confirmPassword}
						InputLabelProps={{shrink: true}}
						type={isConfirmPasswordVisible ? "text" : "password"}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={toggleConfirmPasswordVisibility}
										edge="end">
										<EyeIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
						error={Boolean(formErrors["confirmPassword"])}
						helperText={formErrors["confirmPassword"]}
					/>
					<ModalButton type="submit" fullWidth disabled={isLoading}>
						{t("modal.reset.action")}
					</ModalButton>
				</form>
			</ModalContent>
		</Modal>
	);
});
