import React from "react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ModalError} from "views/components/modals/ModalError/modalError.component";
import {ModalSuccess} from "views/components/modals/ModalSuccess/modalSuccess.component";
import {ModalResetPassword} from "views/components/modals/ModalResetPassword/modalResetPassword.component";
import {ModalTutorial} from "views/components/modals/ModalTutorial/modalTutorial.component";
import {IHOCMainController} from "views/components/HOCMain/HOCMain.controller";
import {Preloader} from "views/components/Preloader/preloader.component";
import {observer} from "mobx-react";
import {ModalSeasonEnd} from "views/components/modals/ModalSeasonEnd/modalSeasonEnd.component";

const Main = styled.main`
	// 56px - header, 144px - footer
	min-height: calc(100vh - 56px - 144px);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	background: #fafafa;
	position: relative;
`;

interface IProps {
	children: React.ReactNode;
}

export const HOCMain: React.FC<IProps> = observer(({children}) => {
	const {isLoadingUser} = useViewController<IHOCMainController>(Bindings.HOCMainController);
	if (isLoadingUser) {
		return <Preloader fixed={true} />;
	}

	return (
		<Main>
			<React.Fragment>{children}</React.Fragment>
			<ModalError />
			<ModalSuccess />
			<ModalResetPassword />
			<ModalTutorial />
			<ModalSeasonEnd />
		</Main>
	);
});
