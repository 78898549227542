import type {
	IAnswerBase,
	IAnswersPayload,
	IAxiosApiError,
	IParlayEntity,
	IParlayPayload,
	IServerAnswer,
	IServerAnswersResponse,
} from "data/types/api";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {Empty, Nullable, NullOrEmpty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import type {IAnswersApiProvider} from "data/providers/api/answers.api.provider";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IParlayPrefillService} from "data/services/parlayPrefill.service";
import {DEFAULT_CHIPS_COUNT} from "data/constants";
import {ModalType, PropStatus} from "data/enums";

import {IProp} from "data/types/entities";

export interface IAnswersStore {
	getIsQuestionAnswered(questionId: number): boolean;

	getAnswerById(questionId: Nullable<number>): Empty<IServerAnswer>;

	getParlayAnswerById(questionId: Nullable<number>): Empty<IAnswerBase>;

	answerQuestion(answer: IAnswerBase, emit?: boolean): void;

	clearAnswerById(questionId: NullOrEmpty<number>): void;

	clearAnswers(ids: number[], all?: boolean): void;

	fetchAnswersByContestId(contestId: number): Promise<void>;

	saveAnswers(contestId: number): Promise<void>;

	saveParlayForContest(contestId?: number): Promise<void>;

	clearEmptyAnswers(): void;

	getSaveAnswersPayload(): IAnswersPayload;

	addToParlay(prop: Empty<IProp>): void;

	removeFromParlay(prop: Empty<IProp>): IAnswerBase | undefined;

	get answers(): IServerAnswer[];

	get parlayAnswers(): IAnswerBase[];

	get answersLength(): number;

	get availableFootballs(): number;

	get earnedFootballs(): number;

	get parlayIds(): number[];

	get isParlayAvailable(): boolean;

	get isParlayWagerOpened(): boolean;

	get isSaving(): boolean;

	get answersChanged(): boolean;

	set answersChanged(value: boolean);

	get userHasAnswers(): boolean;

	get userHasSavedParlay(): boolean;
}

@injectable()
export class AnswersStore implements IAnswersStore {
	@observable _contestParlay: IParlayEntity = {
		picks: [],
		outcome: null,
	};
	@observable private _serverAnswers: Empty<IServerAnswersResponse>;
	@observable private _serverChips: number = DEFAULT_CHIPS_COUNT;

	constructor(
		@inject(Bindings.AnswersApiProvider) private _answersProvider: IAnswersApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ParlayPrefillService) private _parlayPrefillService: IParlayPrefillService,
		// Please do not include AnswersStore to ContestsStore to avoid circular dependency
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get userHasSavedParlay(): boolean {
		return Boolean(this._serverAnswers?.parlay);
	}

	@observable private _userHasAnswers: boolean = false;

	get userHasAnswers(): boolean {
		return this._userHasAnswers;
	}

	@observable private _answersChanged: boolean = false;

	get answersChanged(): boolean {
		return this._answersChanged;
	}

	set answersChanged(value: boolean) {
		this._answersChanged = value;
	}

	get parlayAnswers(): IAnswerBase[] {
		return this._contestParlay.picks ?? [];
	}

	@observable private _isSaving: boolean = false;

	get isSaving(): boolean {
		return this._isSaving;
	}

	@observable private _isParlayWagerOpened: boolean = false;

	get isParlayWagerOpened(): boolean {
		return this._isParlayWagerOpened;
	}

	get parlayIds(): number[] {
		return this._contestParlay.picks?.map((e) => e.questionId) ?? [];
	}

	@observable private _availableFootballs: number = DEFAULT_CHIPS_COUNT;

	get availableFootballs(): number {
		return this._availableFootballs;
	}

	private set availableFootballs(value: number) {
		this._availableFootballs = value;
	}

	@observable private _answers: IServerAnswer[] = [];

	get answers(): IServerAnswer[] {
		return this._answers;
	}

	get answersLength(): number {
		const props = this._contestStore.currentContestProps;
		const answers = this._answers.filter((e) => (e.bet ?? 0) > 0).map((e) => e.id);

		return props.filter((e) => answers.includes(e.id) && e.status !== PropStatus.Canceled)
			.length;
	}

	get earnedFootballs(): number {
		const picksFootballs = this._answers.reduce((acc, value) => acc + (value.outcome || 0), 0);
		const parlayFootballs = this._contestParlay.outcome ?? 0;
		return picksFootballs + parlayFootballs;
	}

	get isParlayAvailable(): boolean {
		return this.answersWithBet.length > 0;
	}

	private get answersWithBet(): IServerAnswer[] {
		return this.answers.filter((e) => (e.bet ?? 0) > 0);
	}

	private get totalAnswersFootballs() {
		return this.answers.reduce((acc, value) => acc + (value.bet || 0), 0);
	}

	public addToParlay(prop: Empty<IProp>): void {
		const payload = this.removeFromParlay(prop);
		if (!payload) {
			return;
		}
		this._contestParlay.picks = [...(this._contestParlay.picks ?? []), payload];
	}

	public removeFromParlay(prop: Empty<IProp>): IAnswerBase | undefined {
		if (!prop) {
			return;
		}
		const answer = this.getAnswerById(prop.id);

		if (!answer) {
			return undefined;
		}

		const payload: IAnswerBase = {
			questionId: prop.id,
			pick: answer.pick,
		};

		this._contestParlay.picks = this._contestParlay.picks?.filter(
			(e) => e.questionId !== payload.questionId
		);
		return payload;
	}

	@action
	answerQuestion(answer: IAnswerBase, emit = false): void {
		const answerIndex = this._answers.findIndex((e) => e.questionId === answer.questionId);
		if (answerIndex === -1) {
			this._answers.push(answer);
		} else {
			const storeQuestion = this._answers[answerIndex];
			this._answers[answerIndex] = {
				...storeQuestion,
				bet: answer.bet ?? storeQuestion.bet,
				pick: answer.pick ?? storeQuestion.pick,
			};
		}

		if (emit) {
			this._answersChanged = true;
		}
		this.calculateFootballs();
	}

	public getIsQuestionAnswered(questionId: number): boolean {
		return this._answers.some((e) => e.questionId === questionId);
	}

	getAnswerById(questionId: Nullable<number>): Empty<IServerAnswer> {
		return this._answers.find((e) => e.questionId === questionId);
	}

	getParlayAnswerById(questionId: Nullable<number>): Empty<IAnswerBase> {
		return this._contestParlay.picks?.find((e) => e.questionId === questionId);
	}

	public clearAnswerById(questionId: NullOrEmpty<number>): void {
		const answerIndex = this._answers.findIndex((e) => e.questionId === questionId);
		if (answerIndex === -1) {
			return;
		}
		this._answers.splice(answerIndex, 1);
		this._answersChanged = true;
		this.calculateFootballs();
	}

	@action
	public clearAnswers(ids: number[], all?: boolean): void {
		if (all) {
			this._answers = [];
			this._answersChanged = true;
			this.calculateFootballs();
			return;
		}
		this._answers = this.answers.filter((e) => !ids.includes(e.questionId));

		/*
		 * If answers length > 0, then was cleared only non-locked props, and it can be saved to reset if
		 * If cleared all - disable clear/save button
		 */
		this._answersChanged = this.answers.length !== 0;
		this.calculateFootballs();
	}

	@action
	public async fetchAnswersByContestId(contestId: number): Promise<void> {
		if (!this._userStore.isAuthorized) {
			return;
		}

		runInAction(() => {
			this._userHasAnswers = false;
		});

		try {
			const response = await this._answersProvider.fetchAnswersByContestId(contestId);
			const {items, parlay, totalFootballs} = response.data.success;

			runInAction(() => {
				this._answers = items;
				this._contestParlay = parlay || {picks: [], outcome: null};
				this._availableFootballs = totalFootballs;
				this._serverChips = totalFootballs;
				this._userHasAnswers = items.length > 0;

				this._serverAnswers = response.data.success;
			});

			this.calculateFootballs();
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		}
	}

	@action
	public async saveAnswers(contestId: number): Promise<void> {
		const payload = this.getSaveAnswersPayload();
		try {
			this._isSaving = true;

			await this._answersProvider.saveAnswersForContest(payload, contestId);

			this._answersChanged = false;
			this._modalsStore.showModal(ModalType.PICKS_SAVED);
			return Promise.resolve();
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
			return Promise.reject();
		} finally {
			this._isSaving = false;
		}
	}

	@action
	public async saveParlayForContest(contestId: number): Promise<void> {
		if (!contestId) {
			return Promise.reject("No selected contest found");
		}

		try {
			this._isSaving = true;
			const payload = this.updateParlayWithPrefilled(this.getParlayPayload());
			await this._answersProvider.saveParlayForContest(contestId, payload);
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		} finally {
			this._isSaving = false;
		}
	}

	@action
	public clearEmptyAnswers(): void {
		this._answers = this.answers.filter((e) => (e.bet || 0) > 0);
	}

	public getSaveAnswersPayload(): IAnswersPayload {
		const canceledProps =
			this._contestStore.selectedContest?.props
				.filter((e) => e.status === PropStatus.Canceled)
				.map((e) => e.id) ?? [];

		const props = this.answers
			.filter((answer) => !canceledProps.includes(answer.questionId))
			.map((answer) => ({
				questionId: answer.questionId,
				bet: answer.bet,
				pick: answer.pick,
			}));

		return {props};
	}

	protected updateParlayWithPrefilled(payload: IParlayPayload): IParlayPayload {
		if (this.userHasSavedParlay) {
			return payload;
		}
		const prefill = this._parlayPrefillService.getOrGenerateParlayPrefill();
		return {...payload, isParlayPreselected: prefill};
	}

	@action
	private calculateFootballs(): void {
		this.availableFootballs = this._serverChips - this.totalAnswersFootballs;
	}

	private getParlayPayload(): IParlayPayload {
		// If preview parlay only
		const answers = this.answers.length === 0 ? this._serverAnswers?.items || [] : this.answers;
		const parlay = answers
			.filter((e) => this.parlayIds.includes(e.questionId))
			.map((answer) => ({
				questionId: answer.questionId,
				pick: answer.pick,
			}));

		return {parlay};
	}
}
