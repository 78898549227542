import {ViewController} from "data/types/structure";
import React from "react";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {IRegisterForm} from "data/types/forms";
import type {ICountry} from "data/providers/json/json.provider";
import type {IStaticStore} from "data/stores/static/static.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IFormValidator} from "data/utils/helpers/validators/FormValidator";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

import {POSTCODE_COUNTRY} from "data/constants";
import {IRegistrationPayload, ISquad} from "data/types/api";
import {ValidationScheme} from "data/types/validators";
import {PasswordValidator} from "data/utils/helpers/validators/PasswordValidator";
import {Empty} from "data/types/generics";
import {PostcodeValidator} from "data/utils/helpers/validators/Postcode.validator";
import {isBoolean, parseStringToBoolean} from "data/utils/helpers";
import {AxiosError} from "axios";
import {EmailValidator} from "data/utils/helpers/validators/Email.validator";
import {ModalType} from "data/enums";
import {UsernameValidator} from "data/utils/helpers/validators/Username.validator";

export interface IFormRegistrationController extends ViewController {
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;

	handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	handleFormSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;

	togglePasswordVisibility: () => void;

	toggleConfirmPasswordVisibility: () => void;

	clearFormError: () => void;

	checkUsername: () => void;

	goToLogin: () => void;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;

	get form(): IRegisterForm;

	get isPostcodeVisible(): boolean;

	get countries(): ICountry[];

	get squads(): ISquad[];

	get formErrors(): Record<string, Empty<string>>;

	get isLoading(): boolean;

	get error(): string | undefined;
}

@injectable()
export class FormRegistrationController implements IFormRegistrationController {
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
		email: [new EmailValidator()],
		postCode: [new PostcodeValidator()],
		username: [new UsernameValidator()],
	};

	constructor(
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.StaticStore) private _staticStore: IStaticStore,
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		this._formValidator.enterScheme(this._validationScheme);
		makeAutoObservable(this);
	}

	@observable private _error: string | undefined;

	get error(): string | undefined {
		return this._error;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _form: IRegisterForm = {
		email: "",
		firstName: "",
		lastName: "",
		password: "",
		confirmPassword: "",
		username: "",
		country: POSTCODE_COUNTRY,
		postCode: null,
		favouriteTeam: 0,
		xflNews: true,
		isTicketsOptIn: false,
	};

	get form(): IRegisterForm {
		return this._form;
	}

	get isPostcodeVisible(): boolean {
		return this._form.country === POSTCODE_COUNTRY;
	}

	get countries(): ICountry[] {
		return this._countriesStore.list;
	}

	get squads(): ISquad[] {
		return this._staticStore.squads.filter((e) => e.shortName.toLowerCase() !== "general");
	}

	get formErrors(): Record<string, Empty<string>> {
		return this._formValidator.formErrors;
	}

	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
		this.formChangeAction(event.target as unknown as HTMLInputElement);
	};

	handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.formChangeAction(event.target);
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>): void => {
		event.preventDefault();
		event.stopPropagation();
		this._formValidator.clearErrors();
		const isValid = this._formValidator.validate(event.currentTarget);

		if (!isValid) {
			return;
		}

		if (this.form.password !== this.form.confirmPassword) {
			this._formValidator.setError("confirmPassword", "Passwords don't match");
			return;
		}

		const payload: IRegistrationPayload = {
			email: this.form.email,
			firstName: this.form.firstName,
			lastName: this.form.lastName,
			country: this.form.country,
			favouriteTeam: this.form.favouriteTeam,
			isTicketsOptIn: this.form.isTicketsOptIn,
			xflNews: this.form.xflNews,
			password: this.form.password,
			postCode: this.form.postCode,
			username: this.form.username,
		};

		this.changeLoadingState(true);

		this._userStore
			.register(payload)
			.then(this.checkIsSaveRequired.bind(this))
			.catch((error: AxiosError<{errors: {message: string}[]}>) => {
				this._error = error.response?.data?.errors[0]?.message || error.message;
			})
			.finally(() => this.changeLoadingState(false));
	};

	@action
	public toggleConfirmPasswordVisibility = (): void => {
		runInAction(() => {
			this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
		});
	};

	@action
	public togglePasswordVisibility = (): void => {
		runInAction(() => {
			this._isPasswordVisible = !this._isPasswordVisible;
		});
	};

	@action
	public clearFormError = () => {
		runInAction(() => {
			this._error = undefined;
		});
	};

	@action
	public checkUsername = (): void => {
		this._userStore.checkUsername(this.form.username).then(console.log).catch(console.warn);
	};

	public goToLogin = (): void => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	init(param: void): void {
		this.fetchCountries();
	}

	dispose(): void {
		return;
	}

	@action
	private formChangeAction(target: HTMLInputElement) {
		const {name, value, checked, type} = target;

		const inputValue = type === "checkbox" ? checked : value;
		if (!name) {
			return;
		}
		const keyName = name as keyof IRegisterForm;

		runInAction(() => {
			this._form = {
				...this._form,
				[keyName]: this.parseChangeValue(inputValue),
			};

			this.setCursor(target);
		});
		this.checkPostcode();
		this.clearErrorFor(keyName);
		this.clearFormError();
	}

	private fetchCountries(): void {
		void this._countriesStore.fetchCountries();
	}

	private checkPostcode(): void {
		if (this.form.country !== POSTCODE_COUNTRY) {
			runInAction(() => {
				this._form.postCode = undefined;
			});
		}
	}

	private setCursor(target: HTMLInputElement): void {
		if (target.type === "checkbox") {
			return;
		}

		const cursor = Number(target.selectionStart);
		setTimeout(() => {
			target.setSelectionRange(cursor, cursor);
		}, 0);
	}

	private clearErrorFor(name: string) {
		this._formValidator.clearError(name);
	}

	private parseChangeValue<T>(value: T): T | boolean {
		if (isBoolean(value)) {
			return parseStringToBoolean(String(value));
		}
		return value;
	}

	private changeLoadingState(value: boolean): void {
		runInAction(() => {
			this._isLoading = value;
		});
	}

	private checkIsSaveRequired(): void {
		if (this._answersStore.answersChanged && this._contestStore.selectedContest) {
			void this._answersStore.saveAnswers(this._contestStore.selectedContest?.id);
		}
		if (this._answersStore.parlayIds.length >= 2) {
			void this._answersStore.saveParlayForContest(this._contestStore.selectedContest?.id);
		}
		this._modalsStore.hideModal();
	}
}
