import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IProp} from "data/types/entities";
import {Bindings} from "data/constants/bindings";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";

export interface IPicksReviewController extends ViewController {
	get props(): IProp[];
}

@injectable()
export class PicksReviewController implements IPicksReviewController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get props(): IProp[] {
		return this._contestStore.selectedContest?.props ?? [];
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
