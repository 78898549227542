import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ILeague} from "data/types/entities";
import {Empty} from "data/types/generics";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {getSponsoredImageFromBackend} from "data/utils/helpers";

interface IParams {
	leagueId: number;
}

export interface ILeagueItemController extends ViewController<IParams> {
	get league(): Empty<ILeague>;

	get isSponsored(): boolean;

	get sponsorImageSrc(): Empty<string>;
}

@injectable()
export class LeagueItemController implements ILeagueItemController {
	@observable private _leagueId: number = 0;

	constructor(@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore) {
		makeAutoObservable(this);
	}

	get league(): Empty<ILeague> {
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._leagueId = param.leagueId;
	}

	get isSponsored(): boolean {
		return this.league?.class === "sponsored";
	}

	get sponsorImageSrc(): Empty<string> {
		if (!this.isSponsored || !this.league) {
			return;
		}
		return getSponsoredImageFromBackend(this.league.id);
	}
}
