import styled from "@emotion/styled";

import {PureButton} from "views/components/common/buttons/pureButton";

export const SecondaryButton = styled(PureButton)`
	background: ${({theme}) => theme.button.secondary.default.background};
	color: ${({theme}) => theme.button.secondary.default.color};
	border: 1px solid ${({theme}) => theme.button.secondary.default.borderColor};
	padding: 14px 22px;
	height: 48px;

	.preloader svg {
		color: ${({theme}) => theme.button.secondary.default.color};
	}

	&:hover,
	&:active,
	&:focus &.active {
		background: ${({theme}) => theme.button.secondary.hover.background};
		color: ${({theme}) => theme.button.secondary.hover.color};
		border-color: ${({theme}) => theme.button.secondary.hover.borderColor};
	}

	&:disabled {
		background: ${({theme}) => theme.button.secondary.disabled.background};
		color: ${({theme}) => theme.button.secondary.disabled.color};
		border-color: ${({theme}) => theme.button.secondary.disabled.borderColor};
		opacity: ${({theme}) => theme.button.secondary.disabled.opacity};
	}
`;
