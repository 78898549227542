import {AbstractValidator} from "data/utils/helpers/validators/abstract.validator";
import {THTMLFormElements} from "data/types/validators";
import {PASSWORD_PATTERN} from "data/constants";

export class PasswordValidator extends AbstractValidator {
	getErrorMessage(): string {
		return "form.common.password_requirements";
	}

	validate(formControl: THTMLFormElements): boolean {
		return new RegExp(PASSWORD_PATTERN).test(formControl.value);
	}
}
