import {AbstractValidator} from "data/utils/helpers/validators/abstract.validator";
import i18n from "i18next";
import {THTMLFormElements} from "data/types/validators";

export class UsernameValidator extends AbstractValidator {
	getErrorMessage(): string {
		return i18n.t("form.common.username_invalid");
	}

	validate(formControl: THTMLFormElements): boolean {
		return !/@/gi.test(formControl.value);
	}
}
