import {Select, TextField, Radio} from "@mui/material";
import styled from "@emotion/styled";
import {BASE_FONT} from "data/constants";

export const Input = styled(TextField)({
	fontFamily: BASE_FONT,
	width: "100%",
	marginBottom: "16px",
	"& label": {
		fontFamily: BASE_FONT,
		color: "#A3A3A3",
	},
	"& input": {
		fontFamily: BASE_FONT,
		color: "#A3A3A3",
	},

	"&:hover": {
		"*": {
			transition: ".25s",
		},
		label: {
			color: "#121212",
		},
		input: {
			color: "#121212",
		},
	},

	label: {
		"&.Mui-focused": {
			color: "#121212",
		},

		"&.MuiFormLabel-filled + div input": {
			color: "#121212",
		},

		"&.Mui-error": {
			color: "#C20000",
		},
	},

	".MuiOutlinedInput-root": {
		"&.Mui-focused:not(.Mui-error)": {
			fieldset: {
				border: "2px solid #121212",
			},
			input: {
				color: "#121212",
			},
		},

		"&.Mui-error": {
			input: {
				color: "#C20000",
			},
		},
	},

	"& .Mui-disabled": {
		pointerEvents: "none",
		opacity: "0.5",
	},

	"& .Mui-disabled.MuiInputLabel-root": {
		color: "#D9D9D9",
	},

	// Select styles if you use it as <Input select>
	"& .MuiSvgIcon-root": {
		color: "#333333",
	},

	"& .MuiSelect-select": {
		fontFamily: BASE_FONT,
		color: "#121212",
	},

	//TextArea
	"& textarea": {
		fontFamily: BASE_FONT,
		color: "#121212",
	},
});

export const SelectInput = styled(Select)({
	width: "100%",
});

export const RadioElement = styled(Radio)({
	"&.Mui-checked": {
		color: "#121212",
	},
});
