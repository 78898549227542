import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface IResetPasswordPageController extends ViewController {
	openModalWithToken: (token: string) => void;
}

@injectable()
export class ResetPasswordPageController implements IResetPasswordPageController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	openModalWithToken = (token: string): void => {
		this._modalsStore.showModal(ModalType.RESET_PASSWORD, {token});
	};
}
