import React from "react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {TutorialStep} from "data/enums";
import {Modal} from "@mui/material";
import {ModalCloseButton, ModalContent} from "views/components/modals/common";
import {Exist} from "views/components/Exist";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {observer} from "mobx-react";
import {WelcomeScreen} from "./WelcomeScreen/welcomeScreen.component";
import {IModalTutorialController} from "./modalTutorial.controller";
import {SliderScreen} from "./SliderScreen/sliderScreen.component";
import {FinishScreen} from "./FinishScreen/finishScreen.component";
import styled from "@emotion/styled";

const ModalContentStyled = styled(ModalContent)`
	overflow: hidden;
	max-width: 335px;
	padding: 0;
`;

const SModalCloseButton = styled(ModalCloseButton)`
	position: absolute;
	right: 12px;
	top: 12px;
	width: 40px;
	height: 40px;
	background: #333333;
	z-index: 3;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	svg {
		width: 24px;
		height: 24px;

		path {
			fill: ${({theme}) => theme.text.color.white};
		}
	}
`;

export const ModalTutorial: React.FC = observer(() => {
	const {step, changeStep, close, replay, isOpen} = useViewController<IModalTutorialController>(
		Bindings.ModalTutorialController
	);

	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContentStyled>
				<SModalCloseButton onClick={close}>
					<IconClose />
				</SModalCloseButton>

				<Exist when={step === TutorialStep.Welcome}>
					<WelcomeScreen changeStep={changeStep} />
				</Exist>

				<Exist when={step === TutorialStep.Slider}>
					<SliderScreen changeStep={changeStep} />
				</Exist>

				<Exist when={step === TutorialStep.Finish}>
					<FinishScreen close={close} replay={replay} />
				</Exist>
			</ModalContentStyled>
		</Modal>
	);
});
