import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface IModalRegistrationController extends ViewController {
	get isOpen(): boolean;
	close: () => void;
}

@injectable()
export class ModalRegistrationController implements IModalRegistrationController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.REGISTRATION;
	}
}
