export const Bindings = {
	// Providers
	JSONProvider: Symbol(),
	AuthApiProvider: Symbol(),
	AnswersApiProvider: Symbol(),
	ApiHTTPClient: Symbol(),
	JsonHTTPClient: Symbol(),
	JsonHelpClient: Symbol(),
	LeaguesApiProvider: Symbol(),
	RankingsApiProvider: Symbol(),

	// Stores
	CountriesStore: Symbol(),
	UserStore: Symbol(),
	StaticStore: Symbol(),
	ModalsStore: Symbol(),
	MenuStore: Symbol(),
	ContestStore: Symbol(),
	AnswersStore: Symbol(),
	ParlayStore: Symbol(),
	LeaguesStore: Symbol(),
	DashboardStore: Symbol(),
	TitleStore: Symbol(),
	RankingsStore: Symbol(),
	LiveScoringStore: Symbol(),
	SettingsStore: Symbol(),

	// Controllers
	HOCMainController: Symbol(),
	AuthController: Symbol(),
	HeaderController: Symbol(),
	FormRegistrationController: Symbol(),
	FormUserUpdateController: Symbol(),
	FormChangeUserPasswordController: Symbol(),
	FormLoginController: Symbol(),
	ModalController: Symbol(),

	ModalForgotPasswordController: Symbol(),
	ModalResetPasswordController: Symbol(),
	MenuController: Symbol(),
	RoundSelectorController: Symbol(),
	HelpNavigationController: Symbol(),
	PicksSliderController: Symbol(),
	PickCardController: Symbol(),
	PicksActionBarController: Symbol(),
	ScoreBarController: Symbol(),
	RangeSliderController: Symbol(),
	DashboardController: Symbol(),
	ParlayController: Symbol(),
	SliderStepsController: Symbol(),
	ModalParlayController: Symbol(),
	ModalPicksSavedController: Symbol(),
	ModalPicksSubmitController: Symbol(),
	ModalLeaveContestController: Symbol(),
	ParlaySummaryItemController: Symbol(),
	ParlayItemController: Symbol(),
	SummaryParlayController: Symbol(),
	DashboardContestItemController: Symbol(),
	LeagueItemController: Symbol(),
	LeaguesJoinController: Symbol(),
	LeagueJoinItemController: Symbol(),
	JoinLeagueFiltersController: Symbol(),
	FormLeagueCreateController: Symbol(),
	LeaveLeagueController: Symbol(),
	ModalLeaveLeagueController: Symbol(),
	InviteLeagueController: Symbol(),
	LeagueUsersController: Symbol(),
	ModalRemoveLeagueUserController: Symbol(),
	ModalTutorialController: Symbol(),
	SliderScreenController: Symbol(),
	HelpOutletController: Symbol(),
	BonusCodesPageController: Symbol(),
	ContactUsController: Symbol(),
	SquadImageController: Symbol(),
	ModalRegistrationController: Symbol(),
	RegistrationPageController: Symbol(),
	ModalBeforePicksSaveController: Symbol(),
	ModalLoginController: Symbol(),
	OverallLeaderboardController: Symbol(),
	TeamLeaderboardController: Symbol(),
	ParlayCheckboxController: Symbol(),
	LeagueStandingsRowController: Symbol(),
	RankingsPicksController: Symbol(),
	AdvertisementController: Symbol(),
	HelpController: Symbol(),
	HelpListController: Symbol(),
	PicksReviewController: Symbol(),
	ShareControllerController: Symbol(),
	FootballTooltipController: Symbol(),
	ModalNoPicksPlacedController: Symbol(),
	ProfileLinkBlockController: Symbol(),

	// Page controllers
	MyAccountController: Symbol(),
	ResetPasswordPageController: Symbol(),
	PicksPageController: Symbol(),
	MyLeaguesController: Symbol(),
	PicksSummaryController: Symbol(),
	ViewLeagueOutletController: Symbol(),
	LeagueAboutController: Symbol(),
	LoginPageController: Symbol(),
	LeaderboardPageController: Symbol(),
	LeagueLadderController: Symbol(),
	NotificationController: Symbol(),
	PageTitleController: Symbol(),
	HOCSecretKeyController: Symbol(),
	InvitePageController: Symbol(),
	ModalSeasonEndController: Symbol(),

	// Helpers
	FormValidator: Symbol(),
	NotificationService: Symbol(),
	ParlayPrefillService: Symbol(),
};
