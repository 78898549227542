import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {ModalType} from "data/enums";

export interface IModalPicksSubmitController extends ViewController {
	submit: () => void;
	cancel: () => void;

	get isWagerEnabled(): boolean;

	get isOpen(): boolean;
}

@injectable()
export class ModalPicksSubmitController implements IModalPicksSubmitController {
	constructor(
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore
	) {
		makeAutoObservable(this);
	}

	get isWagerEnabled(): boolean {
		return this._answersStore.answers.length > 1;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.PICKS_SUBMIT;
	}

	private get parlayStep(): number {
		if (!this._contestStore.selectedContest) {
			return 1;
		}
		return this._contestStore.selectedContest.props.length;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public cancel = (): void => {
		this.close();
	};

	public submit = (): void => {
		this._contestStore.currentSliderStep = this.parlayStep;
		this.close();
	};

	private close() {
		this._modalsStore.hideModal();
	}
}
