import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface ILeaveLeagueController extends ViewController {
	handleLeave: () => void;

	get isLoading(): boolean;

	get isJoined(): boolean;
}

@injectable()
export class LeaveLeagueController implements ILeaveLeagueController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isJoined(): boolean {
		if (!this._leaguesStore.selectedLeague) {
			return true;
		}
		return this._leaguesStore.selectedLeague.isJoined;
	}

	public handleLeave = (): void => {
		this._modalsStore.showModal(ModalType.LEAVE_LEAGUE);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
