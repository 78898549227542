import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {noop} from "lodash";
import {ILeagueUser} from "data/types/entities";
import {Empty} from "data/types/generics";

interface IContent {
	userId: number;
}

export interface IModalRemoveLeagueUserController extends ViewController {
	close: () => void;
	submit: () => void;

	get isOpen(): boolean;

	get isLoading(): boolean;

	get username(): string;
}

@injectable()
export class ModalRemoveLeagueUserController implements IModalRemoveLeagueUserController {
	constructor(
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.REMOVE_LEAGUE_USER;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	public submit = (): void => {
		const content = this._modalsStore.modalContent as IContent;
		if (!content.userId) {
			this.close();
			return;
		}

		this._isLoading = true;
		this._leaguesStore
			.removeUserFromLeague(content?.userId)
			.then(this.onSuccess.bind(this))
			.catch(noop);
	};

	private onSuccess() {
		this._isLoading = false;
		this.close();
	}

	get username(): string {
		return this.leagueUser?.username || "-";
	}

	private get leagueUser(): Empty<ILeagueUser> {
		const content = this._modalsStore.modalContent as IContent;
		return this._leaguesStore.leagueUsers.find((e) => e?.userId === content?.userId);
	}
}
