import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService, IApiAxiosResponse} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IRegistrationPayload, IResetPasswordPayload, IUserUpdatePayload} from "data/types/api";
import {AbstractApiProvider} from "data/providers/api/abstract.api.provider";

export interface ILoginPayload {
	email: string;
	password: string;
}

export interface IBonusCodeResponse {
	bonus: {
		amount: number;
	};
}

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	checkUsername: (params: string) => Promise<AxiosResponse<unknown>>;
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TLoginResponse>>;
	update: (params: IUserUpdatePayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
	fetchUser: () => Promise<AxiosResponse<TLoginResponse>>;

	forgotPasswordRequest: (params: string) => Promise<AxiosResponse<unknown>>;
	resetPasswordRequest: (params: IResetPasswordPayload) => Promise<AxiosResponse<unknown>>;
	sendCode: (param: string) => Promise<IApiAxiosResponse<IBonusCodeResponse>>;
}

@injectable()
export class AuthApiProvider extends AbstractApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {
		super();
	}

	checkUsername = (username: string) =>
		this._http.post<unknown>(`${this.language}/user/check_username`, {username});

	fetchUser = () => this._http.get<TLoginResponse>(`${this.language}/user`);

	login = (params: ILoginPayload) =>
		this._http.post<TLoginResponse>(`${this.language}/auth/login`, params);

	register = (params: IRegistrationPayload) =>
		this._http.post<TLoginResponse>(`${this.language}/auth/register`, params);

	update = (params: IUserUpdatePayload) =>
		this._http.post<TLoginResponse>(`${this.language}/user/update`, params);

	logout = () => this._http.post(`${this.language}/auth/logout`);

	forgotPasswordRequest(params: string): Promise<AxiosResponse<unknown>> {
		return this._http.post<unknown>(`${this.language}/auth/password_reset/request`, {
			email: params,
		});
	}

	resetPasswordRequest(params: IResetPasswordPayload): Promise<AxiosResponse<unknown>> {
		return this._http.post<unknown>(`${this.language}/auth/password_reset`, params);
	}

	sendCode(param: string): Promise<IApiAxiosResponse<IBonusCodeResponse>> {
		return this._http.post(`${this.language}/bonus/redeem`, {code: param});
	}
}
