import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IParlayStore} from "data/stores/parlay/parlay.store";
import {ModalType} from "data/enums";

export interface IModalParlayController extends ViewController {
	get isOpen(): boolean;

	close: () => void;
}

@injectable()
export class ModalParlayController implements IModalParlayController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ParlayStore) private _parlayStore: IParlayStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	close = (): void => {
		this._modalsStore.hideModal();
		this._parlayStore.closeParlayTutorial();
	};

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.PARLAY_TUTORIAL;
	}
}
