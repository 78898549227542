import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IMenuStore} from "data/stores/menu/menu.store";
import type {IUserStore} from "data/stores/user/user.store";
import {computed, makeAutoObservable} from "mobx";

export interface IMenuController extends ViewController {
	get menuClass(): string;
	get isMenuOpen(): boolean;
	get isLogged(): boolean;
	closeMenu: () => void;
	logout: () => void;
}

@injectable()
export class MenuController implements IMenuController {
	constructor(
		@inject(Bindings.MenuStore) private _menuStore: IMenuStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}
	public closeMenu = (): void => {
		this._menuStore.closeMenu();
	};

	public logout = (): void => {
		void this._userStore.logout();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get isMenuOpen(): boolean {
		return this._menuStore.isMenuOpen;
	}

	@computed
	get menuClass(): string {
		return this.isMenuOpen ? "open" : "";
	}

	get isLogged(): boolean {
		return this._userStore.isAuthorized;
	}
}
