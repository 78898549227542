import {ViewController} from "data/types/structure";
import {ModalType} from "data/enums";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface IModalController extends ViewController {
	close: () => void;

	isModalOpen: (modal: ModalType) => boolean;

	showTutorialModal: () => void;

	get modalData(): unknown;
}

@injectable()
export class ModalController implements IModalController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	get modalData(): unknown {
		return this._modalsStore.modalContent;
	}

	public close = (): void => {
		this._modalsStore.hideModal();
	};

	public isModalOpen = (modal: ModalType): boolean => {
		return this._modalsStore.modal === modal;
	};

	public showTutorialModal = (): void => {
		this._modalsStore.showModal(ModalType.TUTORIAL);
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}
}
